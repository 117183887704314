import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import { useTranslation } from 'react-i18next'
import BatchTabs from './BatchTabs/BatchTabs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        padding: theme.spacing(2, 2, 1),
      },
    },

    modal: {
      borderRadius: 'none',
    },
  })
)

function BatchFormModal({ isOpened, onBackdropClick }: any): any {
  const classes = useStyles()
  const { t } = useTranslation()
  const body = (
    <>
      <div className={classes.paper}>
        <ModalTop label={t('batchFormModal.title')} onBackdropClick={onBackdropClick} />
        <BatchTabs onBackdropClick={onBackdropClick} />
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onBackdropClick={onBackdropClick}
      disablePortal
      className={classes.modal}
      style={{ height: '690px' }}
      hideBackdrop={true}
    >
      {body}
    </MaterialModal>
  )
}

export default BatchFormModal
