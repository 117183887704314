import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'
import { getColors } from '../NotificationItem/NotificationItem.style'

export const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Content = styled.div`
  background-color: #fff;
  padding: 30px;
  width: 600px;
  ${respondFrom.lessThanTablet`
    width: 90%;
  `}
  color: #495057;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`

export const Icon = styled.img`
  margin-left: auto;
  cursor: pointer;
`
export const Topbar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const TypeTag = styled.div`
  ${({ type }) => getColors(type)}
  padding: 2px 4px;
  text-transform: uppercase;
  font-weight: 600;
`

export const ImageContainer = styled.img`
  height: 200px;
  width: 100%;
  background-color: gray;
  margin-bottom: 16px;
`

export const Title = styled.h3`
  font-size: 26px;
  margin: 0 0 10px;
  font-weight: 600;
`

export const Description = styled.p`
  font-size: 14px;
  margin: 0 0 16px;
  font-weight: 400;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
