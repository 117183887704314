const getProperUrl = (data, key) => {
  if (!data) {
    return ''
  }

  const item = data?.products?.find((item) => item.name?.toLowerCase() === key.toLowerCase())

  if (
    item &&
    ((item.url && item.url.indexOf('http') > -1) ||
      (item.urlMarketing && item.urlMarketing.indexOf('http') > -1))
  ) {
    return item
  }
  return {
    ...item,
    newTab: false,
  }
}

export default getProperUrl
