import { createSlice } from '@reduxjs/toolkit'

export enum ToastStatusType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ToastState {
  type: ToastStatusType | null
  message: string | null
  timeout: number
  triggeredAt: number | null
}

const initialState: ToastState = {
  type: null,
  message: null,
  timeout: 5000,
  triggeredAt: null,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.type = action.payload.type
      state.message = action.payload.message
      state.timeout = action.payload.timeout ?? 5000
      state.triggeredAt = Date.now()
    },
    clearToast: (state) => {
      state.type = null
      state.message = null
      state.triggeredAt = null
    },
  },
})

export const { setToast, clearToast } = toastSlice.actions

export default toastSlice.reducer
