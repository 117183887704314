import { createSlice } from '@reduxjs/toolkit'
import { notificationsApi } from 'services/notifications-api'
import { RootState } from 'store/store'

export interface NotificationsState {
  allNotifications: any[] | null
  unreadNotifications: any[] | null
}

const initialState: NotificationsState = {
  allNotifications: null,
  unreadNotifications: null,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setAllNotifications: (state, action) => {
      state.allNotifications = action.payload
    },
    setUnreadNotifications: (state, action) => {
      state.unreadNotifications = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        notificationsApi.endpoints.getAllNotifications.matchFulfilled,
        (state, action) => {
          state.allNotifications = action.payload
        }
      )
      .addMatcher(notificationsApi.endpoints.getAllNotifications.matchRejected, (state, _) => {
        state.allNotifications = null
      })
      .addMatcher(
        notificationsApi.endpoints.getUnreadNotifications.matchFulfilled,
        (state, action) => {
          state.unreadNotifications = action.payload
        }
      )
      .addMatcher(notificationsApi.endpoints.getUnreadNotifications.matchRejected, (state, _) => {
        state.unreadNotifications = null
      })
  },
})

export const { setAllNotifications, setUnreadNotifications } = notificationsSlice.actions

export const selectAllNotifications = (state: RootState) => state.notifications.allNotifications
export const selectUnreadNotifications = (state: RootState) =>
  state.notifications.unreadNotifications

export default notificationsSlice.reducer
