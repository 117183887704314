import React from 'react'
import {
  StyledNotificationError,
  StyledTryAgainButton,
  StyledWarningIcon,
} from './NotificationError.styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'

export interface NotificationErrorProps {
  tryAgainAction: () => void
  isLoading: boolean
}

const NotificationError = (props: NotificationErrorProps) => {
  const { tryAgainAction, isLoading } = props
  const { t } = useTranslation()

  return (
    <StyledNotificationError>
      <StyledWarningIcon>
        <WarningIcon height={40} width={40} />
      </StyledWarningIcon>
      <h1>{t('notification.error.title')}</h1>
      <h2>{t('notification.error.text')}</h2>
      <StyledTryAgainButton
        kind="secondary"
        onClick={tryAgainAction}
        loading={isLoading}
        active={isLoading}
      >
        {isLoading ? '' : t('common.error.table.try-again.button')}
      </StyledTryAgainButton>
    </StyledNotificationError>
  )
}

export default NotificationError
