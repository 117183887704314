import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledDescription,
  StyledDescriptionContent,
  StyledDescriptionWrapper,
  StyledFileUpload,
  StyledOnDragDescription,
  StyledSpinner,
} from './BatchFileDragAndDrop.styled'
import { FileDrop } from 'react-file-drop'
import FileUploadIcon from 'assets/images/icons/fileUpload.svg'
import FileCheckmark from 'assets/images/icons/file_checkmark.svg'
import FileError from 'assets/images/icons/file_error.svg'
import TrashIcon from 'assets/images/icons/trashButton.svg'
/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!common/workers/batchFileProcessor'
import ValidationErrorsBatchModal from 'components/Modal/ValidationErrorsBatchModal/ValidationErrorsBatchModal'

const UploadedFileDescription = (props: any) => {
  const {
    fileData,
    processingResult,
    isFileError,
    fileInputRef,
    handleOpenValidationErrorsModal,
    batchMaxSize,
  } = props
  const { t } = useTranslation()
  const getValidMessage = () => {
    let message = t('batchFormModal.fileUpload.uploaded.valid', {
      value: processingResult?.rowsCount,
    })
    if (processingResult?.limitedRows === true) {
      message =
        message +
        ' ' +
        t('batchFormModal.fileUpload.uploaded.limited', {
          value: batchMaxSize,
        })
    }
    return message
  }

  const validationErrorsBody = (
    <>
      {t('batchFormModal.fileUpload.uploaded.validationErrors', {
        value: processingResult?.validationErrors?.length,
      })}
      <span onClick={handleOpenValidationErrorsModal}>
        {t('batchFormModal.fileUpload.uploaded.errorsList')}
      </span>
    </>
  )
  return (
    <>
      <p>
        {fileData?.fileName}
        {isFileError && !fileData?.isProcessing && (
          <span onClick={() => fileInputRef.current.click()}>
            {t('batchFormModal.fileUpload.uploaded.uploadAgain')}
          </span>
        )}
      </p>
      <p style={{ color: isFileError && !fileData?.isProcessing ? '#A93C30' : '' }}>
        {!fileData?.isProcessing &&
          (fileData?.isFileValid === true && processingResult?.invalidFile === false
            ? processingResult?.validationErrors?.length > 0
              ? validationErrorsBody
              : processingResult?.rowsCount > 0
              ? getValidMessage()
              : t('batchFormModal.fileUpload.uploaded.emptyFile')
            : t('batchFormModal.fileUpload.uploaded.invalid'))}
        {fileData?.isProcessing && t('batchFormModal.fileUpload.uploaded.processing')}
      </p>
    </>
  )
}

const BatchImg = (props: any) => {
  const { isProcessing, isFileValid, validationErrors, rowsCount } = props
  const icon = useMemo(() => {
    if (isProcessing) {
      return <StyledSpinner disableShrink={true} />
    }
    if (isFileValid && validationErrors?.length === 0 && rowsCount > 0) {
      return <img src={FileCheckmark} alt="fileCheckmark" />
    }
    return <img src={FileError} alt="fileError" />
  }, [isProcessing, isFileValid, validationErrors, rowsCount])

  return icon
}

const BatchFileDragAndDrop = (props: any) => {
  const { setBatchData, batchMaxSize, fileMissingError, onFileRemove } = props
  const { t } = useTranslation()
  const fileInputRef = useRef(null)
  const [onDragState, setOnDragState] = useState(false)
  const [fileData, setFileData] = useState(null)
  const [openValidationErrors, setOpenValidationErrors] = useState(false)
  const [fileProcessed, setFileProcessed] = useState(false)
  const [processingResult, setProcessingResult] = useState(null)

  const handleDrop = async (files: any) => {
    const file = files[0]
    const newFileData = {
      ...fileData,
      isProcessing: true,
      fileName: file.name,
    }
    setFileData(newFileData)
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFileData({
        ...newFileData,
        isProcessing: false,
        isFileValid: false,
      })
      setFileProcessed(true)
      return
    }
    const fileContent = await file.arrayBuffer()
    const processWorker = new Worker()
    processWorker.postMessage({ data: fileContent, maxSize: batchMaxSize })
    processWorker.onmessage = function (e) {
      setFileData({
        ...newFileData,
        isFileValid: true,
        isProcessing: false,
      })
      setProcessingResult(e.data)
      setFileProcessed(true)
      processWorker.terminate()
    }
  }

  const onRemoveFile = () => {
    setBatchData(null)
    setFileData(null)
    setProcessingResult(null)
    setFileProcessed(false)
    onFileRemove()
  }

  const handleOpenValidationErrorsModal = () => {
    setOpenValidationErrors(true)
  }

  const onBackdropValidationErrorsClick = () => {
    setOpenValidationErrors(false)
  }

  const isFileError =
    !fileData?.isFileValid === true ||
    processingResult?.invalidFile === true ||
    processingResult?.validationErrors?.length > 0 ||
    processingResult?.rowsCount === 0

  const bodyBeforeFile = (
    <StyledDescriptionWrapper>
      <StyledDescription isDrag={onDragState}>
        <img src={FileUploadIcon} alt="fileUpload" />
        <StyledDescriptionContent>
          <p>
            {t('batchFormModal.fileUpload.dragndrop.row1.a')}
            <span style={{ marginLeft: 0 }} onClick={() => fileInputRef.current.click()}>
              {' ' + t('batchFormModal.fileUpload.dragndrop.row1.b')}
            </span>
          </p>
          <p>{t('batchFormModal.fileUpload.dragndrop.row2')}</p>
        </StyledDescriptionContent>
      </StyledDescription>
    </StyledDescriptionWrapper>
  )

  const fileBody = (
    <StyledDescriptionWrapper>
      <StyledDescription isDrag={onDragState}>
        <BatchImg
          isProcessing={fileData?.isProcessing}
          isFileValid={fileData?.isFileValid}
          validationErrors={processingResult?.validationErrors}
          rowsCount={processingResult?.rowsCount}
        />
        <StyledDescriptionContent>
          <UploadedFileDescription
            fileInputRef={fileInputRef}
            handleOpenValidationErrorsModal={handleOpenValidationErrorsModal}
            batchMaxSize={batchMaxSize}
            fileData={fileData}
            processingResult={processingResult}
            isFileError={isFileError}
          />
        </StyledDescriptionContent>
        <img
          onClick={() => onRemoveFile()}
          src={TrashIcon}
          alt="fileUpload"
          className="trash-icon"
        />
      </StyledDescription>
    </StyledDescriptionWrapper>
  )

  useEffect(() => {
    if (!processingResult) {
      return
    }
    if (!isFileError) {
      setBatchData(processingResult?.batchData)
    }
  }, [processingResult, setBatchData, isFileError])

  return (
    <>
      <div>
        <StyledFileUpload
          transparent={onDragState}
          isError={(fileProcessed && isFileError) || fileMissingError}
        >
          <FileDrop
            onFrameDragEnter={() => setOnDragState(true)}
            onFrameDragLeave={() => setOnDragState(false)}
            onFrameDrop={() => setOnDragState(false)}
            onDragOver={() => setOnDragState(true)}
            onDrop={(files, _) => handleDrop(files)}
          >
            {fileData?.fileName ? fileBody : bodyBeforeFile}
            {onDragState && (
              <StyledOnDragDescription>
                <img src={FileUploadIcon} alt="fileUpload" />
                <p>{t('batchFormModal.fileUpload.dragndrop.ondrag')}</p>
              </StyledOnDragDescription>
            )}
          </FileDrop>
        </StyledFileUpload>
        <input
          onChange={(event) => {
            handleDrop(event.target.files)
            event.target.value = null
          }}
          ref={fileInputRef}
          type="file"
          style={{ visibility: 'hidden' }}
        />
      </div>
      <ValidationErrorsBatchModal
        isOpened={openValidationErrors}
        onBackdropClick={onBackdropValidationErrorsClick}
        validationErrors={processingResult?.validationErrors || []}
        fileName={fileData?.fileName || ''}
      />
    </>
  )
}

export default BatchFileDragAndDrop
