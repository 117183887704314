import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import SVG from 'react-inlinesvg'
import {
  StyledNoData,
  TableStyles,
  SpinnerStyled,
  StyledImageLinkContainer,
  LeadLogo,
  StyledPagination,
  StyledProductName,
  StyledCoinPrice,
  StyledCheckmarkIcon,
  StyledCartIcon,
} from './AddressTable.style'
import { useSelector } from 'react-redux'
import plus from 'assets/images/icons/plus.svg'
import plusorange from 'assets/images/icons/plusorange.svg'
import pluswhite from 'assets/images/icons/pluswhite.svg'
import plusblue from 'assets/images/icons/plusblue.svg'
import leadIcon from 'assets/images/icons/app-lead.png'
import modernizerLead from 'assets/images/icons/app-modernizer-lead.png'
import mapsIcon from 'assets/images/icons/app-map.png'
import reportIcon from 'assets/images/icons/app-report.png'
import indexIcon from 'assets/images/icons/app-index.png'
import TableComponent from './TableComponent'
import Button from 'components/Button/Button'
import { matchSorter } from 'match-sorter'
import { StyledHeader, StyledLabel, StyledMoreButton } from 'pages/app/App.styles'
import DateCell from 'components/DateCell/DateCell'
import Sort from 'components/Sort/Sort'
import { useTranslation } from 'react-i18next'
import { FileCopy, MoreHoriz } from '@material-ui/icons'
import useOutsideClickHandler from 'hooks/useOutsideClickHandler'
import { findInArray, isProductBilled } from 'common/utils/generic'
import { generateIndicesCSV } from 'common/utils/CSVGenerator'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { ISort, SortDirection } from 'types/ISort'
import { StyledLink, StyledTag } from 'components/Tag/Tag.style'
import Chip from '@material-ui/core/Chip'
import AddressInfoModal from 'components/Modal/AddressInfoModal/AddressInfoModal'
import NewAddressModal from 'components/Modal/NewAddressModal/NewAddressModal'
import DeleteAddressModal from 'components/Modal/DeleteAddressModal/DeleteAddressModal'
import { useGetAddressListQuery, useLazyGetAddressQuery } from 'services/address-api'
import { BasicAddressEntityType } from 'types/ApiResponse'
import CopyAddressModal from 'components/Modal/CopyAddressModal/CopyAddressModal'
import { useLazyGetMapLinkQuery } from 'services/map-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import http from 'common/services/HttpService'
import { ToastStatusType, setToast } from 'slices/toastSlice'
import { useAppDispatch } from 'store/hooks'
import TableError from 'components/TableError/TableError'
import LeadModal from 'components/Modal/LeadModal/LeadModal'
import {
  clearAddressForm,
  setAddressData,
  setIsEdit,
  setProductName,
} from 'slices/addressFormSlice'
import { QueryStatus } from '@reduxjs/toolkit/query'
import line from 'assets/images/icons/line.svg'
import coinIcon from 'assets/images/icons/coin.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/radio-checked.svg'
import { selectProductsCost } from 'slices/coinsSlice'
import cartIcon from 'assets/images/icons/shopcart.svg'
import Tooltip from '@material-ui/core/Tooltip'

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = (val) => !val

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

const AddressTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentSort, setCurrentSort] = useState<ISort>({
    properties: ['creationTime'],
    direction: SortDirection.DESC,
  })
  const [search, setSearch] = useState('')

  const {
    data,
    isSuccess: isAddressListSuccess,
    isFetching: isAddressListFetching,
    isError: isAddressListError,
    refetch,
  } = useGetAddressListQuery({
    page: currentPage,
    sort: currentSort,
    search: search,
  })

  const addressData = useMemo(() => {
    return data?.content || []
  }, [data])

  const [showTableError, setShowTableError] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [isActionLoading, setActionLoading] = useState({})

  const [openNewAddressModal, setOpenNewAddressModal] = useState(false)
  const [openAddressInfo, setOpenAddressInfo] = useState(false)
  const [openLead, setOpenLead] = useState(false)
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false)
  const [openCopyAddress, setOpenCopyAddress] = useState(false)
  const [activeAddress, setActiveAddress] = useState<BasicAddressEntityType>(null)

  const [
    getMapLink,
    {
      data: mapLink,
      isSuccess: isMapLinkSuccess,
      isError: isMapLinkError,
      isFetching: isMapLinkFetching,
    },
  ] = useLazyGetMapLinkQuery()

  const [getAddress] = useLazyGetAddressQuery()

  const customerConfig = useSelector(selectCustomerConfiguration)
  const servicePrices = useSelector(selectProductsCost)

  const leadProduct = findInArray(customerConfig?.products, 'name', 'lead')
  const modernizerLeadProduct = findInArray(customerConfig?.products, 'name', 'modernizerlead')
  const reportProduct = findInArray(customerConfig?.products, 'name', 'report')
  const mapsProduct = findInArray(customerConfig?.products, 'name', 'maps')
  const indicesProduct = findInArray(customerConfig?.products, 'name', 'indices')

  const isLeadBilled = isProductBilled(leadProduct, customerConfig)
  const isModernizerLeadBilled = isProductBilled(modernizerLeadProduct, customerConfig)
  const isReportBilled = isProductBilled(reportProduct, customerConfig)
  const isMapsBilled = isProductBilled(mapsProduct, customerConfig)
  const isIndicesBilled = isProductBilled(indicesProduct, customerConfig)
  const isLeadActive = findInArray(customerConfig?.products, 'name', 'lead')?.active
  const isModernizerLeadActive = findInArray(
    customerConfig?.products,
    'name',
    'modernizerlead'
  )?.active
  const isReportActive = findInArray(customerConfig?.products, 'name', 'report')?.active
  const isMapsActive = findInArray(customerConfig?.products, 'name', 'maps')?.active
  const isIndexActive = customerConfig?.products?.find((item) => item.name === 'indices')?.active
  const priority =
    customerConfig?.configuration?.priority && JSON.parse(customerConfig?.configuration?.priority)

  useEffect(() => {
    if (isAddressListError) {
      setShowTableError(true)
    }
    if (isAddressListSuccess && !isAddressListFetching) {
      setShowTableError(false)
      setShowToastError(false)
    }
  }, [isAddressListError, isAddressListSuccess, isAddressListFetching])

  useEffect(() => {
    if (isMapLinkSuccess && mapLink && !isMapLinkFetching) {
      window.open(mapLink, '_blank')
    }
  }, [mapLink, isMapLinkSuccess, isMapLinkFetching])

  const handleFileButtonClick = (
    product: string,
    data: BasicAddressEntityType,
    isDOCX?: boolean
  ) => {
    setActionLoading({ ...isActionLoading, [data.id]: { [product]: true } })
    const fileName = `${product}_${data.zip}-${data.town}${isDOCX ? '.docx' : '.pdf'}`
    const anchor = document.createElement('a')
    anchor.setAttribute('download', fileName)
    document.body.appendChild(anchor)

    let path = `/${product}/${data.id}`
    if (product === 'report' && isDOCX) {
      path = `${path}?format=docx`
    }

    http
      .get(path, {
        responseType: 'blob',
      })
      .then((response) => {
        const objectUrl = window.URL.createObjectURL(new Blob([response.data]))
        anchor.href = objectUrl
        anchor.click()
        window.URL.revokeObjectURL(objectUrl)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.href = '/session-ended'
        } else {
          dispatch(
            setToast({
              type: ToastStatusType.ERROR,
              message: t(`addressList.download.${product}.error.toast`),
            })
          )
        }
      })
      .finally(() => {
        document.body.removeChild(anchor)
        setActionLoading({ ...isActionLoading, [data.id]: { [product]: false } })
      })
  }

  const handleMapButtonClick = (addressId: number) => {
    getMapLink({ id: addressId })
  }

  const handleIndexCsvButtonClick = (data: BasicAddressEntityType) => {
    setActionLoading({ ...isActionLoading, [data.id]: { indices: true } })
    http
      .get(`/indices/${data.id}/data`)
      .then((response) => {
        generateIndicesCSV(response.data, data.zip, data.town)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.href = '/session-ended'
        } else {
          dispatch(
            setToast({
              type: ToastStatusType.ERROR,
              message: t('addressList.download.indices-csv.error.toast'),
            })
          )
        }
      })
      .finally(() => {
        setActionLoading({ ...isActionLoading, [data.id]: { indices: false } })
      })
  }

  const getColumnColor = () => {
    if (isLeadActive && !isModernizerLeadActive) {
      return 'rgb(251, 245, 245)'
    }

    if (isModernizerLeadActive && !isLeadActive) {
      return 'rgba(185, 209, 203, 0.2)'
    }

    return 'rgb(246, 246, 246)'
  }

  const getNoDataTextColor = () => {
    if (isLeadActive && !isModernizerLeadActive) {
      return '#d49d97'
    }

    if (isModernizerLeadActive && !isLeadActive) {
      return 'rgb(137, 160, 155)'
    }

    return 'rgb(149, 147, 147)'
  }

  const handleSortChange = (sort: ISort) => {
    setCurrentPage(1)
    setCurrentSort(sort)
  }

  const handleSearchChange = (searchPhrase: string) => {
    setCurrentPage(1)
    setSearch(searchPhrase)
  }

  const handleOpenNewAddressModal = () => {
    setOpenNewAddressModal(true)
  }

  const handleOpenEditModal = async (id: number, productName: string) => {
    const addressData = await getAddress({ id: id })
    if (addressData.status === QueryStatus.fulfilled) {
      dispatch(setProductName(productName))
      dispatch(setIsEdit(true))
      dispatch(setAddressData(addressData.data))
      setOpenNewAddressModal(true)
    } else {
      dispatch(
        setToast({
          type: ToastStatusType.ERROR,
          message: t('addressList.edit-address.error.toast'),
        })
      )
    }
  }

  const onBackdropNewAddressClick = () => {
    setOpenNewAddressModal(false)
    refetch()
    dispatch(clearAddressForm())
  }

  const handleOpenAddressInfoModal = (address: BasicAddressEntityType) => {
    setActiveAddress(address)
    setOpenAddressInfo(true)
  }

  const onBackdropAddressInfoClick = () => {
    setOpenAddressInfo(false)
    setActiveAddress(null)
  }

  const handleOpenLeadModal = (address: BasicAddressEntityType) => {
    setActiveAddress(address)
    setOpenLead(true)
  }

  const onBackdropLeadClick = () => {
    setOpenLead(false)
    setActiveAddress(null)
  }

  const handleOpenDeleteAddressModal = (address: BasicAddressEntityType) => {
    setActiveAddress(address)
    setOpenDeleteAddress(true)
  }

  const onBackdropDeleteAddressClick = useCallback(() => {
    setOpenDeleteAddress(false)
    setActiveAddress(null)
  }, [])

  const handleOpenCopyAddressModal = (address: BasicAddressEntityType) => {
    setActiveAddress(address)
    setOpenCopyAddress(true)
  }

  const onBackdropCopyAddressClick = useCallback(() => {
    setOpenCopyAddress(false)
    setActiveAddress(null)
  }, [])

  useToastEffect(t('addressList.open-map.error.toast'), 'ERROR', [isMapLinkError])
  useToastEffect(t('addressList.error.toast'), 'ERROR', [isAddressListError, showToastError])

  const WithTooltip = ({ condition, title, children }) => {
    if (condition) {
      return (
        <Tooltip title={title} arrow>
          {children}
        </Tooltip>
      )
    }
    return children
  }

  if (!customerConfig) return <></>

  const columns = [
    {
      Header: () => {
        return (
          <>
            <span className="date">{t('leadsList.dateFilter')}</span>
            <Sort
              properties={['creationTime']}
              tableSort={currentSort}
              handleSortChange={handleSortChange}
            />
          </>
        )
      },
      accessor: 'creationTime',
      columns: [
        {
          accessor: 'creationTime',
          Cell: (props: any) => {
            return <DateCell date={props.cell.row.original.creationTime} />
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <>
            <span>{t('leadsList.addressFilter')}</span>
            <Sort
              properties={['street', 'houseNumber']}
              tableSort={currentSort}
              handleSortChange={handleSortChange}
            />
          </>
        )
      },
      accessor: 'address',
      columns: [
        {
          accessor: 'address',
          Cell: (props: any) => {
            const address = props.cell.row.original
            return (
              <StyledLink onClick={() => handleOpenAddressInfoModal(props.cell.row.original)}>
                <div>
                  <span style={{ fontSize: 16, display: 'block' }}>
                    {address.street} {address.houseNumber}
                  </span>
                  <StyledTag>
                    {`${address.zip || ''} ${address.town || ''}`}, {address.country}{' '}
                    <Chip label={address.category} variant="outlined" />{' '}
                    {address.isCopy && <FileCopy style={{ marginLeft: '6px' }} />}
                  </StyledTag>
                </div>
              </StyledLink>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <StyledImageLinkContainer backgroundColor="#eff7ff">
            <img src={indexIcon} className="app-icon" alt="" />
            <StyledProductName>
              <span>Index</span>
              <img src={line} className="separator" alt="" />
              {isIndicesBilled ? (
                <StyledCoinPrice>
                  <img src={coinIcon} alt="coinIcon" />
                  <span>{servicePrices.indices}</span>
                </StyledCoinPrice>
              ) : (
                <StyledCheckmarkIcon>
                  <CheckmarkIcon />
                </StyledCheckmarkIcon>
              )}
            </StyledProductName>
          </StyledImageLinkContainer>
        )
      },
      accessor: `${indicesProduct.id}`,
      productName: 'indices',
      columns: [
        {
          accessor: `${indicesProduct.id}`,
          productName: 'indices',
          Cell: (props: any) => {
            return props.cell.row.original.indicesGenerated ? (
              <>
                <SpinnerStyled>
                  <Button
                    kind="secondary"
                    onClick={() => handleFileButtonClick('indices', props.cell.row.original)}
                    loading={isActionLoading[props.cell.row.original.id]?.indices}
                    disable={isActionLoading[props.cell.row.original.id]?.indices}
                    style={{ height: '36px', width: '96px' }}
                    active={isActionLoading[props.cell.row.original.id]?.indices}
                  >
                    {isActionLoading[props.cell.row.original.id]?.indices
                      ? ''
                      : t('common.showButton')}
                  </Button>
                </SpinnerStyled>
                {props.cell.row.original.indicesCsvAvailable && (
                  <Button
                    kind="link"
                    onClick={() => handleIndexCsvButtonClick(props.cell.row.original)}
                  >
                    {t('common.showCsvButton')}
                  </Button>
                )}
              </>
            ) : (
              <Button
                className="plus-button"
                onClick={() => handleOpenEditModal(props.cell.row.original.id, 'index')}
                kind="secondary"
              >
                <SVG src={plusblue} />
              </Button>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        const areBothActive = isLeadActive && isModernizerLeadActive
        return (
          <StyledImageLinkContainer backgroundColor={getColumnColor()}>
            {isLeadActive && (
              <LeadLogo>
                <img src={leadIcon} className="app-icon" alt="" />
              </LeadLogo>
            )}
            {isModernizerLeadActive && (
              <LeadLogo>
                <img src={modernizerLead} className="app-icon" alt="" />
              </LeadLogo>
            )}
            <StyledProductName>
              <span>Lead</span>
              <img src={line} className="separator" alt="" />
              {isLeadActive &&
                (isLeadBilled ? (
                  <WithTooltip
                    title={t('addressList.lead.noActiveSubscription')}
                    condition={areBothActive}
                  >
                    <StyledCartIcon>
                      <img src={cartIcon} alt="cart.svg" />
                    </StyledCartIcon>
                  </WithTooltip>
                ) : (
                  <WithTooltip
                    title={t('addressList.lead.activeSubscription')}
                    condition={areBothActive}
                  >
                    <StyledCheckmarkIcon>
                      <CheckmarkIcon />
                    </StyledCheckmarkIcon>
                  </WithTooltip>
                ))}
              {isModernizerLeadActive &&
                (isModernizerLeadBilled ? (
                  <WithTooltip
                    title={t('addressList.modernizerlead.noActiveSubscription')}
                    condition={areBothActive}
                  >
                    <StyledCartIcon>
                      <img src={cartIcon} alt="cart.svg" />
                    </StyledCartIcon>
                  </WithTooltip>
                ) : (
                  <WithTooltip
                    title={t('addressList.modernizerlead.activeSubscription')}
                    condition={areBothActive}
                  >
                    <StyledCheckmarkIcon>
                      <CheckmarkIcon />
                    </StyledCheckmarkIcon>
                  </WithTooltip>
                ))}
            </StyledProductName>
          </StyledImageLinkContainer>
        )
      },
      accessor: `${isLeadActive ? leadProduct.id : modernizerLeadProduct.id || 'lead'}`,
      productName: 'lead',
      columns: [
        {
          accessor: `${isLeadActive ? leadProduct.id : modernizerLeadProduct.id || 'lead'}`,
          productName: 'lead',
          Cell: (props: any) => {
            const isModernizerLead = props.cell.row.original.type === 'MODERNIZER'

            return !props.cell.row.original.local &&
              ((isLeadActive && !isModernizerLead) ||
                (isModernizerLeadActive && isModernizerLead)) ? (
              <Button
                onClick={() => handleOpenLeadModal(props.cell.row.original)}
                kind={isModernizerLead ? 'green' : 'red'}
              >
                {t('common.showButton')}
              </Button>
            ) : (
              <StyledNoData color={getNoDataTextColor()}>{t('leadsList.noData')}</StyledNoData>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <StyledImageLinkContainer backgroundColor="rgb(246, 246, 246)">
            <img src={reportIcon} className="app-icon" alt="" />
            <StyledProductName>
              <span>Report</span>
              <img src={line} className="separator" alt="" />
              {isReportBilled ? (
                <StyledCoinPrice>
                  <img src={coinIcon} alt="coinIcon" />
                  <span>{servicePrices.report}</span>
                </StyledCoinPrice>
              ) : (
                <StyledCheckmarkIcon>
                  <CheckmarkIcon />
                </StyledCheckmarkIcon>
              )}
            </StyledProductName>
          </StyledImageLinkContainer>
        )
      },
      accessor: `${reportProduct.id}`,
      productName: 'report',
      columns: [
        {
          accessor: `${reportProduct.id}`,
          productName: 'report',
          Cell: (props: any) => {
            const isDOCXavailable =
              new Date(1656615600000) < new Date(props.cell.row.original.creationTime * 1000)

            return props.cell.row.original.reportGenerated ? (
              <>
                <SpinnerStyled>
                  <Button
                    kind="gray"
                    onClick={() => handleFileButtonClick('report', props.cell.row.original)}
                    loading={isActionLoading[props.cell.row.original.id]?.report}
                    disable={isActionLoading[props.cell.row.original.id]?.report}
                    style={{ height: '36px', width: '96px' }}
                    active={isActionLoading[props.cell.row.original.id]?.report}
                  >
                    {isActionLoading[props.cell.row.original.id]?.report
                      ? ''
                      : t('common.showButton')}
                  </Button>
                </SpinnerStyled>
                {isDOCXavailable && (
                  <Button
                    kind="link"
                    onClick={() => handleFileButtonClick('report', props.cell.row.original, true)}
                  >
                    {t('common.docxButton')}
                  </Button>
                )}
              </>
            ) : (
              <Button
                className="plus-button"
                onClick={() => handleOpenEditModal(props.cell.row.original.id, 'report')}
                kind="gray"
              >
                <SVG src={plus} />
              </Button>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <StyledImageLinkContainer backgroundColor="rgb(254, 248, 239)">
            <img src={mapsIcon} className="app-icon" alt="" />
            <StyledProductName>
              <span>Maps</span>
              <img src={line} className="separator" alt="" />
              {isMapsBilled ? (
                <StyledCoinPrice>
                  <img src={coinIcon} alt="coinIcon" />
                  <span>{servicePrices.maps}</span>
                </StyledCoinPrice>
              ) : (
                <StyledCheckmarkIcon>
                  <CheckmarkIcon />
                </StyledCheckmarkIcon>
              )}
            </StyledProductName>
          </StyledImageLinkContainer>
        )
      },
      accessor: `${mapsProduct.id}`,
      productName: 'maps',
      columns: [
        {
          accessor: `${mapsProduct.id}`,
          productName: 'maps',
          Cell: (props: any) => {
            return props.cell.row.original.mapGenerated ? (
              <Button
                kind="orange"
                onClick={() => handleMapButtonClick(props.cell.row.original.id)}
              >
                {t('common.showButton')}
              </Button>
            ) : (
              <Button
                className="plus-button"
                onClick={() => handleOpenEditModal(props.cell.row.original.id, 'map')}
                kind="orange"
              >
                <SVG src={plusorange} />
              </Button>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return <div style={{ width: '24px' }}></div>
      },
      accessor: 'delete',
      columns: [
        {
          accessor: 'delete',
          Cell: (props: any) => {
            const [moreDropdown, setMoreDropdown] = useState({ id: null, isActive: false })
            const moreButtonRef = useRef()

            const handleMoreButtonDropdownClose = () => {
              if (moreDropdown.id && moreDropdown.isActive) {
                setMoreDropdown({ id: null, isActive: false })
              }
            }

            useOutsideClickHandler(moreButtonRef, handleMoreButtonDropdownClose)
            return (
              <StyledMoreButton
                ref={moreButtonRef}
                onClick={() =>
                  setMoreDropdown({
                    id: props.cell.row.original.id,
                    isActive:
                      moreDropdown.id === props.cell.row.original.id
                        ? !moreDropdown.isActive
                        : true,
                  })
                }
                isDropdownVisible={
                  moreDropdown.id === props.cell.row.original.id && moreDropdown.isActive
                }
              >
                <MoreHoriz />
                <ul>
                  <li onClick={() => handleOpenCopyAddressModal(props.cell.row.original)}>
                    {t('common.moreButton.copy')}
                  </li>
                  <li onClick={() => handleOpenDeleteAddressModal(props.cell.row.original)}>
                    {t('common.moreButton.delete')}
                  </li>
                </ul>
              </StyledMoreButton>
            )
          },
        },
      ],
    },
  ]

  const sortedCollumns =
    columns.length &&
    columns
      .map((col) => ({
        ...col,
        priority:
          col?.accessor && col?.accessor !== 'delete' && priority
            ? priority[col.accessor] || 1
            : 1000,
      }))
      .sort((a, b) => a?.priority - b?.priority)

  const filterDisabledProductComlumns = (currentCollumns) => {
    return currentCollumns.filter((item) => {
      if (item.productName === 'lead' && !isLeadActive && !isModernizerLeadActive) {
        return false
      }
      if (item.productName === 'report' && !isReportActive) {
        return false
      }
      if (item.productName === 'maps' && !isMapsActive) {
        return false
      }
      if (item.productName === 'indices' && !isIndexActive) {
        return false
      }
      return item
    })
  }

  const filteredColumns = filterDisabledProductComlumns(sortedCollumns)

  return (
    <>
      <StyledHeader>
        <StyledLabel>
          <input
            placeholder={t('leadsList.search')}
            onChange={_.debounce((e) => {
              handleSearchChange(e.target.value)
            }, 700)}
          />
        </StyledLabel>
        {(isReportActive || isMapsActive || isIndexActive) && (
          <Button kind="primary" onClick={handleOpenNewAddressModal}>
            <img src={pluswhite} alt="pluswhite" /> {t('leadsList.createLeadButton')}
          </Button>
        )}
      </StyledHeader>
      <TableStyles columnsNo={filteredColumns.length} tableError={showTableError}>
        <TableComponent
          columns={filteredColumns}
          data={showTableError ? [] : addressData}
          leadColumnColor={getColumnColor()}
        />
        {showTableError && (
          <TableError
            tryAgainAction={() => {
              setShowToastError(true)
              refetch()
            }}
            message={t('addressList.error')}
            isLoading={isAddressListFetching}
          />
        )}
        {!showTableError && (
          <StyledPagination
            count={data?.totalPages}
            page={currentPage}
            onChange={(e, newPage) => setCurrentPage(newPage)}
          />
        )}
      </TableStyles>
      <AddressInfoModal
        isOpened={openAddressInfo}
        onBackdropClick={onBackdropAddressInfoClick}
        address={activeAddress}
      />
      <NewAddressModal isOpened={openNewAddressModal} onBackdropClick={onBackdropNewAddressClick} />
      <LeadModal
        isOpened={openLead}
        onBackdropClick={onBackdropLeadClick}
        address={activeAddress}
      />
      <DeleteAddressModal
        isOpened={openDeleteAddress}
        onBackdropClick={onBackdropDeleteAddressClick}
        address={activeAddress}
        onDelete={refetch}
      />
      <CopyAddressModal
        isOpened={openCopyAddress}
        onBackdropClick={onBackdropCopyAddressClick}
        address={activeAddress}
        onCopy={refetch}
      />
    </>
  )
}

export default AddressTable
