import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import Button from 'components/Button/Button'
import {
  StlyledErrorsInfoContainer,
  StyledButtonContainer,
  StyledErrorsListContainer,
  StyledErrorsListRow,
} from './ValidationErrorsBatchModal.styled'
import { useTranslation } from 'react-i18next'
import FileError from 'assets/images/icons/file_error.svg'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 720,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

function ValidationErrorsBatchModal({
  isOpened,
  onBackdropClick,
  validationErrors,
  fileName,
}: any): any {
  const classes = useStyles()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)

  const getErrorMessage = (error) => {
    if (error.errorType === 'invalidEnum') {
      return t('validationErrorsBatchModal.errors.invalidEnum', {
        value: error.validValues.join(', '),
      })
    } else if (error.errorType === 'invalidSize') {
      return t('validationErrorsBatchModal.errors.invalidSize', {
        min: error.validSize.min,
        max: error.validSize.max,
      })
    } else if (error.errorType === 'missingZipOrTown') {
      return t('validationErrorsBatchModal.errors.missingZipOrTown')
    } else if (error.errorType === 'missingStreet') {
      return t('validationErrorsBatchModal.errors.missingStreet')
    } else if (error.errorType === 'invalidValue') {
      return (
        t('validationErrorsBatchModal.errors.invalidValue') +
        (error?.validNumber !== undefined
          ? ' ' +
            t('validationErrorsBatchModal.errors.invalidValue.number', {
              min: error?.validNumber?.min,
              max: error?.validNumber?.max,
            })
          : '')
      )
    }
  }

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('validationErrorsBatchModal.title')} onBackdropClick={onBackdropClick} />
        <StlyledErrorsInfoContainer>
          <img src={FileError} alt="fileError" />
          <p>
            {t('validationErrorsBatchModal.info1')}
            <span style={{ fontWeight: 'bold' }}>{' ' + fileName + ' '}</span>
            {t('validationErrorsBatchModal.info2')}
            <span style={{ fontWeight: 'bold', color: '#A93C30' }}>
              {' ' + validationErrors.length + ' '}
              {t('validationErrorsBatchModal.info3')}
            </span>
          </p>
        </StlyledErrorsInfoContainer>
        <StyledErrorsListContainer>
          {validationErrors.map((error, index) => {
            return (
              <StyledErrorsListRow key={index}>
                <p>{index + 1 + '.'}</p>
                <p>
                  <span>{t('validationErrorsBatchModal.row') + ' '}</span>
                  {error.rowNum}
                </p>
                <p>
                  <span>{t('validationErrorsBatchModal.column') + ' '}</span>
                  {error?.colName || ''}
                </p>
                <p>{getErrorMessage(error)}</p>
              </StyledErrorsListRow>
            )
          })}
        </StyledErrorsListContainer>
        <StyledButtonContainer>
          <Button onClick={onBackdropClick}>{t('common.closeButton')}</Button>
        </StyledButtonContainer>
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      disableBackdropClick={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default ValidationErrorsBatchModal
