import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'

const INDICES = '/indices'

export const indicesApi = createApi({
  reducerPath: 'indicesApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getIndexDocument: builder.query<
      Blob,
      { id: number; type: string; isContactDetailActive: boolean }
    >({
      query: (args) => ({
        url: `${INDICES}/${args.id}?type=${args.type}&cda=${args.isContactDetailActive}`,
        method: 'GET',
        responseHandler: (response: any) => {
          return response.blob()
        },
      }),
    }),
  }),
})

export const { useLazyGetIndexDocumentQuery } = indicesApi
