import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'

const REPORT = '/report'

export const reportApi = createApi({
  reducerPath: 'reportApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getReportDocument: builder.query<Blob, { id: number; format: string }>({
      query: (args) => ({
        url: `${REPORT}/${args.id}?format=${args.format}`,
        method: 'GET',
        responseHandler: (response: any) => {
          return response.blob()
        },
      }),
    }),
  }),
})

export const { useLazyGetReportDocumentQuery } = reportApi
