import Button from 'components/Button/Button'
import styled from 'styled-components'

export const StyledNotificationError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #495057;
  max-width: 340px;
  text-wrap: pretty;
  text-align: center;
  padding: 30px 0px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

export const StyledWarningIcon = styled.div`
  path {
    stroke: #f18500;
  }
  circle {
    stroke: #f18500;
  }
  line {
    stroke: #f18500;
  }
`
export const StyledTryAgainButton = styled(Button)<any>`
  min-width: 100px;
  height: 26px;
`
