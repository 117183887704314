import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import RadioGroupButtons from 'components/RadioGroupButtons/RadioGroupButtons'
import { StyledObjectType } from './AddObjectType.styled'
import { checkValidationError } from 'common/utils/CheckValidationError'
import { useTranslation } from 'react-i18next'
import {
  includeContactDetailsSelect,
  periodTypeSelect,
  reportTypeSelect,
} from '../AddHouseParameters/helper'
import {
  selectAddressFormData,
  setEquipmentDetailsChanged,
  setFormField,
} from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'

const AddObjectType = () => {
  const dispatch = useAppDispatch()
  const addressFormData = useSelector(selectAddressFormData)
  const { t } = useTranslation()
  const isDE = addressFormData.addressData?.nation === 'DE'
  const isIndex = addressFormData.productName === 'index'
  const reportTypeSelectArray = [...reportTypeSelect]
  const periodTypeSelectArray = [...periodTypeSelect]

  const handleChangeObjectType = useCallback(
    (value: string) => {
      dispatch(setFormField({ key: 'category', value }))
      dispatch(setEquipmentDetailsChanged(true))
    },
    [dispatch]
  )

  const handleChangeReportType = (value: string) => {
    dispatch(setFormField({ key: 'indexReportType', value }))
  }

  const handleChangePeriodType = (value: string) => {
    dispatch(setFormField({ key: 'indexPeriodType', value }))
  }

  const handleChangeIncludeContactDetails = (value: string) => {
    dispatch(setFormField({ key: 'isIndexContactDetailActive', value }))
  }

  const objectTypes = [
    {
      key: 'EFH',
      value: (
        <label>
          {t(`newAddressModal.objectDataTab.objectTypes.detachedHouse`)} <p>EFH</p>
        </label>
      ),
    },
    {
      key: 'ETW',
      value: (
        <label>
          {t('newAddressModal.objectDataTab.objectTypes.condominium')} <p>ETW</p>
        </label>
      ),
    },
    {
      key: 'MFH',
      value: (
        <label>
          {t('newAddressModal.objectDataTab.objectTypes.apartment')} <p>MFH</p>
        </label>
      ),
    },
  ].filter(Boolean)

  useEffect(() => {
    if (!isIndex) return
    if (
      !addressFormData.addressData.indexReportType &&
      !addressFormData.addressData.isIndexContactDetailActive
    ) {
      dispatch(setFormField({ key: 'indexReportType', value: 'VALUATION' }))
      dispatch(setFormField({ key: 'isIndexContactDetailActive', value: false }))
    }
    if (!addressFormData.addressData.indexPeriodType) {
      dispatch(setFormField({ key: 'indexPeriodType', value: 'YEAR_1' }))
    }
  }, [
    dispatch,
    isIndex,
    addressFormData.addressData.indexReportType,
    addressFormData.addressData.indexPeriodType,
    addressFormData.addressData.isIndexContactDetailActive,
  ])

  useEffect(() => {
    if (!addressFormData.addressData.category) {
      handleChangeObjectType('EFH')
    }
  }, [addressFormData.addressData, handleChangeObjectType])

  return (
    (objectTypes.length && (
      <>
        <StyledObjectType>
          <RadioGroupButtons
            validationErrorCheckbox={checkValidationError(
              addressFormData.validationErrors,
              'category'
            )}
            options={isDE ? objectTypes : objectTypes.filter((item) => item.key !== 'MFH')}
            label={t('newAddressModal.objectDataTab.objectType')}
            onChangeProps={handleChangeObjectType}
            value={addressFormData.addressData.category}
          />
        </StyledObjectType>
        {isIndex && (
          <>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'indexReportType'
                )}
                options={reportTypeSelectArray}
                label={t('newAddressModal.objectDataTab.reportType')}
                onChangeProps={handleChangeReportType}
                value={`${addressFormData.addressData.indexReportType}`}
              />
            </StyledObjectType>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'indexPeriodType'
                )}
                options={periodTypeSelectArray}
                label={t('newAddressModal.objectDataTab.periodType')}
                onChangeProps={handleChangePeriodType}
                value={`${addressFormData.addressData.indexPeriodType}`}
              />
            </StyledObjectType>
            <StyledObjectType>
              <RadioGroupButtons
                validationErrorCheckbox={checkValidationError(
                  addressFormData.validationErrors,
                  'isIndexContactDetailActive'
                )}
                options={includeContactDetailsSelect}
                label={t('newAddressModal.objectDataTab.includeContactDetails')}
                onChangeProps={handleChangeIncludeContactDetails}
                value={`${addressFormData.addressData.isIndexContactDetailActive}`}
              />
            </StyledObjectType>
          </>
        )}
      </>
    )) ||
    null
  )
}

export default AddObjectType
