import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const handleButtonBackground = (kind) => {
  switch (kind) {
    case 'primary':
      return '#044f98'
    case 'secondary':
      return 'transparent'
    case 'tertiary':
      return '#ffd300'
    case 'red':
      return '#fbe8e5'
    case 'gray':
      return '#e9e9e7'
    case 'orange':
      return '#fdeed6'
    case 'image':
      return 'transparent'
    case 'link':
      return 'transparent'
    case 'green':
      return '#b9d1cb'
    default:
      return '#0b4f98'
  }
}

const handleButtonHoverBackground = (kind) => {
  switch (kind) {
    case 'primary':
      return '#1f497d'
    case 'secondary':
      return '#044f98'
    case 'tertiary':
      return '#ceb623'
    case 'red':
      return '#a93c30'
    case 'gray':
      return `${theme.colors.dark}`
    case 'orange':
      return '#ef8633'
    case 'image':
      return 'transparent'
    case 'link':
      return 'transparent'
    case 'green':
      return '#60726c'
    default:
      return '#0b4f98'
  }
}

const handleButtonColor = (kind, disabled) => {
  if (disabled) {
    return '#D4E5F7'
  }
  switch (kind) {
    case 'primary':
      return '#fff'
    case 'secondary':
      return '#0b4f98'
    case 'tertiary':
      return '#495057'
    case 'red':
      return '#a93c30'
    case 'gray':
      return `${theme.colors.dark}`
    case 'orange':
      return '#ef8633'
    case 'image':
      return '#0b4f98'
    case 'link':
      return '#0b4f98'
    case 'green':
      return '#60726c'
    default:
      return '#fff'
  }
}

const handleSpinnerColor = (kind) => {
  if (kind === 'secondary') {
    return '#044f98'
  }
  return 'white'
}

const handleHoverButtonColor = (kind) => {
  switch (kind) {
    case 'primary':
      return '#fff'
    case 'secondary':
      return '#fff'
    case 'tertiary':
      return '#495057'
    case 'red':
      return '#fff'
    case 'gray':
      return '#fff'
    case 'orange':
      return '#fff'
    case 'image':
      return '#0b4f98'
    case 'link':
      return '#0b4f98'
    default:
      return '#fff'
  }
}

const handleButtonBorder = (kind, disabled) => {
  if (disabled) {
    return '1px solid #D4E5F7'
  }
  switch (kind) {
    case 'primary':
      return '#044f98'
    case 'secondary':
      return '1px solid #0b4f98'
    case 'tertiary':
      return '0'
    case 'red':
      return '1px solid #a93c30'
    case 'gray':
      return `1px solid ${theme.colors.darkMedium}`
    case 'orange':
      return '1px solid #ef8633'
    case 'image':
      return 'none'
    case 'link':
      return 'none'
    case 'green':
      return '1px solid #60726c'
    default:
      return '#0b4f98'
  }
}

export const StyledButton = styled.div<any>`
  .MuiButton-containedPrimary {
    background-color: ${({ kind }) => handleButtonBackground(kind)};
    color: ${({ kind, disabled }) => handleButtonColor(kind, disabled)};
    border: ${({ kind, noBorder, disabled }) =>
      !noBorder ? handleButtonBorder(kind, disabled) : '1px solid transparent'};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-transform: none;
    box-shadow: none;
    text-decoration: none;
    pointer-events: ${(props: any) => (props.disabled ? 'none' : 'inherit')};
    transition: 0.3s;
    text-decoration: ${({ kind }) => (kind !== 'link' ? 'unset' : 'underline')};

    svg rect {
      transition: 0.3s;
    }

    &:hover {
      background-color: ${({ kind }) => handleButtonHoverBackground(kind)};
      color: ${({ kind }) => handleHoverButtonColor(kind)};

      svg rect {
        fill: #fff;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    &::before {
      background-color: transparent;
    }

    ${({ active }) =>
      active &&
      `
        background-color: ${theme.colors.darkMedium};
        svg rect {
          fill: #fff;
        }
      }
  `}
  }

  .MuiCircularProgress-root {
    margin-left: 10px;
  }

  .MuiCircularProgress-colorSecondary {
    color: ${({ kind }) =>
      kind !== 'link' ? handleSpinnerColor(kind) : handleButtonColor(kind, false)};
  }

  &:hover .MuiCircularProgress-colorSecondary {
    color: white;
  }
`
