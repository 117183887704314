import React from 'react'
import { useSelector } from 'react-redux'
import Map from 'components/Map/Map'
import { useDebouncedCallback } from 'use-debounce'
import { StyledAddressPanel } from './AddAddress.styles'
import getCountriesCode from 'common/utils/getCountrieCode'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useAppDispatch } from 'store/hooks'
import { setFormField } from 'slices/addressFormSlice'
import { AddressGeocoded } from 'types/AddressGeocoded'

const AddAddress = () => {
  const dispatch = useAppDispatch()
  const customerConfig = useSelector(selectCustomerConfiguration)
  const countries = getCountriesCode(customerConfig.name)

  const onChange = useDebouncedCallback((address: AddressGeocoded) => {
    dispatch(setFormField({ key: 'lat', value: address.lat }))
    dispatch(setFormField({ key: 'lng', value: address.lng }))
    dispatch(setFormField({ key: 'houseNumber', value: address.houseNumber }))
    dispatch(setFormField({ key: 'street', value: address.street }))
    dispatch(setFormField({ key: 'zip', value: address.zip }))
    dispatch(setFormField({ key: 'town', value: address.town }))
    dispatch(setFormField({ key: 'nation', value: address.nation }))
    dispatch(setFormField({ key: 'category', value: 'EFH' }))
  }, 500)

  return (
    <StyledAddressPanel>
      <Map
        latitude={customerConfig.mapOptions.lat || 50.548187591116715}
        longitude={customerConfig.mapOptions.lng || 7.131986305434703}
        countries={countries}
        onChange={onChange}
      />
    </StyledAddressPanel>
  )
}

export default AddAddress
