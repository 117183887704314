import React, { useState } from 'react'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { StyledBuyCoinsMenu } from './BuyCoinsMenu.style'
import MenuItem from '@material-ui/core/MenuItem'
import Link from 'components/Link/Link'

const BuyCoinsMenu = (props: any) => {
  const { prePaidCoinsLink, subscriptionCoinsLink, channelName, subscriptionCoinsDisabled } = props
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="buy-coins-button"
        kind="tertiary"
        className="reset-transform"
        aria-controls={open ? 'buy-coins-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('common.coinsButton')}
      </Button>
      <StyledBuyCoinsMenu
        id="buy-coins-menu"
        MenuListProps={{
          'aria-labelledby': 'buy-coins-button',
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {prePaidCoinsLink?.active && (
          <MenuItem onClick={handleClose} disableRipple>
            <Link
              url={prePaidCoinsLink?.url}
              newTab={prePaidCoinsLink?.newTab}
              channelName={channelName}
            >
              {t('nav.coins.premium')}
            </Link>
          </MenuItem>
        )}
        {subscriptionCoinsLink?.active && subscriptionCoinsLink?.url && (
          <MenuItem onClick={handleClose} disableRipple disabled={subscriptionCoinsDisabled}>
            <Link
              url={subscriptionCoinsLink?.url}
              newTab={subscriptionCoinsLink?.newTab}
              channelName={channelName}
              isDisabled={subscriptionCoinsDisabled}
            >
              {t('nav.coins.subscription')}
            </Link>
          </MenuItem>
        )}
      </StyledBuyCoinsMenu>
    </div>
  )
}

export default BuyCoinsMenu
