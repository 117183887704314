export const mapAddress = (context: [], key: any) => {
  let response
  context.forEach((ctx: any) => {
    if (ctx.id.startsWith('country') && key === 'text') {
      response = ctx.text
    }
    if (ctx.id.startsWith('country') && key === 'short_code') {
      response = ctx.short_code.toUpperCase()
    }
  })
  return response
}
