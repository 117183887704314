import styled from 'styled-components'
import { respondFrom } from 'common/styles/Responsive.styles'

export const StyledRadioGroupContainer = styled.div<any>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const StyledRadioGroupRow = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const StyledRadioGroupCol = styled.div<any>`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-direction: column;

  label {
    display: block;
  }

  ${respondFrom.smallDesktop`
    padding: 5px;
    width: 33.33%;
  `}

  ${respondFrom.lessThanTablet`
    padding: 5px;
    width: 50%;
  `}

  .MuiFormControl-root .MuiFormGroup-root {
    width: 100%;
    justify-content: flex-start;

    .MuiFormControlLabel-root {
      height: 29px;
    }
  }

  span {
    font-size: 14px;
  }

  legend.MuiFormLabel-root {
    color: #495057;
    font-size: 14px;
  }
`
