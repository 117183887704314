import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import Address from './LeadModalComponents/Address/Address'
import LeadPersonalData from './LeadModalComponents/LeadPersonalData/LeadPersonalData'
import { StyledButtonContainer } from './LeadModal.styled'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import AnonymizationCounter from './LeadModalComponents/AnonymizationCounter/AnonymizationCounter'
import { BasicAddressEntityType } from 'types/ApiResponse'
import { useGetAddressQuery } from 'services/address-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import LoadError from './LeadModalComponents/LoadError/LoadError'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 720,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

export interface LeadModalProps {
  isOpened: boolean
  address: BasicAddressEntityType | null
  onBackdropClick: () => void
}

const LeadModal = (props: LeadModalProps) => {
  const { isOpened, address, onBackdropClick } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)
  const [showToastError, setShowToastError] = useState(false)

  const { data, isError, isFetching, isSuccess, refetch } = useGetAddressQuery(
    { id: address?.id },
    {
      skip: isOpened === false,
    }
  )

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setShowToastError(false)
    }
  }, [isSuccess, isFetching])

  useToastEffect(t('leadModal.error.toast'), 'ERROR', [isError, showToastError])

  const onCloseModal = () => {
    onBackdropClick()
    setShowToastError(false)
  }

  if (address === null) {
    return <></>
  }

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('leadModal.title')} onBackdropClick={onCloseModal} />
        {!(isError || showToastError) && isSuccess && (
          <>
            <Address data={data} />
            <LeadPersonalData data={data} />
          </>
        )}
        {(isError || showToastError) && (
          <LoadError
            tryAgainAction={() => {
              setShowToastError(true)
              refetch()
            }}
            isLoading={isFetching}
          />
        )}
        <StyledButtonContainer>
          {!(isError || showToastError) && isSuccess ? (
            <AnonymizationCounter leadCreationTime={data.creationTime} leadName={data.name} />
          ) : (
            <div />
          )}
          <Button kind="primary" onClick={onCloseModal}>
            {t('common.closeButton')}
          </Button>
        </StyledButtonContainer>
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick={true}
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default LeadModal
