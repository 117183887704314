import styled from 'styled-components'

export const SessionEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 750px;
  color: #495057;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

export const StyledInfoIcon = styled.div`
  circle {
    stroke: #044f98;
  }
  line {
    stroke: #044f98;
  }
`
