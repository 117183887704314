import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { BatchEntityType } from 'types/ApiResponse'

export interface CurrentBatchDraftState {
  currentBatchDraft: BatchEntityType | null
}

const initialState: CurrentBatchDraftState = {
  currentBatchDraft: null,
}

const currentBatchDraftSlice = createSlice({
  name: 'currentBatchDraft',
  initialState,
  reducers: {
    setCurrentBatchDraft: (state, action) => {
      state.currentBatchDraft = action.payload
    },
  },
})

export const { setCurrentBatchDraft } = currentBatchDraftSlice.actions

export const selectCurrentBatchDraft = (state: RootState) =>
  state.currentBatchDraft?.currentBatchDraft
export default currentBatchDraftSlice.reducer
