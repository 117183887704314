import React from 'react'
import SprengnetterAppsValue from 'assets/images/icons/SprengnetterAppsValue.png'
import SprengnetterAppsCalc from 'assets/images/icons/SprengnetterAppsCalc.png'
import SprengnetterAppsBooks from 'assets/images/icons/SprengnetterAppsBooks.png'
import SprengnetterAppsDefault from 'assets/images/icons/SprengnetterAppsDefault.png'
import SprengnetterAppsLead from 'assets/images/icons/SprengnetterAppsLead.png'
import SprengnetterAppsReport from 'assets/images/icons/SprengnetterAppsReport.png'
import SprengnetterAppsMaps1x from 'assets/images/icons/SprengnetterAppsMaps1x.png'
import SprengnetterAppsF2C from 'assets/images/icons/SprengnetterAppsF2C.png'
import SprengnetterAppsAvmValuation from 'assets/images/icons/SprengnetterAppsAvmValuation.png'
import ProductTile from 'components/ProductTile/ProductTile'
import ProductListTile from 'components/ProductListTile/ProductListTile'
import { useSetUserConfigurationMutation } from 'services/configuration-api'

const getIconById = (id: string): string => {
  switch (id) {
    case 'value':
      return SprengnetterAppsValue
    case 'calc':
      return SprengnetterAppsCalc
    case 'books':
      return SprengnetterAppsBooks
    case 'five-2-click':
      return SprengnetterAppsF2C
    case 'lead':
      return SprengnetterAppsLead
    case 'report':
      return SprengnetterAppsReport
    case 'maps':
      return SprengnetterAppsMaps1x
    case 'avm_valuation':
      return SprengnetterAppsAvmValuation
    default:
      return SprengnetterAppsDefault
  }
}

const ProductWrapper = (props: any) => {
  const { product, viewType } = props
  const icon = getIconById(product.name.toLowerCase())

  const [updateUserConfiguration] = useSetUserConfigurationMutation()

  const saveFavorites = (favorites: Array<number>) => {
    updateUserConfiguration({ name: 'favoritesList', value: JSON.stringify(favorites) })
  }

  return viewType === 'module' ? (
    <ProductTile icon={icon} saveFavorites={saveFavorites} {...props} />
  ) : (
    <ProductListTile icon={icon} saveFavorites={saveFavorites} {...props} />
  )
}

export default ProductWrapper
