import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import { default as MaterialTabs } from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { StyledTabsWrapper } from './BatchTabs.styled'
import SVG from 'react-inlinesvg'
import checkmarkImage from 'assets/images/icons/checkmark.svg'
import { useTranslation } from 'react-i18next'
import BatchFileUpload from '../BatchFileUpload/BatchFileUpload'
import BatchCheckout from '../BatchCheckout/BatchCheckout'
import { useSelector } from 'react-redux'
import { selectCurrentBatchDraft } from 'slices/currentBatchDraftSlice'

function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function BatchTabs({ onBackdropClick }: any): any {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0)
  const batchDraft = useSelector(selectCurrentBatchDraft)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    if (batchDraft?.uuid) {
      setCurrentTab(1)
    }
  }, [batchDraft])

  return (
    <StyledTabsWrapper>
      <AppBar position="relative">
        <MaterialTabs
          value={currentTab}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          scrollButtons="off"
        >
          <Tab
            label={t('batchFormModal.fileUpload.label')}
            disabled
            {...a11yProps(0)}
            icon={currentTab > 0 && <SVG src={checkmarkImage} />}
          />
          <Tab
            label={t('batchFormModal.retrieval.label')}
            {...a11yProps(1)}
            disabled
            icon={currentTab > 1 && <SVG src={checkmarkImage} />}
          />
        </MaterialTabs>
      </AppBar>
      <TabPanel value={currentTab} index={0}>
        <BatchFileUpload setCurrentTab={setCurrentTab} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <BatchCheckout batchDraft={batchDraft} onBackdropClick={onBackdropClick} />
      </TabPanel>
    </StyledTabsWrapper>
  )
}

export default BatchTabs
