import { AddressGeocoded } from 'types/AddressGeocoded'
import { mapAddress } from './mapAddress'

export const sortByDate = (data: any) => {
  return data.sort((a, b) => a.startDate - b.startDate).reverse()
}

export const mapMapBoxPlaceResponse = (response: any): AddressGeocoded => {
  const address = response?.features ? response.features[0] : response
  return {
    nation: mapAddress(address.context, 'short_code'),
    town: address.context.find((item) => item.id.includes('place'))?.text,
    street: address.text,
    houseNumber: address.address,
    zip: address.context[0].text,
    lat: address.geometry.coordinates[1],
    lng: address.geometry.coordinates[0],
  }
}
