import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledAddressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`
export const StyledThumb = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 10px;
  aspect-ratio: 4/3;

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
`
export const StyledAddress = styled.div`
  /* max-width: 570px; */
  width: 100%;
  height: 81px;
  background-color: #f6f6f6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledAddressRow = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 14px;
  color: #92969a;

  :first-child {
    font-size: 16px;
    color: #495057;
  }

  :last-child {
    font-size: 12px;
  }

  p {
    min-width: 35px;
    min-height: 18px;
    margin: 0 0 0 5px;
    /* padding: 1px 14px 0 14px; */
    background-color: #ffffff;
    color: ${theme.colors.dark};
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    /* vertical-align: middle; */
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`
