import { createSlice } from '@reduxjs/toolkit'
import { configurationApi } from 'services/configuration-api'
import { RootState } from 'store/store'
import { CustomerConfigurationEntityType } from 'types/ApiResponse'

export interface CustomerConfigurationState {
  configuration: CustomerConfigurationEntityType | null
}

const initialState: CustomerConfigurationState = {
  configuration: null,
}

const customerConfigurationSlice = createSlice({
  name: 'customerConfiguration',
  initialState,
  reducers: {
    setCustomerConfiguration: (state, action) => {
      state.configuration = action.payload
    },
    setUserConfiguration: (state, action) => {
      state.configuration = {
        ...state.configuration,
        userConfiguration: {
          ...state.configuration.userConfiguration,
          ...action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      configurationApi.endpoints.setUserConfiguration.matchFulfilled,
      (state, action) => {
        state.configuration = {
          ...state.configuration,
          userConfiguration: {
            ...state.configuration.userConfiguration,
            ...action.payload,
          },
        }
      }
    )
  },
})

export const { setCustomerConfiguration, setUserConfiguration } = customerConfigurationSlice.actions

export const selectCustomerConfiguration = (state: RootState) =>
  state.customerConfiguration.configuration

export default customerConfigurationSlice.reducer
