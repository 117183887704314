import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledButtonContainer, StyledContainer } from './BatchCheckout.styled'
import Button from 'components/Button/Button'
import { useSelector } from 'react-redux'
import getProperUrl from 'common/utils/getProperUrl'
import Link from 'components/Link/Link'
import BatchIcon from 'assets/images/icons/SprengnetterAppsAvmValuation.png'
import { useProcessBatchDraftMutation } from 'services/batch-api'
import ProductGenerationError from 'components/ProductGenerationError/ProductGenerationError'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { selectCustomerCoins, selectProductCost } from 'slices/coinsSlice'
import { useLazyGetCustomerCoinsQuery } from 'services/coins-api'
import ProductGenerationSuccess from 'components/ProductGenerationSuccess/ProductGenerationSuccess'
import { findInArray, isProductBilled } from 'common/utils/generic'

const BatchCheckout = (props: any) => {
  const { t } = useTranslation()
  const { batchDraft, onBackdropClick } = props
  const servicePrice = useSelector(selectProductCost('avm_valuation'))
  const customerConfig = useSelector(selectCustomerConfiguration)
  const customerCoins = useSelector(selectCustomerCoins)
  const premiumCoins =
    customerCoins?.coins?.accounts?.find((item) => item.type === 'on-demand')?.amount || 0
  const subscriptionCoinsData = customerCoins.coins?.accounts?.find(
    (item) => item.type === 'subscription'
  )
  const subscriptionCoins = subscriptionCoinsData?.amount
  const coinsLink = getProperUrl(customerConfig, 'coins')
  const subscriptionCoinsLink = getProperUrl(customerConfig, 'coins-subscription')
  const batchProduct = findInArray(customerConfig?.products, 'name', 'avm_valuation')

  const isBatchBilled = isProductBilled(batchProduct, customerConfig)
  const batchPrice = servicePrice * batchDraft.batchSize
  const batchIcon = batchProduct?.imageUrl || BatchIcon
  const [showToastError, setShowToastError] = useState(false)

  const [processBatchDraft, { isSuccess, isError, isLoading }] = useProcessBatchDraftMutation()

  const [getCustomerCoins] = useLazyGetCustomerCoinsQuery()

  const onBatchCreateClick = () => {
    if (isLoading) {
      return
    }
    processBatchDraft({ uuid: batchDraft.uuid })
  }

  useEffect(() => {
    if (isSuccess) {
      getCustomerCoins()
      setShowToastError(false)
    }
  }, [isSuccess, getCustomerCoins])

  useToastEffect(t('batchFormModal.retrieval.error.toast'), 'ERROR', [isError, showToastError])

  const NoCoinsContainer = () => {
    return (
      <StyledContainer>
        <img src={batchIcon} alt="" className="app-icon" />
        <h1>{t('batchFormModal.retrieval.noCoinsTitle')}</h1>
        <p className="no-coins-info">
          {t('batchFormModal.retrieval.noCoinsDescription', {
            value: batchPrice,
          })}
        </p>
        <StyledButtonContainer>
          {coinsLink?.active && (
            <Link
              url={coinsLink?.url}
              newTab={coinsLink?.newTab}
              channelName={customerConfig?.name}
            >
              <Button kind="tertiary">{t('common.coinsButton.prePaid')}</Button>
            </Link>
          )}
          {subscriptionCoinsLink?.active &&
            subscriptionCoinsLink?.url &&
            (subscriptionCoinsData === undefined ? (
              <Link
                url={subscriptionCoinsLink?.url}
                newTab={subscriptionCoinsLink?.newTab}
                channelName={customerConfig?.name}
              >
                <Button kind="tertiary">{t('common.coinsButton.subscription')}</Button>
              </Link>
            ) : (
              <Button type="disable" className="disabled-subscription-coins-button">
                {t('common.coinsButton.subscription')}
              </Button>
            ))}
          <Button type="disable" className="no-coins-button">
            {t('common.generateBatch')}
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    )
  }

  return (
    <>
      {!isSuccess &&
        !isError &&
        !showToastError &&
        (!isBatchBilled ||
        ((premiumCoins || subscriptionCoins) &&
          (batchPrice <= subscriptionCoins || batchPrice <= premiumCoins)) ? (
          <StyledContainer>
            <img src={batchIcon} alt="" className="app-icon" />
            <h1>{t('common.generateBatch')}</h1>
            {isBatchBilled && (
              <p>
                {t('batchFormModal.retrieval.description', {
                  value: batchPrice,
                })}
              </p>
            )}
            <div onClick={() => onBatchCreateClick()}>
              <Button kind="primary" loading={isLoading} disabled={isLoading}>
                {isLoading ? t('common.loadingButton') : t('common.generateBatch')}
              </Button>
            </div>
          </StyledContainer>
        ) : (
          <NoCoinsContainer />
        ))}
      {isSuccess && (
        <ProductGenerationSuccess
          icon={batchIcon}
          title={t('batchFormModal.retrieval.successTitle')}
          message1={t('batchFormModal.retrieval.downloadDescription')}
          message2={t('batchFormModal.retrieval.successDescription')}
        >
          <Button kind="secondary" onClick={onBackdropClick}>
            {t('common.closeButton')}
          </Button>
        </ProductGenerationSuccess>
      )}
      {(isError || showToastError) && (
        <ProductGenerationError
          icon={batchIcon}
          title={t('batchFormModal.retrieval.errorTitle')}
          message1={t('batchFormModal.retrieval.errorDescription.1')}
          message2={t('batchFormModal.retrieval.errorDescription.2')}
          isLoading={isLoading}
          tryAgainAction={() => {
            setShowToastError(true)
            onBatchCreateClick()
          }}
        />
      )}
    </>
  )
}

export default BatchCheckout
