import styled from 'styled-components'
import { respondFrom } from 'common/styles/Responsive.styles'

export const StyledButtonsRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  ${respondFrom.lessThanTablet`
  flex-direction: column;
`};
`

export const StyledToggleButtonContainer = styled.div`
  max-height: 35px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 10px;
`

export const StyledProductDates = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(124, 124, 123, 0.2);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  gap: 16px;
`

export const StyledProductTitle = styled.span`
  color: #92969a;
`

export const StyledProductDate = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledProductDateName = styled.span`
  color: #7c7c7b;
`

export const StyledProductDateValue = styled.span`
  color: #495057;
`

export const StyledListIcon = styled.div`
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
  path {
    stroke: ${({ isActive }: any) => (isActive ? '#044F98' : '#495057')};
  }
`

export const StyledGridIcon = styled.div`
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
  rect {
    stroke: ${({ isActive }: any) => (isActive ? '#044F98' : '#495057')};
  }
`

export const StyledAppsLabel = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #495057;
  margin-top: 12px;
  margin-bottom: 12px;
`
