import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { CustomerConfigurationEntityType } from 'types/ApiResponse'

const CONFIGURATION = '/customer/configuration'

export const configurationApi = createApi({
  reducerPath: 'configurationApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getCustomerConfiguration: builder.query<CustomerConfigurationEntityType, void>({
      query: () => ({
        url: `${CONFIGURATION}`,
        method: 'GET',
      }),
    }),
    setUserConfiguration: builder.mutation<any, { name: string; value: string }>({
      query: (args) => ({
        url: `${CONFIGURATION}/${args.name}`,
        method: 'PUT',
        body: { value: args.value },
      }),
    }),
  }),
})

export const { useGetCustomerConfigurationQuery, useSetUserConfigurationMutation } =
  configurationApi
