import React from 'react'
import styled from 'styled-components'
import footerIcon1 from 'assets/images/icons/footer-icon-1.svg'
import footerIcon2 from 'assets/images/icons/footer-icon-2.svg'
import footerIcon3 from 'assets/images/icons/footer-icon-3.svg'
import footerIcon4 from 'assets/images/icons/footer-icon-4.svg'
import line from 'assets/images/icons/line.svg'
import bulbIcon from 'assets/images/icons/lightbulb.svg'
import { respondFrom } from 'common/styles/Responsive.styles'
import { useSelector } from 'react-redux'
import getProperUrl from 'common/utils/getProperUrl'
import { useTranslation } from 'react-i18next'
import Link from 'components/Link/Link'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'

export const StyledFooter = styled.div`
  background-color: #ededed;
  padding-top: 30px;
  padding: 10px 20px;
  height: 60px;
`

export const StyledFooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img.separator {
    margin: 0 20px;
  }
  ${respondFrom.lessThanTablet`
    flex-direction: column;
  `}
`

export const StyledFooterItem = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }

  span {
    color: #495057;
    size: 14px;
    ${respondFrom.lessThanTablet`
      white-space: nowrap;
    `}
  }

  img {
    margin-right: 5px;
  }
`

function Contact(): any {
  const customerConfig = useSelector(selectCustomerConfiguration)
  const { t } = useTranslation()
  const appsLink = getProperUrl(customerConfig, 'uber')
  const faqLink = getProperUrl(customerConfig, 'help/FAQ')
  const orderFulfillmentLink = getProperUrl(customerConfig, 'DSGVO')
  const termsOfUseLink = getProperUrl(customerConfig, 'Nutzungsbed.')
  const imprintLink = getProperUrl(customerConfig, 'impressum')
  const privacyLink = getProperUrl(customerConfig, 'privacy')
  const instructionLink = getProperUrl(customerConfig, 'was ist neu?')

  return (
    <StyledFooter>
      <StyledFooterWrapper>
        {instructionLink?.active && (
          <>
            <StyledFooterItem>
              <img src={bulbIcon} alt="" />
              {(instructionLink?.url.length && (
                <Link
                  url={instructionLink?.url}
                  newTab={instructionLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('nav.instruction')}</span>
                </Link>
              )) || <span>{t('nav.instruction')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {appsLink?.active && (
          <>
            <StyledFooterItem>
              <img src={footerIcon1} alt="" />
              {(appsLink?.url.length && (
                <Link
                  url={appsLink?.url}
                  newTab={appsLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('footer.apps')}</span>
                </Link>
              )) || <span>{t('footer.apps')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {faqLink?.active && (
          <>
            <StyledFooterItem>
              <img src={footerIcon2} alt="" />
              {(faqLink?.url.length && (
                <Link
                  url={faqLink?.url}
                  newTab={faqLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('footer.faq')}</span>
                </Link>
              )) || <span>{t('footer.faq')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {orderFulfillmentLink?.active && (
          <>
            <StyledFooterItem>
              <img src={footerIcon3} alt="" />
              {(orderFulfillmentLink?.url.length && (
                <Link
                  url={orderFulfillmentLink?.url}
                  newTab={orderFulfillmentLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('footer.orderFulfillment')}</span>
                </Link>
              )) || <span>{t('footer.orderFulfillment')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {termsOfUseLink?.active && (
          <>
            <StyledFooterItem>
              <img src={footerIcon4} alt="" />
              {(termsOfUseLink?.url.length && (
                <Link
                  url={termsOfUseLink?.url}
                  newTab={termsOfUseLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('footer.termsOfUse')}</span>
                </Link>
              )) || <span>{t('footer.termsOfUse')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {imprintLink?.active && (
          <>
            <StyledFooterItem>
              <img src={footerIcon1} alt="" />
              {(imprintLink?.url.length && (
                <Link
                  url={imprintLink?.url}
                  newTab={imprintLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <span>{t('footer.imprint')}</span>
                </Link>
              )) || <span>{t('footer.imprint')}</span>}
            </StyledFooterItem>
            <img src={line} className="separator" alt="" />
          </>
        )}
        {privacyLink?.active && (
          <StyledFooterItem>
            <img src={footerIcon3} alt="" />
            {(privacyLink?.url?.length && (
              <Link
                url={privacyLink?.url}
                newTab={privacyLink?.newTab}
                channelName={customerConfig?.name}
              >
                <span>{t('footer.privacy')}</span>
              </Link>
            )) || <span>{t('footer.privacy')}</span>}
          </StyledFooterItem>
        )}
      </StyledFooterWrapper>
    </StyledFooter>
  )
}

export default Contact
