import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom'
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull'
import WarningIconMUI from '@material-ui/icons/Warning'
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive'
import Button from 'components/Button/Button'
import {
  StyledNav,
  StyledContainer,
  StyledNavItem,
  StyledTitle,
  StyledArrow,
  StyledSpanItem,
  StyledNavLinkContainer,
  StyledNavItemsContainer,
  StyledCoinsContainer,
  StyledCoinsInformationDropdown,
  StyledCoinItem,
  StyledCoinItemHelper,
  StyledCoinsError,
  StyledWarningIcon,
  StyledTryAgainButton,
} from './Nav.style'
import backIcon from 'assets/images/icons/back.svg'
import coinIcon from 'assets/images/icons/coin.svg'
import SprengnetterAppsValue from 'assets/images/icons/SprengnetterAppsValue.png'
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings.svg'
import lineIcon from 'assets/images/icons/line.svg'
import { ReactComponent as AddressListIcon } from 'assets/images/icons/addressList.svg'
import { ReactComponent as BatchListIcon } from 'assets/images/icons/batchList.svg'
import getProperUrl from 'common/utils/getProperUrl'
import { useTranslation } from 'react-i18next'
import Link from 'components/Link/Link'
import NotificationsManager from 'components/Notifications/NotificationsManager'
import { findInArray } from 'common/utils/generic'
import BuyCoinsMenu from './BuyCoinsMenu/BuyCoinsMenu'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useGetCustomerCoinsQuery } from 'services/coins-api'
import { selectCustomerCoins } from 'slices/coinsSlice'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

const stepsPremiumCoins = [600, 1200, 1800, 2400, 3000]
// const stepsSubscriptionCoins = [30, 60, 90, 120, 150]

const getBars = (value, steps, fillColor, bgColor) => {
  return (
    <svg width="32" height="16" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82959 -6.55668e-08C2.65802 -2.93553e-08 3.32959 0.671573 3.32959 1.5L3.32959 12.5C3.32959 13.3284 2.65802 14 1.82959 14C1.00116 14 0.329589 13.3284 0.329589 12.5L0.32959 1.5C0.32959 0.671573 1.00116 -1.01778e-07 1.82959 -6.55668e-08Z"
        fill={value >= steps[0] ? fillColor : bgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82959 -6.55668e-08C8.65802 -2.93553e-08 9.32959 0.671573 9.32959 1.5L9.32959 12.5C9.32959 13.3284 8.65802 14 7.82959 14C7.00116 14 6.32959 13.3284 6.32959 12.5L6.32959 1.5C6.32959 0.671573 7.00116 -1.01778e-07 7.82959 -6.55668e-08Z"
        fill={value >= steps[1] ? fillColor : bgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8296 -6.55668e-08C14.658 -2.93553e-08 15.3296 0.671573 15.3296 1.5L15.3296 12.5C15.3296 13.3284 14.658 14 13.8296 14C13.0012 14 12.3296 13.3284 12.3296 12.5L12.3296 1.5C12.3296 0.671573 13.0012 -1.01778e-07 13.8296 -6.55668e-08Z"
        fill={value >= steps[2] ? fillColor : bgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8296 -6.55668e-08C20.658 -2.93553e-08 21.3296 0.671573 21.3296 1.5L21.3296 12.5C21.3296 13.3284 20.658 14 19.8296 14C19.0012 14 18.3296 13.3284 18.3296 12.5L18.3296 1.5C18.3296 0.671573 19.0012 -1.01778e-07 19.8296 -6.55668e-08Z"
        fill={value >= steps[3] ? fillColor : bgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8296 -6.55668e-08C26.658 -2.93553e-08 27.3296 0.671573 27.3296 1.5L27.3296 12.5C27.3296 13.3284 26.658 14 25.8296 14C25.0012 14 24.3296 13.3284 24.3296 12.5L24.3296 1.5C24.3296 0.671573 25.0012 -1.01778e-07 25.8296 -6.55668e-08Z"
        fill={value >= steps[4] ? fillColor : bgColor}
      />
    </svg>
  )
}

const getDate = (date) => {
  return date
    ? `${new Date(date * 1000).toLocaleDateString('pl-PL')} ${new Date(
        date * 1000
      ).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    : null
}

const BuyCoinsButton = (props: any) => {
  const {
    prePaidCoinsLink,
    subscriptionCoinsLink,
    subscriptionCoinsData,
    channelName,
    coinsButtonLabel,
    isCoinsError,
  } = props
  const prePaidCoinsEnabled = prePaidCoinsLink?.active && prePaidCoinsLink?.url
  const subscriptionCoinsEnabled = subscriptionCoinsLink?.active && subscriptionCoinsLink?.url
  const subscriptionCoinsActive = subscriptionCoinsEnabled && subscriptionCoinsData === undefined
  if (isCoinsError) {
    return (
      <Button type="disable" className="disabled-coins-button">
        {coinsButtonLabel}
      </Button>
    )
  }
  if (prePaidCoinsEnabled && subscriptionCoinsEnabled) {
    return (
      <BuyCoinsMenu
        prePaidCoinsLink={prePaidCoinsLink}
        subscriptionCoinsLink={subscriptionCoinsLink}
        subscriptionCoinsDisabled={!subscriptionCoinsActive}
        channelName={channelName}
      />
    )
  }
  let coinsLink = prePaidCoinsLink
  let coinsLinkEnabled = true
  if (subscriptionCoinsEnabled) {
    coinsLink = subscriptionCoinsLink
    coinsLinkEnabled = subscriptionCoinsActive
  }
  if (coinsLink?.active && coinsLink?.url) {
    if (!coinsLinkEnabled) {
      return (
        <Button type="disable" className="disabled-coins-button">
          {coinsButtonLabel}
        </Button>
      )
    }
    return (
      <Link url={coinsLink?.url} newTab={coinsLink?.newTab} channelName={channelName}>
        <Button kind="tertiary" className="reset-transform">
          {coinsButtonLabel}
        </Button>
      </Link>
    )
  }
  return <></>
}

const CoinsDisplay = (props: any) => {
  const {
    prePaidCoinsLink,
    subscriptionCoinsLink,
    prePaidCoinsData,
    subscriptionCoinsData,
    t,
    isError,
    isLoading,
    tryAgainAction,
  } = props
  const prePaidCoinsEnabled = prePaidCoinsLink?.active && prePaidCoinsLink?.url
  const subscriptionCoinsEnabled = subscriptionCoinsLink?.active && subscriptionCoinsLink?.url
  const prePaidCoins = prePaidCoinsData?.amount || 0
  const subscriptionCoins = subscriptionCoinsData?.amount || 0

  if (
    !prePaidCoinsEnabled &&
    !subscriptionCoinsEnabled &&
    prePaidCoins === 0 &&
    subscriptionCoins === 0 &&
    !subscriptionCoinsData
  ) {
    return <></>
  }
  const SubscriptionCoinsTooltip = () => {
    if (subscriptionCoinsData) {
      const resetDate = subscriptionCoinsData?.nextResetAt
        ? subscriptionCoinsData?.nextResetAt - 60
        : null
      return (
        <div>
          <StyledCoinItemHelper>
            <BatteryChargingFullIcon />
            <>{t('common.nextRecharge')}</>
            <span>{getDate(subscriptionCoinsData?.nextRechargeAt)}</span>
          </StyledCoinItemHelper>
          {subscriptionCoinsData?.nextResetAt && (
            <StyledCoinItemHelper>
              <WarningIconMUI />
              <>{t('common.validUntil')}</>
              <span>{getDate(resetDate)}</span>
            </StyledCoinItemHelper>
          )}
        </div>
      )
    } else {
      return (
        <StyledCoinItemHelper>
          <AirplanemodeInactiveIcon />
          <>{t('common.inactiveSubscription')}</>
        </StyledCoinItemHelper>
      )
    }
  }
  return (
    <>
      <img src={lineIcon} alt="lineIcon" style={{ marginLeft: 20 }} />
      <StyledNavItem>
        <img src={coinIcon} alt="coinIcon" />
      </StyledNavItem>
      <StyledNavItem>
        <StyledCoinsContainer>
          <StyledSpanItem>
            {!isError && <span>{prePaidCoins + subscriptionCoins}</span>}
            {isError && (
              <StyledWarningIcon>
                <WarningIcon height={28} width={28} />
              </StyledWarningIcon>
            )}
            {getBars(prePaidCoins + subscriptionCoins, stepsPremiumCoins, '#044F98', '#C5D0D9')}
            <StyledCoinsInformationDropdown>
              {isError && (
                <StyledCoinsError>
                  <h1>{t('coins.error.title')}</h1>
                  <h2>{t('coins.error.text.line1')}</h2>
                  <h2>{t('coins.error.text.line2')}</h2>
                  <StyledTryAgainButton
                    kind="secondary"
                    onClick={tryAgainAction}
                    loading={isLoading}
                    active={isLoading}
                  >
                    {isLoading ? '' : t('common.error.table.try-again.button')}
                  </StyledTryAgainButton>
                </StyledCoinsError>
              )}
              {!isError && (prePaidCoinsEnabled || prePaidCoins > 0) && (
                <StyledCoinItem>
                  {t('nav.coins.premium')} <span>{prePaidCoins}</span>
                </StyledCoinItem>
              )}
              {!isError &&
                (subscriptionCoinsEnabled || subscriptionCoins > 0 || subscriptionCoinsData) && (
                  <>
                    <StyledCoinItem>
                      {t('nav.coins.subscription')} <span>{subscriptionCoins}</span>
                    </StyledCoinItem>
                    <SubscriptionCoinsTooltip />
                  </>
                )}
            </StyledCoinsInformationDropdown>
          </StyledSpanItem>
        </StyledCoinsContainer>
      </StyledNavItem>
    </>
  )
}

const FreeValueDisplay = (props: any) => {
  const { prePaidCoinsData, subscriptionCoinsData, valueProduct, isError } = props
  const prePaidCoins = prePaidCoinsData?.amount || 0
  const subscriptionCoins = subscriptionCoinsData?.amount || 0
  const freeValueAmount = Math.floor((prePaidCoins + subscriptionCoins) / valueProduct.coinPrice)

  return (
    <>
      <img src={lineIcon} alt="lineIcon" style={{ marginLeft: 20 }} />
      <StyledNavItem>
        <img className="value-icon" src={SprengnetterAppsValue} alt="SprengnetterAppsValue" />
      </StyledNavItem>
      <StyledNavItem>
        <StyledSpanItem style={{ cursor: 'default' }}>
          {!isError && <span>{freeValueAmount}</span>}
          {isError && (
            <StyledWarningIcon>
              <WarningIcon height={28} width={28} />
            </StyledWarningIcon>
          )}
        </StyledSpanItem>
      </StyledNavItem>
    </>
  )
}

function Nav(): any {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const isLeadView = location.pathname === '/leads'
  const isBatchView = location.pathname === '/batches'
  const isListView = isLeadView || isBatchView
  const customerCoins = useSelector(selectCustomerCoins)
  const customerConfig = useSelector(selectCustomerConfiguration)
  const [showToastCoinsError, setShowToastCoinsError] = useState(false)
  const prePaidCoinsData = customerCoins.coins?.accounts?.find((item) => item.type === 'on-demand')
  const subscriptionCoinsData = customerCoins.coins?.accounts?.find(
    (item) => item.type === 'subscription'
  )
  const valueProduct = findInArray(customerConfig?.products, 'name', 'value')
  const isArrowActive =
    customerConfig &&
    (valueProduct?.active ||
      findInArray(customerConfig?.products, 'name', 'calc')?.active ||
      findInArray(customerConfig?.products, 'name', 'books')?.active ||
      findInArray(customerConfig?.products, 'name', 'five-2-click')?.active)
  const optionsLink = getProperUrl(customerConfig, 'einstellungen')
  const prePaidCoinsLink = getProperUrl(customerConfig, 'coins')
  const subscriptionCoinsLink = getProperUrl(customerConfig, 'coins-subscription')
  const isBatchProductActive =
    customerConfig?.products?.filter(
      (product) => product?.name?.toLowerCase() === 'avm_valuation' && product?.active
    ).length > 0

  const {
    isSuccess: isCoinsFetchSuccess,
    isError: isCoinsFetchError,
    isFetching: isCoinsFetching,
    refetch: refetchCoins,
  } = useGetCustomerCoinsQuery()

  useEffect(() => {
    if (isCoinsFetchSuccess && !isCoinsFetching) {
      setShowToastCoinsError(false)
    }
  }, [isCoinsFetchSuccess, isCoinsFetching])

  useToastEffect(t('coins.error.toast'), 'ERROR', [isCoinsFetchError, showToastCoinsError])

  return (
    <StyledNav>
      <StyledContainer>
        <StyledNavItem>
          {isListView ? (
            <>
              {isArrowActive && (
                <>
                  <StyledArrow>
                    <img
                      src={backIcon}
                      onClick={isListView ? () => history.push('/') : null}
                      style={{ cursor: isListView ? 'pointer' : 'inherit' }}
                      alt=""
                    />
                  </StyledArrow>
                  <img
                    src={lineIcon}
                    alt=""
                    style={{ height: 36, margin: '0 20px', width: 'inherit' }}
                  />
                </>
              )}
              <StyledTitle style={{ margin: 0 }}>
                {isLeadView ? t('nav.address') : t('nav.batch')}
              </StyledTitle>
            </>
          ) : (
            <StyledTitle>{t('nav.title')}</StyledTitle>
          )}
        </StyledNavItem>
        <StyledNavItemsContainer>
          {isBatchProductActive && (
            <StyledNavItem>
              <StyledNavLinkContainer isActive={isBatchView}>
                <RouterLink to="/batches">
                  <BatchListIcon />
                  {t('nav.batch')}
                </RouterLink>
              </StyledNavLinkContainer>
            </StyledNavItem>
          )}
          <StyledNavItem>
            <StyledNavLinkContainer isActive={isLeadView}>
              <RouterLink to="/leads">
                <AddressListIcon />
                {t('nav.address')}
              </RouterLink>
            </StyledNavLinkContainer>
          </StyledNavItem>
          <StyledNavItem>
            <NotificationsManager channelName={customerConfig?.name} />
          </StyledNavItem>
          {optionsLink?.active && (
            <StyledNavItem>
              <StyledNavLinkContainer>
                <Link
                  url={optionsLink?.url}
                  newTab={optionsLink?.newTab}
                  channelName={customerConfig?.name}
                >
                  <SettingsIcon />
                  {t('nav.options')}
                </Link>
              </StyledNavLinkContainer>
            </StyledNavItem>
          )}
          {customerConfig?.immopointsEnabled !== true ? (
            <CoinsDisplay
              prePaidCoinsLink={prePaidCoinsLink}
              subscriptionCoinsLink={subscriptionCoinsLink}
              prePaidCoinsData={prePaidCoinsData}
              subscriptionCoinsData={subscriptionCoinsData}
              isError={isCoinsFetchError || customerCoins.loadedWithError}
              isLoading={isCoinsFetching}
              tryAgainAction={() => {
                refetchCoins()
                setShowToastCoinsError(true)
              }}
              t={t}
            />
          ) : (
            <FreeValueDisplay
              prePaidCoinsData={prePaidCoinsData}
              subscriptionCoinsData={subscriptionCoinsData}
              valueProduct={valueProduct}
              isError={isCoinsFetchError || customerCoins.loadedWithError}
            />
          )}
        </StyledNavItemsContainer>
        {customerConfig?.immopointsEnabled !== true ? (
          <StyledNavItemsContainer>
            <StyledNavItem>
              <BuyCoinsButton
                prePaidCoinsLink={prePaidCoinsLink}
                subscriptionCoinsLink={subscriptionCoinsLink}
                subscriptionCoinsData={subscriptionCoinsData}
                channelName={customerConfig?.name}
                coinsButtonLabel={t('common.coinsButton')}
                isCoinsError={isCoinsFetchError || customerCoins.loadedWithError}
              />
            </StyledNavItem>
          </StyledNavItemsContainer>
        ) : null}
      </StyledContainer>
    </StyledNav>
  )
}

export default Nav
