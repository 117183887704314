import React, { useEffect, useState } from 'react'
import dayjs from 'common/utils/defaultDayjs'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import {
  StyledBackdropButton,
  StyledBatchContainer,
  StyledButtonContainer,
  StyledDate,
  StyledName,
  StyledQuestionBeforeDelete,
} from './DeleteBatchModal.styled'
import TrashIcon from 'assets/images/icons/trash.svg'
import { useDeleteBatchMutation } from 'services/batch-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 417,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

function DeleteBatchModal({ isOpened, batch, onBackdropClick, onBatchDelete }: any): any {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()

  const [deleteBatch, { isError, isSuccess, isLoading }] = useDeleteBatchMutation()

  const removeBatch = () => {
    deleteBatch({ uuid: batch.uuid })
  }

  useEffect(() => {
    if (isSuccess) {
      onBatchDelete()
    }
    if (isError || isSuccess) {
      onBackdropClick()
    }
  }, [isError, isSuccess, onBatchDelete, onBackdropClick])

  useToastEffect(t('modal.deleteBatchModal.toast.error'), 'ERROR', [isError])
  useToastEffect(t('modal.deleteBatchModal.toast.success'), 'INFO', [isSuccess])

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('modal.deleteBatchModal.header')} onBackdropClick={onBackdropClick} />
        <StyledQuestionBeforeDelete>{t('modal.deleteBatchModal.text')}</StyledQuestionBeforeDelete>
        <StyledBatchContainer>
          <StyledName>
            <img src={TrashIcon} alt="trash" />
            {batch?.name}
          </StyledName>
          <StyledDate>{dayjs.unix(batch?.creationTime).format()}</StyledDate>
        </StyledBatchContainer>
        <StyledButtonContainer>
          <StyledBackdropButton>
            <Button onClick={onBackdropClick}> {t('modal.deleteBatchModal.button.back')} </Button>
          </StyledBackdropButton>
          <Button
            className="second-button"
            onClick={removeBatch}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('modal.deleteBatchModal.button.confirm')}
          </Button>
        </StyledButtonContainer>
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onBackdropClick={onBackdropClick}
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default DeleteBatchModal
