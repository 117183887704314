import styled from 'styled-components'

export const StyledContainer = styled.div`
  font-size: 14px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: 400;

  svg {
    margin-right: 5px;
    font-size: 20px;
    color: #92969a;
  }

  p {
    margin: 0;
  }

  span {
    font-weight: 600;
  }
`
