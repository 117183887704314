import styled from 'styled-components'

export const StyledWarningIcon = styled.div`
  path {
    stroke: #f18500;
  }
  circle {
    stroke: #f18500;
  }
  line {
    stroke: #f18500;
  }
`
