import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import { default as MaterialTabs } from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AddAddress from 'components/Modal/NewAddressModal/AddAddress/AddAddress'
import AddObjectData from 'components/Modal/NewAddressModal/AddObjectData/AddObjectData'
import ModalSwitchButtons from '../ModalSwitchButtons/ModalSwitchButtons'
import AddEquipment from '../AddEquipment/AddEquipment'
import { StyledTabsWrapper } from './NewAddressTabs.styled'
import SVG from 'react-inlinesvg'
import checkmarkImage from 'assets/images/icons/checkmark.svg'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectAddressFormData } from 'slices/addressFormSlice'
import ProductCheckout from '../ProductCheckout/ProductCheckout'

function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export interface NewAddressTabsProps {
  onBackdropClick: () => void
}

const NewAddressTabs = (props: NewAddressTabsProps) => {
  const { onBackdropClick } = props
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const [value, setValue] = useState(addressFormData.isEdit ? 1 : 0)
  const [isProductGenerated, setProductGenerated] = useState(false)
  const [nextButton, setNextButton] = useState(undefined)
  const isIndexEdit = addressFormData.productName === 'index'

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <StyledTabsWrapper>
      <AppBar position="relative">
        <MaterialTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          scrollButtons="off"
        >
          <Tab
            label={t('newAddressModal.addressTab.label')}
            disabled
            {...a11yProps(0)}
            icon={value > 0 && <SVG src={checkmarkImage} />}
          />
          <Tab
            label={t('newAddressModal.objectDataTab.label')}
            {...a11yProps(1)}
            disabled
            icon={value > 1 && <SVG src={checkmarkImage} />}
          />
          {!isIndexEdit && (
            <Tab
              label={t('newAddressModal.furnishingTab.label')}
              {...a11yProps(2)}
              disabled
              icon={value > 2 && <SVG src={checkmarkImage} />}
            />
          )}
          <Tab
            label={t('newAddressModal.finishTab.label')}
            {...a11yProps(!isIndexEdit ? 3 : 2)}
            disabled
            icon={
              value >= (!isIndexEdit ? 3 : 2) && isProductGenerated && <SVG src={checkmarkImage} />
            }
          />
        </MaterialTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AddAddress />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddObjectData nextButton={nextButton} productName={addressFormData.productName} />
      </TabPanel>
      {!isIndexEdit && (
        <TabPanel value={value} index={2}>
          <AddEquipment />
        </TabPanel>
      )}
      <TabPanel value={value} index={!isIndexEdit ? 3 : 2}>
        <ProductCheckout
          isReportGenerated={isProductGenerated}
          setReportGenerated={setProductGenerated}
          onBackdropClick={onBackdropClick}
        />
      </TabPanel>
      <ModalSwitchButtons value={value} setValue={setValue} handleSetNextButton={setNextButton} />
    </StyledTabsWrapper>
  )
}

export default NewAddressTabs
