import { checkValidationError } from 'common/utils/CheckValidationError'
import { requiredFields } from 'common/utils/RequiredFields'

export const checkReqField = (currentNewLeadData, setValidationError, validationError) => {
  if (currentNewLeadData.category === 'ETW') {
    requiredFields['page2ETW'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (currentNewLeadData[item]) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
  if (currentNewLeadData.category === 'EFH') {
    requiredFields['page2EFH'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (currentNewLeadData[item]) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
  if (currentNewLeadData.category === 'MFH') {
    requiredFields['page2MFH'].forEach((item) => {
      if (checkValidationError(validationError, item)) {
        setValidationError((prevState) => ({ ...prevState, [item]: true }))
      }
      if (currentNewLeadData[item]) {
        setValidationError((prevState) => ({ ...prevState, [item]: false }))
      }
    })
  }
}
