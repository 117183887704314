import { handleButtonBackground } from 'components/Button/Button.style'
import styled from 'styled-components'

export const StyledCircle = styled.div<any>`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledButtonContainer = styled.div<any>`
  max-width: 600px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;

  .disabled-subscription-coins-button {
    background-color: ${handleButtonBackground('orange')};
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    :hover {
      cursor: default;
      background-color: ${handleButtonBackground('tertiary')};
    }
  }

  .no-coins-button {
    background-color: #d4e5f7;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    :hover {
      cursor: default;
    }
  }
`

export const StyledContainer = styled.div<any>`
  width: 100%;
  min-height: 500px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 28px;
    color: #324245;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    display: flex;
  }
  p {
    margin-top: 15px;
    font-size: 14px;
    color: #495057;
  }

  .no-coins-info {
    font-size: 14px;
    color: #495057;
  }

  img {
    width: 68px;
    height: 68px;
  }

  svg#check-mark {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .MuiButton-containedPrimary {
    margin-top: 20px;
  }
`
