import dayjs from 'dayjs'

dayjs.extend((option, dayjsClass, dayjsFactory) => {
  const oldFormat = dayjsClass.prototype.format
  dayjsClass.prototype.format = function (formatString) {
    return oldFormat.bind(this)(formatString ?? 'YYYY-MM-DD')
  }
})

export default dayjs
