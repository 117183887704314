import React, { useEffect } from 'react'
import phone from 'assets/images/icons/phone.svg'
import mail from 'assets/images/icons/mail.svg'
import file from 'assets/images/icons/file.svg'
import link from 'assets/images/icons/link.svg'
import {
  StyledLabel,
  StyledPersonalButtonsContainer,
  StyledPersonalButtonsLabel,
  StyledPersonalDataContainer,
  StyledPersonalLongCol,
  StyledPersonalRow,
  StyledPersonalShortCol,
  StyledValue,
} from './LeadPersonalData.styled'
import Button from 'components/Button/Button'
import CSVLeadFormatter from 'common/utils/CSVLeadFormatter'
import { useTranslation } from 'react-i18next'
import { generateLeadCSV } from 'common/utils/CSVGenerator'
import { useLazyGetLeadDocumentLinkQuery } from 'services/address-api'
import { LeadType } from 'types/LeadType'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

const LeadPersonalData = ({ data }: any) => {
  const { t } = useTranslation()
  const isModernizerLead = data.type === 'MODERNIZER'
  const anonimizedFlag = 'ANONYMIZED'

  const [getLeadDocumentLink, { data: leadLink, isSuccess, isError, isFetching }] =
    useLazyGetLeadDocumentLinkQuery()

  useEffect(() => {
    if (isSuccess && leadLink && !isFetching) {
      window.open(leadLink, '_blank')
    }
  }, [leadLink, isSuccess, isFetching])

  useToastEffect(t('leadModal.document.error.toast'), 'ERROR', [isError])

  const handleButtonClick = (isDetailedReport?: boolean) => {
    const type = isDetailedReport && isModernizerLead ? LeadType.MODERNIZER : LeadType.LEAD
    getLeadDocumentLink({ id: data.id, type: type })
  }

  const handleCSVButtonClick = () => {
    const { id, ...restLeadData } = data
    const lead = CSVLeadFormatter(restLeadData)
    generateLeadCSV(lead, true)
  }

  const getPersonalData = (value?: string) => {
    if (value === anonimizedFlag) {
      return t('leadModal.anonymizedData')
    }
    return value
  }

  return (
    <StyledPersonalDataContainer>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.firstName')}</StyledLabel>
          <StyledValue>{getPersonalData(data?.name)}</StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.lastName')}</StyledLabel>
          <StyledValue>{getPersonalData(data?.surname)}</StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.phone')}</StyledLabel>
          <StyledValue>
            <img src={phone} alt="phone" />
            {getPersonalData(data?.phone)}
          </StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.email')}</StyledLabel>
          <StyledValue>
            <img src={mail} alt="mail" />
            {getPersonalData(data?.email)}
          </StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
      <StyledPersonalRow>
        <StyledPersonalShortCol>
          <StyledLabel>{t('leadModal.origin')}</StyledLabel>
          <StyledValue>
            <img src={link} alt="link" />
            {data?.origin || '-'}
          </StyledValue>
        </StyledPersonalShortCol>
        <StyledPersonalLongCol>
          <StyledLabel>{t('leadModal.document')}</StyledLabel>
          <StyledValue withButtons>
            {!isModernizerLead && (
              <StyledPersonalButtonsLabel>
                <img src={file} alt="file" />
                {t('leadModal.propertyDocuments')}
              </StyledPersonalButtonsLabel>
            )}
            <StyledPersonalButtonsContainer>
              {!isModernizerLead && (
                <>
                  <Button onClick={() => handleButtonClick()}>{t('common.showButton')}</Button>
                  <Button onClick={handleCSVButtonClick}>{t('leadModal.csvButton')}</Button>
                </>
              )}
              {isModernizerLead && (
                <>
                  <Button onClick={() => handleButtonClick()}>{t('common.customerReport')}</Button>
                  <Button onClick={() => handleButtonClick(true)}>
                    {t('common.detailedReport')}
                  </Button>
                </>
              )}
            </StyledPersonalButtonsContainer>
          </StyledValue>
        </StyledPersonalLongCol>
      </StyledPersonalRow>
    </StyledPersonalDataContainer>
  )
}

export default LeadPersonalData
