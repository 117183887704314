import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Helmet } from 'react-helmet'
import App from 'pages/app/App'
import Contact from 'components/Contact/Contact'
import reportWebVitals from './reportWebVitals'
import { GlobalStyles, StyledAppWrapper } from 'pages/app/App.styles'
import './common/utils/i18n/i18n'
import { store } from 'store/store'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SessionEnd from 'pages/session-end/SessionEnd'
import Maintenance from 'pages/maintenance/Maintenance'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Provider store={store}>
        <Helmet>
          <title>
            {window.location.href.startsWith(process.env.REACT_APP_PROD_LINK)
              ? 'App production mode'
              : 'App develop mode'}
          </title>
        </Helmet>
        <CssBaseline />
        <GlobalStyles />
        <StyledAppWrapper>
          {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
            <Maintenance />
          ) : (
            <Router>
              <Switch>
                <Route path="/session-ended">
                  <SessionEnd />
                </Route>
                <Route path="/*">
                  <App />
                  <Contact />
                </Route>
              </Switch>
            </Router>
          )}
        </StyledAppWrapper>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
