import React from 'react'
import { useSelector } from 'react-redux'
import Button from 'components/Button/Button'
import closeIcon from 'assets/images/icons/close-popup.svg'
import { StyledModalContainer, StyledHeader } from './ModalTop.styles'
import { selectAddressFormData } from 'slices/addressFormSlice'

const getModalTopButton = (userPreference: string) => {
  switch (userPreference) {
    case 'report':
      return { label: 'Report', kind: 'gray' }
    case 'map':
      return { label: 'Maps', kind: 'orange' }
    case 'index':
      return { label: 'Index', kind: 'primary' }
    default:
      return null
  }
}

function ModalTop({ label, onBackdropClick }: any) {
  const addressFormData = useSelector(selectAddressFormData)
  const topButton = getModalTopButton(addressFormData.productName)

  return (
    <StyledModalContainer>
      <StyledHeader>
        <h1>{label}</h1>
        {topButton && <Button kind={topButton.kind}>{topButton.label}</Button>}
      </StyledHeader>
      <Button kind="image" onClick={() => onBackdropClick()}>
        <img src={closeIcon} alt="" />
      </Button>
    </StyledModalContainer>
  )
}

export default ModalTop
