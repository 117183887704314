import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { BatchEntityType, BatchListApiResponseType } from 'types/ApiResponse'
import { ISort } from 'types/ISort'

const BATCH = '/batches'

export const batchApi = createApi({
  reducerPath: 'batchApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getBatchList: builder.query<
      BatchListApiResponseType,
      { page: number; sort: ISort; size?: number; search: string }
    >({
      query: ({ page, sort, size = 4, search }) => {
        const searchParam = search ? `&search=${search}` : ''
        const sortParam = sort.properties
          .map((property) => `sort=${property},${sort.direction}`)
          .join('&')
        return `${BATCH}?page=${page - 1}&size=${size}${searchParam}&${sortParam}`
      },
    }),
    deleteBatch: builder.mutation<void, { uuid: string }>({
      query: (args) => ({
        url: `${BATCH}/${args.uuid}`,
        method: 'DELETE',
      }),
    }),
    createBatchDraft: builder.mutation<BatchEntityType, any>({
      query: (body) => ({
        url: `${BATCH}`,
        method: 'POST',
        body,
      }),
    }),
    processBatchDraft: builder.mutation<void, { uuid: string }>({
      query: (args) => ({
        url: `${BATCH}/${args.uuid}/process`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetBatchListQuery,
  useDeleteBatchMutation,
  useCreateBatchDraftMutation,
  useProcessBatchDraftMutation,
} = batchApi
