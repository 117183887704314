import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Nav from 'components/Nav/Nav'
import AddressTable from 'components/AddressTable/AddressTable'
import {
  StyledCenteredContainer,
  StyledMainLayout,
  StyledMainLayoutWrapper,
  StyledWarningIcon,
} from './App.styles'
import HomeScreen from 'components/HomeScreen/HomeScreen'
import NotificationPopup from 'components/Notifications/NotificationPopup/NotificationPopup'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'
import { useTranslation } from 'react-i18next'
import BatchTable from 'components/BatchTable/BatchTable'
import { Toast } from 'components/Toast/Toast'
import { useAppDispatch } from 'store/hooks'
import { selectUnreadNotifications } from 'slices/notificationsSlice'
import { useLazyGetUnreadNotificationsQuery } from 'services/notifications-api'
import { useGetCustomerConfigurationQuery } from 'services/configuration-api'
import { setCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useGetProductsCostQuery } from 'services/coins-api'

function App() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const unreadNotifications = useSelector(selectUnreadNotifications)
  const popUpNotification = unreadNotifications?.find((item) => item.popUp)
  const isInfoPopUp =
    popUpNotification?.type === 'MAINTENANCE' || popUpNotification?.type === 'INFO'

  const [getUnreadNotifications] = useLazyGetUnreadNotificationsQuery()

  const {
    data: customerConfigData,
    isLoading: isCustomerConfigLoading,
    isSuccess: isCustomerConfigFetchSuccess,
    isError: isCustomerConfigFetchError,
  } = useGetCustomerConfigurationQuery()

  const { isLoading: isProductsCostLoading, isError: isProductsCostFetchError } =
    useGetProductsCostQuery(['maps', 'report', 'indices', 'avm_valuation'])

  useEffect(() => {
    if (isCustomerConfigFetchSuccess) {
      dispatch(setCustomerConfiguration(customerConfigData))
      getUnreadNotifications()
    }
  }, [dispatch, getUnreadNotifications, isCustomerConfigFetchSuccess, customerConfigData])

  if (isCustomerConfigLoading || isProductsCostLoading) {
    return (
      <StyledCenteredContainer>
        <CircularProgress size={80} />
      </StyledCenteredContainer>
    )
  }

  if (isCustomerConfigFetchError || isProductsCostFetchError) {
    return (
      <StyledCenteredContainer>
        <StyledWarningIcon>
          <WarningIcon height={40} width={40} />
        </StyledWarningIcon>
        <h1>{t('common.error.onApplicationLoad.line.1')}</h1>
        <h2>{t('common.error.onApplicationLoad.line.2')}</h2>
      </StyledCenteredContainer>
    )
  }

  return (
    <Router>
      {popUpNotification && <NotificationPopup {...popUpNotification} isInfoPopUp={isInfoPopUp} />}
      <StyledMainLayoutWrapper>
        <Nav />
        <StyledMainLayout>
          <Switch>
            <Route path="/leads">
              <AddressTable />
            </Route>
            <Route path="/batches">
              <BatchTable />
            </Route>
            <Route path="/">
              <HomeScreen />
            </Route>
          </Switch>
          <Toast />
        </StyledMainLayout>
      </StyledMainLayoutWrapper>
    </Router>
  )
}

export default App
