import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import trash from 'assets/images/icons/trash.svg'
import ModalTop from '../ModalTop/ModalTop'
import {
  StyledAddressContainer,
  StyledBackdropButton,
  StyledButtonContainer,
  StyledQuestionBeforeDelete,
  StyledStreet,
  StyledZipCity,
} from './DeleteAddressModal.styled'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { useDeleteAddressMutation } from 'services/address-api'
import { BasicAddressEntityType } from 'types/ApiResponse'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 417,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

export interface DeleteAddressModalProps {
  isOpened: boolean
  address: BasicAddressEntityType | null
  onBackdropClick: () => void
  onDelete: () => void
}

const DeleteAddressModal = (props: DeleteAddressModalProps) => {
  const { isOpened, address, onBackdropClick, onDelete } = props
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()

  const [deleteAddress, { isError, isSuccess, isLoading }] = useDeleteAddressMutation()

  const removeAddress = () => {
    deleteAddress({ id: address.id })
  }

  useEffect(() => {
    if (isSuccess) {
      onDelete()
    }
    if (isError || isSuccess) {
      onBackdropClick()
    }
  }, [isError, isSuccess, onBackdropClick, onDelete])

  useToastEffect(t('modal.deleteAddressModal.toast.error'), 'ERROR', [isError])
  useToastEffect(t('modal.deleteAddressModal.toast.success'), 'INFO', [isSuccess])

  if (address === null) {
    return <></>
  }

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('modal.deleteLeadsModal.header')} onBackdropClick={onBackdropClick} />
        <StyledQuestionBeforeDelete>{t('modal.deleteLeadsModal.text')}</StyledQuestionBeforeDelete>
        <StyledAddressContainer>
          <StyledStreet>
            <img src={trash} alt="trash" />
            {address.street} {address.houseNumber}
          </StyledStreet>
          <StyledZipCity>
            {address.zip} {address.town}, {address.country}
            <div>{address.category}</div>
          </StyledZipCity>
        </StyledAddressContainer>
        <StyledButtonContainer>
          <StyledBackdropButton>
            <Button onClick={onBackdropClick}> {t('modal.copyLeadsModal.button.back')} </Button>
          </StyledBackdropButton>
          <Button
            className="second-button"
            onClick={removeAddress}
            loading={isLoading}
            disabled={isLoading}
          >
            {isLoading ? t('common.loadingButton') : t('modal.deleteLeadsModal.button.confirm')}
          </Button>
        </StyledButtonContainer>
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onBackdropClick={onBackdropClick}
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default DeleteAddressModal
