import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import {
  StyledAddressContainer,
  StyledBackdropButton,
  StyledButtonContainer,
  StyledQuestionBeforeCopy,
  StyledStreet,
  StyledZipCity,
} from './CopyAddressModal.styled'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import { FileCopy } from '@material-ui/icons'
import { useCreateAddressMutation, useLazyGetAddressQuery } from 'services/address-api'
import { BasicAddressEntityType } from 'types/ApiResponse'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { ToastStatusType, setToast } from 'slices/toastSlice'
import { useAppDispatch } from 'store/hooks'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 417,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

export interface CopyAddressModalProps {
  isOpened: boolean
  address: BasicAddressEntityType | null
  onBackdropClick: () => void
  onCopy: () => void
}

const CopyAddressModal = (props: CopyAddressModalProps) => {
  const { isOpened, address, onBackdropClick, onCopy } = props
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const [getAddress] = useLazyGetAddressQuery()
  const [createAddress, { isError: isCreateAddressError, isSuccess: isCreateAddressSuccess }] =
    useCreateAddressMutation()

  const duplicateAddress = async () => {
    setIsLoading(true)
    const addressToCopy = await getAddress({ id: address.id })
    if (addressToCopy.status === QueryStatus.fulfilled) {
      createAddress({ ...addressToCopy.data, isCopy: true })
    } else {
      dispatch(
        setToast({
          type: ToastStatusType.ERROR,
          message: t('modal.copyLeadsModal.toast.error'),
        })
      )
      setIsLoading(false)
      onBackdropClick()
    }
  }

  useEffect(() => {
    if (isCreateAddressSuccess) {
      onCopy()
    }
    if (isCreateAddressError || isCreateAddressSuccess) {
      setIsLoading(false)
      onBackdropClick()
    }
  }, [isCreateAddressError, isCreateAddressSuccess, onBackdropClick, onCopy])

  useToastEffect(t('modal.copyLeadsModal.toast.error'), 'ERROR', [isCreateAddressError])
  useToastEffect(t('modal.copyLeadsModal.toast.success'), 'INFO', [isCreateAddressSuccess])

  if (address === null) {
    return <></>
  }

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('modal.copyLeadsModal.header')} onBackdropClick={onBackdropClick} />
        <StyledQuestionBeforeCopy>{t('modal.copyLeadsModal.text')}</StyledQuestionBeforeCopy>
        <StyledAddressContainer>
          <StyledStreet>
            <FileCopy />
            {address.street} {address.houseNumber}
          </StyledStreet>
          <StyledZipCity>
            {address.zip} {address.town}, {address.country}
            <div>{address.category}</div>
          </StyledZipCity>
        </StyledAddressContainer>
        <StyledButtonContainer>
          <StyledBackdropButton>
            <Button onClick={onBackdropClick}> {t('modal.copyLeadsModal.button.back')} </Button>
          </StyledBackdropButton>
          <Button
            className="second-button"
            onClick={duplicateAddress}
            loading={isLoading}
            disabled={isLoading}
          >
            {isLoading ? t('common.loadingButton') : t('modal.copyLeadsModal.button.confirm')}
          </Button>
        </StyledButtonContainer>
      </div>
    </>
  )
  return (
    <MaterialModal
      open={isOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onBackdropClick={onBackdropClick}
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default CopyAddressModal
