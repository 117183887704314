import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        // margin: theme.spacing(1),
        width: 'auto',
        maxWidth: '100%',
      },
    },
  })
)

function Input({ value, defaultValue, nextButton, ...props }: any): any {
  const classes = useStyles()
  const [inputText, setInputText] = useState(defaultValue) // eslint-disable-line

  const handleKeyDown = (event) => {
    if (!nextButton) {
      return
    }
    if (event.keyCode === 13) {
      nextButton.current.click()
    }
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(event) => event.preventDefault()}
    >
      <TextField
        value={value}
        defaultValue={inputText}
        variant="outlined"
        onKeyDown={handleKeyDown}
        onChange={(e) => setInputText(e.target.value)}
        {...props}
      />
    </form>
  )
}

export default Input
