import { createSlice } from '@reduxjs/toolkit'
import { coinsApi } from 'services/coins-api'
import { RootState } from 'store/store'
import { CustomerCoinsEntityType } from 'types/ApiResponse'

export interface CustomerCoins {
  coins: CustomerCoinsEntityType | null
  loadedWithError: boolean
}

export interface CoinsState {
  productCosts: Record<string, number> | null
  customerCoins: CustomerCoins
}

const initialState: CoinsState = {
  productCosts: null,
  customerCoins: {
    coins: null,
    loadedWithError: false,
  },
}

const coinsSlice = createSlice({
  name: 'coinsSlice',
  initialState,
  reducers: {
    setProductCost: (state, action) => {
      state.productCosts = {
        ...state.productCosts,
        ...action.payload,
      }
    },
    setProductsCost: (state, action) => {
      state.productCosts = {
        ...state.productCosts,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(coinsApi.endpoints.getProductsCost.matchFulfilled, (state, action) => {
        state.productCosts = {
          ...state.productCosts,
          ...action.payload,
        }
      })
      .addMatcher(coinsApi.endpoints.getCustomerCoins.matchFulfilled, (state, action) => {
        state.customerCoins.coins = action.payload
        state.customerCoins.loadedWithError = false
      })
      .addMatcher(coinsApi.endpoints.getCustomerCoins.matchRejected, (state, _) => {
        state.customerCoins.coins = null
        state.customerCoins.loadedWithError = true
      })
  },
})

export const { setProductCost, setProductsCost } = coinsSlice.actions

export const selectProductsCost = (state: RootState) => state.coins.productCosts
export const selectProductCost = (productName: string) => (state: RootState) =>
  state.coins.productCosts[productName]
export const selectCustomerCoins = (state: RootState) => state.coins.customerCoins

export default coinsSlice.reducer
