import React from 'react'
import SprengnetterAppsLead from 'assets/images/icons/SprengnetterAppsLead.png'
import SprengnetterAppsModernizerLead from 'assets/images/icons/SprengnetterAppsModernizerLead.png'

import {
  StyledAddress,
  StyledAddressRow,
  StyledIcon,
  StyledLeadAddresContainer,
} from './Address.styled'

const Address = ({ data }: any) => {
  const isModernizerLead = data.type === 'MODERNIZER'

  return (
    <StyledLeadAddresContainer isModernizerLead={isModernizerLead}>
      <StyledIcon>
        <img
          src={isModernizerLead ? SprengnetterAppsModernizerLead : SprengnetterAppsLead}
          alt="SprengnetterAppsLead"
        />
      </StyledIcon>
      <StyledAddress>
        <StyledAddressRow>
          {data.street} {data.houseNumber}
        </StyledAddressRow>
        <StyledAddressRow>
          {data.zip} {data.town}, {data.nation} <p>{data.category}</p>
        </StyledAddressRow>
        <StyledAddressRow>
          {data.lat?.toFixed(6)}, {data.lng?.toFixed(6)}
        </StyledAddressRow>
      </StyledAddress>
    </StyledLeadAddresContainer>
  )
}

export default Address
