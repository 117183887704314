import DateCell from 'components/DateCell/DateCell'
import Sort from 'components/Sort/Sort'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from 'common/utils/defaultDayjs'
import { useTranslation } from 'react-i18next'
import { ISort, SortDirection } from 'types/ISort'
import {
  StyledBillingContainer,
  StyledCheckmarkIcon,
  StyledCoinPrice,
  StyledDownloadButton,
  StyledDraftIcon,
  StyledLink,
  StyledMenuListItem,
  StyledNameContainer,
  StyledPagination,
  StyledStatusContainer,
  TableStyles,
} from './BatchTable.style'
import BatchTableComponent from './BatchTableComponent'
import { StyledHeader, StyledLabel, StyledMoreButton } from 'pages/app/App.styles'
import Button from 'components/Button/Button'
import PlusWhiteIcon from 'assets/images/icons/pluswhite.svg'
import ContinueIcon from 'assets/images/icons/continue.svg'
import useOutsideClickHandler from 'hooks/useOutsideClickHandler'
import { MoreHoriz } from '@material-ui/icons'
import DeleteBatchModal from 'components/Modal/DeleteBatchModal/DeleteBatchModal'
import { BatchStatus } from 'types/BatchStatus'
import { StyledTag } from 'components/Tag/Tag.style'
import BatchInfoModal from 'components/Modal/BatchInfoModal/BatchInfoModal'
import BatchStatusChip from 'components/BatchStatusChip/BatchStatusChip'
import BatchFormModal from 'components/Modal/BatchFormModal/BatchFormModal'
import TableError from 'components/TableError/TableError'
import { useGetBatchListQuery } from 'services/batch-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { BatchEntityType } from 'types/ApiResponse'
import { useAppDispatch } from 'store/hooks'
import { setCurrentBatchDraft } from 'slices/currentBatchDraftSlice'
import http from 'common/services/HttpService'
import { ToastStatusType, setToast } from 'slices/toastSlice'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useSelector } from 'react-redux'
import { selectProductsCost } from 'slices/coinsSlice'
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/radio-checked.svg'
import coinIcon from 'assets/images/icons/coin.svg'
import { findInArray, isProductBilled } from 'common/utils/generic'

const BatchTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const customerConfig = useSelector(selectCustomerConfiguration)
  const servicePrices = useSelector(selectProductsCost)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentSort, setCurrentSort] = useState<ISort>({
    properties: ['creationTime'],
    direction: SortDirection.DESC,
  })
  const [search, setSearch] = useState('')

  const {
    data,
    isSuccess: isBatchListSuccess,
    isFetching: isBatchListFetching,
    isError: isBatchListError,
    refetch,
  } = useGetBatchListQuery({
    page: currentPage,
    sort: currentSort,
    search: search,
  })

  const [showTableError, setShowTableError] = useState(false)
  const [showToastError, setShowToastError] = useState(false)

  const [openDeleteBatch, setOpenDeleteBatch] = useState(false)
  const [openBatchInfo, setOpenBatchInfo] = useState(false)
  const [openBatchForm, setOpenBatchForm] = useState(false)
  const [activeBatch, setActiveBatch] = useState<BatchEntityType>(null)
  const [isActionLoading, setActionLoading] = useState({})

  const batchProduct = findInArray(customerConfig?.products, 'name', 'avm_valuation')
  const isBatchBilled = isProductBilled(batchProduct, customerConfig)

  const batchesData = useMemo(() => {
    return data?.content || []
  }, [data])

  useEffect(() => {
    if (isBatchListError) {
      setShowTableError(true)
    }
    if (isBatchListSuccess && !isBatchListFetching) {
      setShowTableError(false)
      setShowToastError(false)
    }
  }, [isBatchListError, isBatchListSuccess, isBatchListFetching])

  const handleSortChange = (sort: ISort) => {
    setCurrentPage(1)
    setCurrentSort(sort)
  }

  const handleSearchChange = (searchPhrase: string) => {
    setCurrentPage(1)
    setSearch(searchPhrase)
  }

  const handleOpenDeleteBatchModal = (batch: BatchEntityType) => {
    setActiveBatch(batch)
    setOpenDeleteBatch(true)
  }

  const onBackdropDeleteBatchClick = useCallback(() => {
    setOpenDeleteBatch(false)
    setActiveBatch(null)
  }, [])

  const handleOpenBatchInfoModal = (batch: BatchEntityType) => {
    setActiveBatch(batch)
    setOpenBatchInfo(true)
  }

  const onBackdropBatchInfoClick = () => {
    setOpenBatchInfo(false)
    setActiveBatch(null)
  }

  const handleDownloadClickButton = (batch: BatchEntityType) => {
    setActionLoading({ ...isActionLoading, [batch.uuid]: true })
    const fileName = `${batch.name}_${dayjs.unix(batch.creationTime).format()}.csv`
    const anchor = document.createElement('a')
    anchor.setAttribute('download', fileName)
    document.body.appendChild(anchor)

    http
      .get(`/batches/${batch.uuid}/document`, {
        responseType: 'blob',
      })
      .then((response) => {
        const objectUrl = window.URL.createObjectURL(new Blob([response.data]))
        anchor.href = objectUrl
        anchor.click()
        window.URL.revokeObjectURL(objectUrl)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.href = '/session-ended'
        } else {
          dispatch(
            setToast({
              type: ToastStatusType.ERROR,
              message: t('batchList.download.error.toast'),
            })
          )
        }
      })
      .finally(() => {
        document.body.removeChild(anchor)
        setActionLoading({ ...isActionLoading, [batch.uuid]: false })
      })
  }

  const handleOpenBatchFormModal = () => {
    setOpenBatchForm(true)
  }

  const handleOpenDraftModal = (batch: BatchEntityType) => {
    dispatch(setCurrentBatchDraft(batch))
    setOpenBatchForm(true)
  }

  const onBackdropBatchFormClick = () => {
    setOpenBatchForm(false)
    refetch()
    dispatch(setCurrentBatchDraft(null))
  }

  useEffect(() => {
    let intervalId
    const hasBatchProcessing =
      batchesData.filter((batch) => batch.status === BatchStatus.PROCESSING)?.length > 0
    if (hasBatchProcessing && !openBatchForm) {
      intervalId = setInterval(() => {
        refetch()
      }, 10000)
    }

    return () => clearInterval(intervalId)
  }, [batchesData, openBatchForm, refetch])

  useToastEffect(t('batchList.error.toast'), 'ERROR', [isBatchListError, showToastError])

  const columns = [
    {
      Header: () => {
        return (
          <>
            <span>{t('batchList.headers.date')}</span>
            <Sort
              properties={['creationTime']}
              tableSort={currentSort}
              handleSortChange={handleSortChange}
            />
          </>
        )
      },
      accessor: 'creationTime',
      columns: [
        {
          accessor: 'creationTime',
          Cell: (props: any) => {
            return <DateCell date={props.cell.row.original.creationTime} />
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <>
            <span>{t('batchList.headers.name')}</span>
            <Sort
              properties={['name']}
              tableSort={currentSort}
              handleSortChange={handleSortChange}
            />
          </>
        )
      },
      accessor: 'name',
      columns: [
        {
          accessor: 'name',
          Cell: (props: any) => {
            return (
              <StyledLink onClick={() => handleOpenBatchInfoModal(props.cell.row.original)}>
                <StyledNameContainer>
                  <span>{props.cell.row.original.name}</span>
                  <StyledTag>
                    {t('batchList.columns.name.batchSize') +
                      ' ' +
                      props.cell.row.original.batchSize}
                  </StyledTag>
                </StyledNameContainer>
              </StyledLink>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return <span>{t('batchList.headers.status')}</span>
      },
      accessor: 'status',
      columns: [
        {
          accessor: 'status',
          Cell: (props: any) => {
            const isDraft = props.cell.row.original.status === BatchStatus.DRAFT
            return (
              <StyledStatusContainer>
                <BatchStatusChip status={props.cell.row.original.status} />
                {isDraft && (
                  <StyledDraftIcon
                    onClick={() => handleOpenDraftModal(props.cell.row.original)}
                    src={ContinueIcon}
                    alt="continueIcon"
                  />
                )}
              </StyledStatusContainer>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return <span></span>
      },
      accessor: 'download',
      columns: [
        {
          accessor: 'download',
          Cell: (props: any) => {
            const handleButtonClick = () => {
              handleDownloadClickButton(props.cell.row.original)
            }
            const isLoading = isActionLoading[props.cell.row.original.uuid]
            const isDisabled =
              props.cell.row.original.status === BatchStatus.ERROR ||
              props.cell.row.original.status === BatchStatus.PROCESSING
            const isShown =
              props.cell.row.original.status === BatchStatus.PROCESSING ||
              props.cell.row.original.status === BatchStatus.COMPLETED
            return (
              isShown && (
                <StyledDownloadButton
                  kind="secondary"
                  onClick={handleButtonClick}
                  loading={isLoading}
                  disabled={isDisabled}
                  active={isLoading}
                >
                  {isLoading ? '' : t('batchList.actionButton.download')}
                </StyledDownloadButton>
              )
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return <span></span>
      },
      accessor: 'dropdown',
      columns: [
        {
          accessor: 'dropdown',
          Cell: (props: any) => {
            const [moreDropdown, setMoreDropdown] = useState({ uuid: null, isActive: false })
            const moreButtonRef = useRef()

            const handleMoreButtonDropdownClose = () => {
              if (moreDropdown.uuid && moreDropdown.isActive) {
                setMoreDropdown({ uuid: null, isActive: false })
              }
            }

            useOutsideClickHandler(moreButtonRef, handleMoreButtonDropdownClose)
            const isDeleteDisabled = props.cell.row.original.status === 'PROCESSING'
            return (
              <StyledMoreButton
                ref={moreButtonRef}
                onClick={() =>
                  setMoreDropdown({
                    uuid: props.cell.row.original.uuid,
                    isActive:
                      moreDropdown.uuid === props.cell.row.original.uuid
                        ? !moreDropdown.isActive
                        : true,
                  })
                }
                isDropdownVisible={
                  moreDropdown.uuid === props.cell.row.original.uuid && moreDropdown.isActive
                }
              >
                <MoreHoriz />
                <ul>
                  <StyledMenuListItem
                    onClick={
                      isDeleteDisabled
                        ? null
                        : () => handleOpenDeleteBatchModal(props.cell.row.original)
                    }
                    disabled={isDeleteDisabled}
                  >
                    {t('common.moreButton.delete')}
                  </StyledMenuListItem>
                </ul>
              </StyledMoreButton>
            )
          },
        },
      ],
    },
  ]

  return (
    <>
      <StyledHeader>
        <StyledLabel>
          <input
            placeholder={t('batchList.search')}
            onChange={_.debounce((e) => {
              handleSearchChange(e.target.value)
            }, 700)}
          />
        </StyledLabel>
        <StyledBillingContainer>
          {isBatchBilled ? (
            <StyledCoinPrice>
              <img src={coinIcon} alt="coinIcon" />
              <span>
                <b>{servicePrices.avm_valuation}</b>
                {t('batchList.coinPricePerObject')}
              </span>
            </StyledCoinPrice>
          ) : (
            <StyledCheckmarkIcon>
              <CheckmarkIcon />
            </StyledCheckmarkIcon>
          )}
          <Button kind="primary" onClick={handleOpenBatchFormModal}>
            <img src={PlusWhiteIcon} alt="pluswhite" /> {t('batchList.createBatchButton')}
          </Button>
        </StyledBillingContainer>
      </StyledHeader>
      <TableStyles tableError={showTableError}>
        <BatchTableComponent columns={columns} data={showTableError ? [] : batchesData} />
        {showTableError && (
          <TableError
            tryAgainAction={() => {
              setShowToastError(true)
              refetch()
            }}
            message={t('batchList.error')}
            isLoading={isBatchListFetching}
          />
        )}
        {!showTableError && (
          <StyledPagination
            count={data?.totalPages}
            page={currentPage}
            onChange={(e, newPage) => setCurrentPage(newPage)}
          />
        )}
      </TableStyles>
      <DeleteBatchModal
        isOpened={openDeleteBatch}
        batch={activeBatch}
        onBackdropClick={onBackdropDeleteBatchClick}
        onBatchDelete={refetch}
      />
      <BatchInfoModal
        isOpened={openBatchInfo}
        batch={activeBatch}
        onBackdropClick={onBackdropBatchInfoClick}
      />
      <BatchFormModal isOpened={openBatchForm} onBackdropClick={onBackdropBatchFormClick} />
    </>
  )
}

export default BatchTable
