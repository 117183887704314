import { respondFrom } from 'common/styles/Responsive.styles'
import { theme } from 'common/styles/theme'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const StyledBatchData = styled.div`
  width: 100%;
  padding-top: 15px;
`

export const StyledLine = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.darkMedium};
  opacity: 0.2;
  margin: 10px 0 26px 0;
`

export const StyledFormColumn = styled.div`
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #495057;
  }
  .MuiInputLabel-animated {
    padding: 3px 10px 5px;
  }
  .MuiFormLabel-root {
    padding-top: 3px;
    font-size: 14px;
    color: #495057;
  }
  .MuiFormHelperText-root {
    padding-left: 10px;
  }
  .MuiInputBase-root {
    width: fit-content;
    height: 40px;
    font-size: 14px;
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
    border-radius: 4px;
    padding-right: 10px;

    ${respondFrom.lessThanTablet`
      width: 100%;
    `}

    &::before {
      border-bottom: 1px solid transparent;
    }

    &::after {
      border-bottom: 1px solid transparent;
    }

    .MuiInputBase-input {
      padding: 10px 10px 6px;
    }
    &:hover {
      &::before {
        border-bottom: 1px solid transparent;
      }
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(4px, 10px) scale(1);
    background-color: #fff;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    ${respondFrom.lessThanTablet`
      max-width: 160px;
    `}
    ${respondFrom.mobile`
      max-width: 240px;
    `}
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(4px, -8px) scale(0.75);
    max-width: unset;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }

  .MuiFormControl-root {
    margin: 0 0 0 0;

    ${respondFrom.lessThanTablet`
      min-width: 100%;
      > div {
        width: 100%;
      }
    `}
  }
`

export const StyledContinueButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const StyledDescription = styled(Typography)`
  width: 70%;
`

export const StyledBatchTemplate = styled.div`
  display: flex;
  padding-top: 15px;
  align-items: center;
`

export const StyledFormContainer = styled.div`
  display: flex;
  padding-top: 15px;
  align-items: flex-start;
`

export const StyledLabel = styled.div`
  padding-bottom: 10px;
`
