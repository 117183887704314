import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledBatchData,
  StyledBatchTemplate,
  StyledContinueButtonContainer,
  StyledDescription,
  StyledFormColumn,
  StyledFormContainer,
  StyledLabel,
  StyledLine,
} from './BatchFileUpload.styled'
import Button from 'components/Button/Button'
import { useSelector } from 'react-redux'
import Input from 'components/Input/Input'
import BatchFileDragAndDrop from './BatchFileDragAndDrop/BatchFileDragAndDrop'
import http from 'common/services/HttpService'
import { ToastStatusType, setToast } from 'slices/toastSlice'
import { useAppDispatch } from 'store/hooks'
import { useCreateBatchDraftMutation } from 'services/batch-api'
import { setCurrentBatchDraft } from 'slices/currentBatchDraftSlice'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { selectProductCost } from 'slices/coinsSlice'

const BatchFileUpload = (props: any) => {
  const { setCurrentTab } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const customerConfig = useSelector(selectCustomerConfiguration)
  const servicePrice = useSelector(selectProductCost('avm_valuation'))
  const [batchName, setBatchName] = useState('')
  const [batchData, setBatchData] = useState(null)
  const [isNameValid, setIsNameValid] = useState(true)
  const [isFileMissing, setIsFileMissing] = useState(false)
  const batchProduct = customerConfig?.products?.filter(
    (product) => product?.name?.toLowerCase() === 'avm_valuation'
  )[0]
  const batchMaxSize = batchProduct?.batchMaxSize || 1000

  const [createBatchDraft, { isLoading, isSuccess, isError, data }] = useCreateBatchDraftMutation()

  const handleDownloadTemplate = () => {
    const batchProductId = batchProduct?.id
    if (!batchProductId) {
      return
    }
    const anchor = document.createElement('a')
    anchor.setAttribute('download', t('batchFormModal.fileUpload.templateName'))
    document.body.appendChild(anchor)

    http
      .get(`/customer/product/${batchProductId}/batch-template`, {
        responseType: 'blob',
      })
      .then((response) => {
        const objectUrl = window.URL.createObjectURL(new Blob([response.data]))
        anchor.href = objectUrl
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
        document.body.removeChild(anchor)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          window.location.href = '/session-ended'
        } else {
          dispatch(
            setToast({
              type: ToastStatusType.ERROR,
              message: t('batchFormModal.fileUpload.template.download.error'),
            })
          )
        }
      })
  }

  const onChangeBatchName = (newName: string) => {
    setBatchName(newName)
  }

  const onContinueClick = () => {
    let isError = false
    if (!batchName || batchName?.length > 50) {
      setIsNameValid(false)
      isError = true
    } else {
      setIsNameValid(true)
    }
    if (!batchData?.length) {
      setIsFileMissing(true)
      isError = true
    }
    if (isError) {
      return
    }
    createBatchDraft({ data: batchData, type: 'AVM_VALUATION', name: batchName })
  }

  useEffect(() => {
    if (batchData?.length > 0) {
      setIsFileMissing(false)
    }
  }, [batchData])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCurrentBatchDraft(data))
      setCurrentTab(1)
    }
  }, [isSuccess, setCurrentTab, data, dispatch])

  useToastEffect(t('batchFormModal.fileUpload.createBathDraft.toast.error'), 'ERROR', [isError])
  useToastEffect(t('batchFormModal.fileUpload.createBathDraft.toast.success'), 'INFO', [isSuccess])

  return (
    <>
      <StyledBatchData>
        <StyledDescription>
          {t('batchFormModal.fileUpload.description', {
            value: batchMaxSize,
            coins: servicePrice,
          })}
        </StyledDescription>
        <StyledBatchTemplate>
          <div>{t('batchFormModal.fileUpload.template') + ' '}</div>
          <Button kind="link" onClick={() => handleDownloadTemplate()}>
            {t('batchFormModal.fileUpload.templateName')}
          </Button>
        </StyledBatchTemplate>
        <StyledLine />
        <StyledFormContainer>
          <StyledFormColumn style={{ width: '25%' }} validationError={!isNameValid}>
            <StyledLabel>{t('batchFormModal.fileUpload.name')}</StyledLabel>
            <Input
              value={batchName}
              onChange={(event) => onChangeBatchName(event.target.value)}
              label={`${t('batchFormModal.fileUpload.input.name')}*`}
              helperText={t('batchFormModal.fileUpload.input.name.validation')}
            />
          </StyledFormColumn>
          <div style={{ width: '75%' }}>
            <StyledLabel>{t('batchFormModal.fileUpload.batchFile')}</StyledLabel>
            <BatchFileDragAndDrop
              setBatchData={setBatchData}
              batchMaxSize={batchMaxSize}
              fileMissingError={isFileMissing}
              onFileRemove={() => {
                setIsFileMissing(false)
              }}
            />
          </div>
        </StyledFormContainer>
      </StyledBatchData>
      <StyledContinueButtonContainer>
        <Button
          kind="primary"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => onContinueClick()}
        >
          {isLoading ? t('common.loadingButton') : t('common.continueButton')}
        </Button>
      </StyledContinueButtonContainer>
    </>
  )
}

export default BatchFileUpload
