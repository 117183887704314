import styled from 'styled-components'

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 345px;
  z-index: 1000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #fff;
`

export const Container = styled.div`
  position: relative;
`

export const Inner = styled.div`
  position: relative;
  max-height: 320px;
  min-height: 80px;
  overflow-y: auto;
  padding: 0 30px;
`

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #495057;
  align-items: center;
  :hover {
    opacity: 0.7;
  }
`
export const ShowAllButton = styled.button`
  border: none;
  background: none;
  color: ${({ disabled }) => (disabled ? '#495057' : '#044f98')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  padding: 0;
  font-size: 12px;
  display: block;
  height: 50px;
  display: block;
  align-items: center;
  background-color: #fff;
  margin: 0 auto;
  font-family: inherit;
  :hover {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`

export const Badge = styled.div`
  font-size: 10px;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  right: -3px;
  top: -3px;
`

export const Icon = styled.div`
  position: relative;
  height: 36px;
`

export const NoNotifications = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  color: #495057;
  border-bottom: 1px solid #e9e9e7;
`
