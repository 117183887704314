import axios from 'axios'
import { isProductionApp } from 'common/utils/environmentCheck'
import { AuthInterceptor } from './AuthInterceptor'

const httpOptions = {
  baseURL: isProductionApp()
    ? process.env.REACT_APP_BACKEND_PROD
    : process.env.REACT_APP_BACKEND_DEV,
}

const http = axios.create(httpOptions)
http.interceptors.request.use(AuthInterceptor)

export default http
