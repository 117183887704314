import { respondFrom, respondTo } from 'common/styles/Responsive.styles'
import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledSwitchTabsAddressButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${respondFrom.lessThanTablet`
    flex-direction: column;
  `}

  p {
    color: #324245;
  }
`
export const StyledSwitchTabsObjectDataButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  /* .MuiButtonBase-root:nth-child(1) {
    background-color: transparent;
  } */
`

export const StyledAddressButtonReport = styled.div`
  width: 130px;
  height: 70px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgba(237, 237, 237, 0.4);
  margin: 0 30px 0 30px;
  border-radius: 4px;
  order: ${({ order }) => order || 'unset'};
  img {
    width: 36px;
  }

  ${respondTo.lessThanTablet`
    img {
      position: absolute;
      left: -20px;
    }
  `}

  .MuiButtonBase-root {
    width: 80px;
    margin-left: 20px;
    background-color: #e9e9e7;
    border: 1px solid ${theme.colors.darkMedium};
    color: ${theme.colors.dark};

    &::before {
      background-color: transparent;
    }

    &:disabled {
      background-color: #f5f5f4;
      border: 1px solid ${theme.colors.darkMedium};
      color: ${theme.colors.dark};
    }
    ${respondFrom.lessThanTablet`
      margin: 8px;
    `}
  }
  ${respondFrom.lessThanTablet`
    margin: 0 10px;
  `}
`

export const StyledAddressButtonIndex = styled.div`
  width: 130px;
  height: 70px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #eff7ff;
  margin: 0 0 0 30px;
  border-radius: 4px;
  order: ${({ order }) => order || 'unset'};

  img {
    width: 36px;
  }

  ${respondTo.lessThanTablet`
    img {
      position: absolute;
      left: -20px;
    }
  `}

  .MuiButtonBase-root {
    width: 80px;
    margin-left: 20px;

    &::before {
      background-color: transparent;
    }

    &:disabled {
      background-color: #f5f5f4;
      border: 1px solid ${theme.colors.darkMedium};
      color: ${theme.colors.dark};
    }
    ${respondFrom.lessThanTablet`
      margin: 8px;
    `}
  }
  ${respondFrom.lessThanTablet`
    margin: 0 10px;
  `}
`

export const StyledAddressButtonMaps = styled.div`
  width: 130px;
  height: 70px;
  position: relative;
  background-color: rgba(247, 166, 51, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 30px;
  border-radius: 4px;
  order: ${({ order }) => order || 'unset'};
  img {
    width: 36px;
  }

  ${respondTo.lessThanTablet`
    img {
      position: absolute;
      left: -20px;
    }
  `}

  .MuiButtonBase-root {
    width: 80px;
    margin-left: 20px;
    background-color: rgba(247, 166, 51, 0.1);
    border: 1px solid #f18500;
    color: #f18500;

    &::before {
      background-color: transparent;
    }
    &:disabled {
      background-color: #f5f5f4;
      border: 1px solid ${theme.colors.darkMedium};
      color: ${theme.colors.dark};
    }
    ${respondFrom.lessThanTablet`
      margin: 8px;
    `}
  }
  ${respondFrom.lessThanTablet`
    margin: 0 0;
  `}
`

export const StyledAddressButtonF2C = styled.div`
  width: 130px;
  height: 70px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgba(251, 232, 229, 0.4);
  margin: 0 30px 0 30px;
  border-radius: 4px;

  img {
    width: 36px;
  }

  ${respondTo.lessThanTablet`
    img {
      position: absolute;
      left: -20px;
    }
  `}

  .MuiButtonBase-root {
    width: 80px;
    margin-left: 20px;
    background-color: #fbe8e5;
    border: 1px solid #a93c30;
    color: #a93c30;

    &::before {
      background-color: transparent;
    }

    &:disabled {
      background-color: #f5f5f4;
      border: 1px solid ${theme.colors.darkMedium};
      color: ${theme.colors.dark};
    }
    ${respondFrom.lessThanTablet`
      margin: 8px;
    `}
  }
  ${respondFrom.lessThanTablet`
    margin: 0 10px;
  `}
`

export const StyledSwitchTabsEquipmentButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: #324245;
  }
  ${respondFrom.lessThanTablet`
    margin-top: 20px;
  `}

  .calculate-rating {
    margin-left: auto;
    margin-right: 24px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
      color: #495057;
      font-size: 14px;
    }

    .rating {
      color: #044f98;
      font-weight: 600;

      ::first-letter {
        text-transform: capitalize;
      }
    }
  }
`
export const StyledSwitchTabsReportButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: #324245;
  }
`

export const StyledAddressButtonsContainer = styled.div`
  display: flex;
`
