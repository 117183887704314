import styled from 'styled-components'

export const StyledBigImageContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  img {
    border-radius: 4px;
    width: 70%;
    height: auto;
    margin: 0;
    margin-top: 7px;
  }
`
export const StyledClose = styled.div`
  margin-top: 5px;
  margin-left: 15px;

  img {
    width: 20px;
    height: 20px;
  }
`
