const whiteList = [
  'https://bewertungscenter.sprengnetter.de',
  'https://bewertungscenter.flowfact.com',
  'https://bewertungscenter.sprengnetter.at',
  'https://bewertungscenter.propstack.de',
]

export const isProductionApp = () => {
  const { href } = window.location
  const results = whiteList.filter((option) => href.startsWith(option))
  return results.length > 0
}
