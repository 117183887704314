import { respondTo } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledEquipmentPanel = styled.div`
  width: 100%;
  ${respondTo.smallDesktop`
    height: 430px;
  `}
`

export const StyledEquipmentRow = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    /* position: absolute; */
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
  }
`

export const StyledEquipmentMethod = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  legend {
    font-size: 14px;
    color: #495057;
    margin-bottom: 3px;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 65px;
  }
`
