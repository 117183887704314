import React, { useEffect, useState } from 'react'
import {
  Container,
  Content,
  Description,
  Icon,
  ImageContainer,
  Title,
  Topbar,
  TypeTag,
  Footer,
} from './NotificationPopup.style'
import closeIcon from 'assets/images/icons/close-popup.svg'
import { NotificationType } from '../NotificationItem/NotificationItem'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import {
  useLazyGetAllNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
  useSetNotificationAsDeliveredMutation,
} from 'services/notifications-api'

const NotificationPopup = (props: any) => {
  const { type, name, text, image, url, newWindow, id } = props
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const { t } = useTranslation()
  const showType =
    type !== ('maintenance' as NotificationType) && type !== ('plain' as NotificationType)

  const [setNotificationAsDelivered, { isSuccess }] = useSetNotificationAsDeliveredMutation()

  const [getUnreadNotifications] = useLazyGetUnreadNotificationsQuery()

  const [getAllNotifications] = useLazyGetAllNotificationsQuery()

  useEffect(() => {
    if (isSuccess) {
      getUnreadNotifications()
      getAllNotifications()
    }
  }, [isSuccess, getUnreadNotifications, getAllNotifications])

  const handleContentClick = (event) => {
    event.stopPropagation()
    setNotificationAsDelivered({ id })
  }

  const handleClickOutside = () => {
    setIsVisible(false)
    setNotificationAsDelivered({ id })
  }

  return (
    (isVisible && (
      <Container onClick={handleClickOutside}>
        <Content onClick={handleContentClick}>
          <Topbar>
            {showType && <TypeTag type={type}>{type}</TypeTag>}
            <Icon src={closeIcon} alt="close notification" onClick={() => setIsVisible(false)} />
          </Topbar>
          {image && <ImageContainer src={image} alt="notification image" />}
          <Title>{name}</Title>
          <Description>{text}</Description>
          <Footer>
            <Button kind="secondary" onClick={() => setIsVisible(false)}>
              {t('common.notInterested')}
            </Button>
            {url && url.length && (
              <Button
                kind="primary"
                onClick={() => setIsVisible(false)}
                style={{ marginLeft: '10px' }}
              >
                <a target={newWindow ? '_blank' : '_self'} href={url} rel="noreferrer">
                  {t('common.learnMore')}
                </a>
              </Button>
            )}
          </Footer>
        </Content>
      </Container>
    )) ||
    null
  )
}

export default NotificationPopup
