import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  font-size: 14px;
  font-weight: 600;
`
