import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'

const MAP = '/map'

export const mapApi = createApi({
  reducerPath: 'mapApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getMapLink: builder.query<string, { id: number }>({
      query: (args) => ({
        url: `${MAP}/${args.id}`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
  }),
})

export const { useLazyGetMapLinkQuery } = mapApi
