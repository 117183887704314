const other = { label: 'common.other', value: 'SONSTIGE' }
const none = { label: 'common.none', value: 'KEINER' }

export const heatingType = [
  { label: 'newAddressModal.furnishingTab.heatingOptions.underfloor', value: 'FUSSBODENHEIZUNG' },
  { label: 'newAddressModal.furnishingTab.heatingOptions.singleOven', value: 'EINZELOEFEN' },
  { label: 'newAddressModal.furnishingTab.heatingOptions.central', value: 'ZENTRALHEIZUNG' },
  other,
]

export const roofCoveringEFHandMFH = [
  { label: 'newAddressModal.furnishingTab.roofOptions.felt', value: 'DACHPAPPE' },
  { label: 'newAddressModal.furnishingTab.roofOptions.tiles', value: 'DACHPFANNEN' },
  { label: 'newAddressModal.furnishingTab.roofOptions.metal', value: 'METALL' },
  { label: 'newAddressModal.furnishingTab.roofOptions.slate', value: 'SCHIEFER' },
  other,
]

export const windowsEFHandMFH = [
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.insulatingGlazing',
    value: 'ISOLIERVERGLASUNG',
  },
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.roomHeightGlazing',
    value: 'RAUMHOHE_VERGLASUNG',
  },
  { label: 'newAddressModal.furnishingTab.windowsOptions.box', value: 'KASTENFENSTER' },
  { label: 'newAddressModal.furnishingTab.windowsOptions.simple', value: 'EINFACH' },
  other,
]

export const windowsETW = [
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.insulatingGlazing',
    value: 'ISOLIERVERGLASUNG',
  },
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.doubleGlazing',
    value: 'ZWEIFACHVERGLASUNG',
  },
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.tripleGlazing',
    value: 'DREIFACHVERGLASUNG',
  },
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.specialGlazing',
    value: 'SPEZIALVERGLASUNG',
  },
  {
    label: 'newAddressModal.furnishingTab.windowsOptions.roomHeightGlazing',
    value: 'RAUMHOHE_VERGLASUNG',
  },
  { label: 'newAddressModal.furnishingTab.windowsOptions.box', value: 'KASTENFENSTER' },
  { label: 'newAddressModal.furnishingTab.windowsOptions.simple', value: 'EINFACH' },
  other,
]

export const storeETW = [
  { label: 'newAddressModal.furnishingTab.storageOptions.outside', value: 'AUSSERHALB' },
  { label: 'newAddressModal.furnishingTab.storageOptions.inside', value: 'INNERHALB' },
  {
    label: 'newAddressModal.furnishingTab.storageOptions.insideOutside',
    value: 'INNERHALB_UND_AUSSERHALB',
  },
  none,
]

export const storeMFH = [
  {
    label: 'newAddressModal.furnishingTab.storageOptions.insideOutside',
    value: 'INNERHALB_UND_AUSSERHALB',
  },
  none,
]

export const floorETW = [
  { label: 'newAddressModal.furnishingTab.floorCoveringOptions.tiles', value: 'FLIESEN' },
  { label: 'newAddressModal.furnishingTab.floorCoveringOptions.plastic', value: 'KUNSTSTOFF_PVC' },
  {
    label: 'newAddressModal.furnishingTab.floorCoveringOptions.parquet',
    value: 'PARKETT_NATURSTEIN',
  },
  { label: 'newAddressModal.furnishingTab.floorCoveringOptions.carpet', value: 'TEPPICH_LAMINAT' },
  other,
]

export const residentialAreaETW = [
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.conservatory',
    value: 'WINTERGARTEN',
  },
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.balconyOver',
    value: 'BALKON_UEBER_10',
  },
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.balconyUnder',
    value: 'BALKON_UNTER_10',
  },
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.noBalcony',
    value: 'KEINBALKON',
  },
]

export const residentialAreaMFH = [
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.balconyOver',
    value: 'BALKON_UEBER_10',
  },
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.balconyUnder',
    value: 'BALKON_UNTER_10',
  },
  {
    label: 'newAddressModal.furnishingTab.outdoorLivingAreaOptions.noBalcony',
    value: 'KEINBALKON',
  },
]

export const bathroomEFH = [
  { label: 'newAddressModal.furnishingTab.bathOptions.multi', value: 'MEHR_ALS_EIN_BAD' },
  { label: 'newAddressModal.furnishingTab.bathOptions.single', value: 'EIN_BAD' },
]

export const bathroomETW = [
  { label: 'newAddressModal.furnishingTab.bathOptions.internal', value: 'INNENLIEGEND' },
  { label: 'newAddressModal.furnishingTab.bathOptions.multi', value: 'MEHR_ALS_EIN_BAD' },
  { label: 'newAddressModal.furnishingTab.bathOptions.withWindow', value: 'MIT_FENSTER' },
]
