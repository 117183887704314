import React from 'react'
import dayjs from 'common/utils/defaultDayjs'
import { StyledDate } from './DateCell.style'

const DateCell = ({ date }: { date: number }) => {
  const parsed = dayjs.unix(date)
  const dayMonth = parsed.format('DD.MM.')
  const year = parsed.format('YYYY')

  return (
    <StyledDate>
      <div>{dayMonth}</div>
      <div className="year">{year}</div>
    </StyledDate>
  )
}

export default DateCell
