import styled from 'styled-components'

export const StyledDisabledLink = styled.div`
  > div > img,
  h4,
  p {
    filter: ${({ isDisabled }: any) => (isDisabled ? 'grayscale(1)' : 'unset')};
    opacity: ${({ isDisabled }: any) => (isDisabled ? '0.5' : '1')};
  }
`

export const StyledLink = styled.a`
  > div > img,
  h4,
  p {
    filter: ${({ isDisabled }: any) => (isDisabled ? 'grayscale(1)' : 'unset')};
    opacity: ${({ isDisabled }: any) => (isDisabled ? '0.5' : '1')};
  }
`
