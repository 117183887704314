import React from 'react'
import { ReactComponent as SortDescIcon } from 'assets/images/icons/activeSort.svg'
import { ReactComponent as SortAscIcon } from 'assets/images/icons/activeSortTwo.svg'
import { ISort, SortDirection } from 'types/ISort'

const Sort = ({
  properties,
  tableSort,
  handleSortChange,
}: {
  properties: string[]
  tableSort: ISort
  handleSortChange: (ISort) => void
}) => {
  const isActive = JSON.stringify(properties) === JSON.stringify(tableSort.properties)
  const Icon = isActive
    ? tableSort.direction === SortDirection.ASC
      ? SortAscIcon
      : SortDescIcon
    : SortDescIcon
  const reversedDirection =
    tableSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC

  return (
    <Icon
      onClick={() =>
        handleSortChange({
          properties,
          direction: isActive ? reversedDirection : tableSort.direction,
        })
      }
      className={isActive ? 'active' : ''}
      style={{
        fill: isActive ? '#1F497D' : '#C8CBCD',
        cursor: 'pointer',
        margin: '0px 4px',
        transform: isActive ? 'inherit' : 'rotate(180deg)',
      }}
    />
  )
}

export default Sort
