import { EquipmentMethod } from 'types/EquipmentMethod'
import {
  bathroomEFH,
  bathroomETW,
  floorETW,
  residentialAreaETW,
  residentialAreaMFH,
  roofCoveringEFHandMFH,
  storeETW,
  storeMFH,
  windowsEFHandMFH,
  windowsETW,
} from '../AddEquipment/AddEquipmentMultiSelect/helper'
import {
  guestToilet,
  surfaceMountedInstalationEFH,
} from '../AddEquipment/AddEquipmentRadioGroup/helper'
import {
  hausTypeEFHandMFH,
  hausTypeETW,
} from '../AddObjectData/AddObjectDataComponents/AddHouseParameters/helper'

const getEnumValue = (value: any, enums: any, valueField: string) => {
  if (value === null || value === undefined) {
    return value
  }
  const validValue = enums.find((item: any) => item[valueField] === value)
  if (validValue !== undefined) {
    return value
  } else {
    return null
  }
}

export const sanitizeAddressData = (leadData: any) => {
  let result = Object.assign(
    {},
    {
      id: leadData.id,
      customerId: leadData.customerId,
      name: leadData.name,
      surname: leadData.surname,
      phone: leadData.phone,
      email: leadData.email,
      insulatedExteriorWalls: leadData.insulatedExteriorWalls,
      heating: leadData.heating,
      category: leadData.category,
      livingArea: leadData.livingArea,
      constructionYear: leadData.constructionYear,
      garages: leadData.garages,
      date: leadData.date,
      nation: leadData.nation,
      street: leadData.street,
      houseNumber: leadData.houseNumber,
      zip: leadData.zip,
      town: leadData.town,
      lat: leadData.lat,
      lng: leadData.lng,
      resultDate: leadData.resultDate,
      value: leadData.value,
      model: leadData.model,
      standardError: leadData.standardError,
      score: leadData.score,
      rangeMin: leadData.rangeMin,
      rangeMax: leadData.rangeMax,
      equipment: leadData.equipment,
      equipmentMethod: leadData.equipmentMethod,
      parkingPlace: leadData.parkingPlace,
      image: leadData.image,
      imageMimeType: leadData.imageMimeType,
      outdoorParkingSpace: leadData.outdoorParkingSpace,
      externalId: leadData.externalId,
      label: leadData.label,
      isCopy: leadData.isCopy,
      buildingType: leadData.buildingType,
      commercialSpace: leadData.commercialSpace,
      numberOfUnits: leadData.numberOfUnits,
      numberOfCommercialUnits: leadData.numberOfCommercialUnits,
      project: leadData.project,
      loanAmount: leadData.loanAmount,
      indexReportType: leadData.indexReportType,
      indexPeriodType: leadData.indexPeriodType,
      isIndexContactDetailActive: leadData.isIndexContactDetailActive,
    }
  )
  switch (leadData.category) {
    case 'EFH':
      result = Object.assign(result, {
        bathroom: getEnumValue(leadData.bathroom, bathroomEFH, 'value'),
        roofCovering: getEnumValue(leadData.roofCovering, roofCoveringEFHandMFH, 'value'),
        windows: getEnumValue(leadData.windows, windowsEFHandMFH, 'value'),
        guestToilet: getEnumValue(leadData.guestToilet, guestToilet, 'key'),
        storeRoom: null,
        surfaceMountedInstallation: getEnumValue(
          leadData.surfaceMountedInstallation,
          surfaceMountedInstalationEFH,
          'key'
        ),
        construction: getEnumValue(leadData.construction, hausTypeEFHandMFH, 'value'),
        plotArea: leadData.plotArea,
        elevator: null,
        rooms: leadData.rooms,
        floor: null,
        residentialArea: null,
        numberOfFloors: leadData.numberOfFloors,
        floorCovering: null,
      })
      break
    case 'ETW':
      result = Object.assign(result, {
        bathroom: getEnumValue(leadData.bathroom, bathroomETW, 'value'),
        roofCovering: null,
        windows: getEnumValue(leadData.windows, windowsETW, 'value'),
        guestToilet: getEnumValue(leadData.guestToilet, guestToilet, 'key'),
        storeRoom: getEnumValue(leadData.windows, storeETW, 'value'),
        surfaceMountedInstallation: null,
        construction: getEnumValue(leadData.construction, hausTypeETW, 'value'),
        plotArea: null,
        elevator: leadData.elevator,
        rooms: leadData.rooms,
        floor: leadData.floor,
        residentialArea: getEnumValue(leadData.construction, residentialAreaETW, 'value'),
        numberOfFloors: null,
        floorCovering: getEnumValue(leadData.floorCovering, floorETW, 'value'),
      })
      break
    case 'MFH':
      result = Object.assign(result, {
        bathroom: null,
        roofCovering: getEnumValue(leadData.roofCovering, roofCoveringEFHandMFH, 'value'),
        windows: getEnumValue(leadData.windows, windowsEFHandMFH, 'value'),
        guestToilet: getEnumValue(leadData.guestToilet, guestToilet, 'key'),
        storeRoom: getEnumValue(leadData.windows, storeMFH, 'value'),
        surfaceMountedInstallation: null,
        construction: getEnumValue(leadData.construction, hausTypeEFHandMFH, 'value'),
        plotArea: leadData.plotArea,
        elevator: null,
        rooms: null,
        floor: null,
        residentialArea: getEnumValue(leadData.construction, residentialAreaMFH, 'value'),
        numberOfFloors: leadData.numberOfFloors,
        floorCovering: null,
      })
      break
  }

  if (result.equipmentMethod === EquipmentMethod.DETAILED) {
    delete result.equipment
  }

  return result
}
