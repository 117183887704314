import styled from 'styled-components'

export const StyledPropertyRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`
export const StyledPropertyCol = styled.div`
  width: 160px;
  height: 60px;
  display: grid;
  padding-right: 10px;

  label {
    font-size: 14px;
    color: #92969a;
    height: 41px;
  }
`

export const StyledPropertyValue = styled.div`
  font-size: 16px;
  color: #495057;
  display: flex;

  img {
    margin-right: 5px;
  }

  span::first-letter {
    text-transform: capitalize;
  }
`

export const StyledPropertyUnit = styled.div`
  color: #92969a;
  margin-left: 3px;
  font-size: 14px;
`
