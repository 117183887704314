import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledChip } from './BatchStatusChip.style'
import { BatchStatus } from 'types/BatchStatus'

export interface BatchStatusProps {
  status: BatchStatus
}

const BatchStatusChip = (props: BatchStatusProps) => {
  const { status } = props
  const { t } = useTranslation()

  return <StyledChip status={status} label={t(`batch.status.${status.toLowerCase()}`)} />
}

export default BatchStatusChip
