import React from 'react'
import { StyledProductGenerationSuccess } from './ProductGenerationSuccess.styles'
import SVG from 'react-inlinesvg'
import checkmarkImage from 'assets/images/icons/checkmark.svg'

export interface ProductGenerationSuccessProps {
  title: string
  message1: string
  message2?: string | null
  icon: any
  children?: React.ReactNode | null
}

const ProductGenerationSuccess = (props: ProductGenerationSuccessProps) => {
  const { title, message1, message2, icon, children } = props

  return (
    <StyledProductGenerationSuccess>
      <img src={icon} alt="" className="app-icon" />
      <h1>
        <SVG src={checkmarkImage} id="check-mark" />
        {title}
      </h1>
      <p>{message1}</p>
      {message2 && <p>{message2}</p>}
      {children && children}
    </StyledProductGenerationSuccess>
  )
}

export default ProductGenerationSuccess
