import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  font-size: 14px;
  font-weight: 600;
`

export const StlyledErrorsInfoContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  color: #495057;

  img {
    height: 40px;
  }
`

export const StyledErrorsListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #495057;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #c8cbcd;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  div {
    border-bottom: 1px solid rgba(124, 124, 123, 0.2);
  }
  div:last-child {
    border-bottom: none;
  }
`

export const StyledErrorsListRow = styled.div`
  display: flex;
  gap: 10px;

  span {
    color: #92969a;
  }

  p:nth-child(1) {
    width: 3%;
  }

  p:nth-child(2) {
    width: 12%;
  }

  p:nth-child(3) {
    width: 30%;
  }

  p:nth-child(4) {
    width: 55%;
  }
`
