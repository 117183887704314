const CSVLeadFormatter = (lead) => {
  return {
    date: new Date(lead.date * 1000).toLocaleDateString(),
    nation: lead.nation,
    street: lead.street,
    houseNumber: lead.houseNumber,
    zip: lead.zip,
    town: lead.town,
    lat: lead.lat,
    lng: lead.lng,
    category: lead.category,
    construction: lead.construction,
    constructionYear: lead.constructionYear,
    livingArea: lead.livingArea,
    plotArea: lead.plotArea,
    garages: lead.garages,
    elevator: lead.elevator,
    equipment: lead.equipment,
    value: lead.value,
    rangeMin: lead.rangeMin,
    rangeMax: lead.rangeMax,
    name: lead.name,
    surname: lead.surname,
    email: lead.email,
    phone: lead.phone,
  }
}

export default CSVLeadFormatter
