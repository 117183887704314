import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { ISort } from 'types/ISort'
import { AddressEntityType, AddressListApiResponseType } from 'types/ApiResponse'
import { LeadType } from 'types/LeadType'

const ADDRESS = '/leads'

export const addressApi = createApi({
  reducerPath: 'addressApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getAddressList: builder.query<
      AddressListApiResponseType,
      { page: number; sort: ISort; size?: number; search: string }
    >({
      query: ({ page, sort, size = 4, search }) => {
        const searchParam = search ? `&addressSearch=${search}` : ''
        const sortParam = sort.properties
          .map((property) => `sort=${property},${sort.direction}`)
          .join('&')
        return `${ADDRESS}?page=${page - 1}&size=${size}${searchParam}&${sortParam}`
      },
    }),
    getAddress: builder.query<AddressEntityType, { id: number }>({
      query: (args) => ({
        url: `${ADDRESS}/${args.id}`,
        method: 'GET',
      }),
    }),
    deleteAddress: builder.mutation<void, { id: number }>({
      query: (args) => ({
        url: `${ADDRESS}/${args.id}`,
        method: 'DELETE',
      }),
    }),
    createAddress: builder.mutation<AddressEntityType, any>({
      query: (body) => ({
        url: `${ADDRESS}`,
        method: 'POST',
        body,
      }),
    }),
    updateAddress: builder.mutation<AddressEntityType, { id: number; payload: any }>({
      query: (args) => ({
        url: `${ADDRESS}/${args.id}`,
        method: 'PUT',
        body: args.payload,
      }),
    }),
    getLeadDocumentLink: builder.query<string, { id: number; type: LeadType | null }>({
      query: (args) => ({
        url: `${ADDRESS}/${args.id}/document${args.type ? `?type=${args.type}` : ''}`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    getEquipmentLevel: builder.mutation<string, any>({
      query: (body) => ({
        url: `${ADDRESS}/equipment-level`,
        method: 'POST',
        body,
        responseHandler: 'text',
      }),
    }),
  }),
})

export const {
  useGetAddressListQuery,
  useGetAddressQuery,
  useLazyGetAddressQuery,
  useLazyGetLeadDocumentLinkQuery,
  useDeleteAddressMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useGetEquipmentLevelMutation,
} = addressApi
