import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

export interface AddressFormState {
  productName: string | null
  addressData: any | null
  isEdit: boolean
  validationErrors: any[]
  equipmentDetailsChanged: boolean
}

const initialState: AddressFormState = {
  productName: null,
  addressData: null,
  isEdit: false,
  validationErrors: [],
  equipmentDetailsChanged: false,
}

const addressFormSlice = createSlice({
  name: 'addressFormSlice',
  initialState,
  reducers: {
    setProductName: (state, action) => {
      state.productName = action.payload
    },
    setAddressData: (state, action) => {
      state.addressData = action.payload
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setFormField: (state, action) => {
      state.addressData = {
        ...state.addressData,
        [action.payload.key]: action.payload.value,
      }
    },
    removeFormField: (state, action) => {
      if (state.addressData !== null) {
        delete state.addressData[action.payload]
      }
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload
    },
    setEquipmentDetailsChanged: (state, action) => {
      state.equipmentDetailsChanged = action.payload
    },
    clearAddressForm: (state) => {
      state.productName = null
      state.addressData = null
      state.isEdit = false
      state.validationErrors = []
      state.equipmentDetailsChanged = false
    },
  },
})

export const {
  setProductName,
  setAddressData,
  setIsEdit,
  setFormField,
  setEquipmentDetailsChanged,
  removeFormField,
  clearAddressForm,
  setValidationErrors,
} = addressFormSlice.actions

export const selectAddressFormData = (state: RootState) => state.addressForm

export default addressFormSlice.reducer
