import { configureStore } from '@reduxjs/toolkit'
import { batchApi } from 'services/batch-api'
import toastSlice from 'slices/toastSlice'
import currentBatchDraftReducer from 'slices/currentBatchDraftSlice'
import notificationsReducer from 'slices/notificationsSlice'
import customerConfigurationReducer from 'slices/customerConfigurationSlice'
import coinsReducer from 'slices/coinsSlice'
import addressFormReducer from 'slices/addressFormSlice'
import { notificationsApi } from 'services/notifications-api'
import { configurationApi } from 'services/configuration-api'
import { coinsApi } from 'services/coins-api'
import { addressApi } from 'services/address-api'
import { mapApi } from 'services/map-api'
import { mapBoxApi } from 'services/mapbox-api'
import { reportApi } from 'services/report-api'
import { indicesApi } from 'services/indices-api'

export const store = configureStore({
  reducer: {
    currentBatchDraft: currentBatchDraftReducer,
    notifications: notificationsReducer,
    customerConfiguration: customerConfigurationReducer,
    coins: coinsReducer,
    toast: toastSlice,
    addressForm: addressFormReducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [configurationApi.reducerPath]: configurationApi.reducer,
    [coinsApi.reducerPath]: coinsApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [mapApi.reducerPath]: mapApi.reducer,
    [mapBoxApi.reducerPath]: mapBoxApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [indicesApi.reducerPath]: indicesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      batchApi.middleware,
      notificationsApi.middleware,
      configurationApi.middleware,
      coinsApi.middleware,
      addressApi.middleware,
      mapApi.middleware,
      mapBoxApi.middleware,
      reportApi.middleware,
      indicesApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
