import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import Pagination from '@material-ui/lab/Pagination'

export const TableStyles = styled.div<any>`
  width: 100%;
  height: 93%;
  ${respondFrom.lessThanTablet`
    overflow-x: scroll;
  `}
  table {
    border-spacing: 0;
    overflow: visible;
    border-spacing: 2px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    thead {
      display: table-header-group;
    }
    tbody {
      display: block;
      height: ${(props) => (props.tableError ? '0px' : '360px')};
    }
    tr {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 89px;
    }
    th,
    td {
      position: relative;
      margin: 0;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgb(224, 224, 224);
      border-radius: 4px 4px 0px 0px;
      border-right: 10px solid #fff;
      padding: 0;
      text-align: left;
      align-items: center;
      height: 89px;
    }
    th {
      height: 50px;
      vertical-align: bottom;
      div,
      span {
        color: #4a4a49;
        font-weight: 400;
        margin-bottom: 20px;
        margin-right: 5px;
        display: inline-block;
      }
    }
    td {
      text-align: left;
    }
    th:nth-child(1),
    td:nth-child(1) {
      width: 10%;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 50%;
      min-width: 250px;
      max-width: 500px;
      border-right: 0;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 15%;
      border-right: 0;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 20%;
      text-align: center;
      border-right: 0;
    }
    th:nth-child(5),
    td:nth-child(5) {
      width: 5%;
    }
  }
`

export const StyledColumn = styled.td<any>`
  background-color: #ffffff;
`

export const StyledMenuListItem = styled.li`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.disabled ? 'gray' : '')};
`

export const StyledLink = styled.div<any>`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: #044f98;
  }
  white-space: nowrap !important;
`

export const StyledDraftIcon = styled.img<any>`
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`

export const StyledNameContainer = styled.div<any>`
  width: 100%;
  span {
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
  }
`

export const StyledStatusContainer = styled.div<any>`
  display: flex;
`

export const StyledDownloadButton = styled(Button)<any>`
  min-width: 130px;
  min-height: 40px;
`

export const StyledPagination = styled(Pagination)<any>`
  margin-top: 10px;
`

export const StyledBillingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const StyledCheckmarkIcon = styled.div`
  display: flex;
  align-items: center;

  circle {
    fill: #5cb814;
  }
`

export const StyledCoinPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;
  gap: 8px;

  span {
    font-size: 14px;
    line-height: 14px;
    color: #1f497d;
  }

  img {
    height: 20px;
    width: 20px;
  }
`
