import { css } from 'styled-components'

const breakpoints = {
  lessThanDesktop: '1439px',
  smallDesktop: '1024px',
  moreThanTablet: '769px',
  tablet: '768px',
  lessThanTablet: '767px',
  mobile: '425px',
  mediumMobile: '424px',
  smallMobile: '320px',
}

export const respondFrom: any = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const respondTo: any = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
