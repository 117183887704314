import React from 'react'
import { useFilters, useGlobalFilter, useTable } from 'react-table'
import { StyledColumn } from './AddressTable.style'

function DefaultColumnFilter({ column: { preFilteredRows } }: any) {
  const count: any = preFilteredRows.length // eslint-disable-line

  return <></>
}

function TableComponent({ columns, data, leadColumnColor }: any) {
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }: any = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter
  )

  const firstPageRows = rows.slice(0, 100)

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(
            (headerGroup, index) =>
              index === 0 && (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: any) => (
                    <th key={index} {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              )
          )}
        </thead>

        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)

            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <StyledColumn
                      key={index}
                      {...cell.getCellProps()}
                      id={cell.column.id}
                      productName={cell.column.productName}
                      leadColumnColor={leadColumnColor}
                    >
                      {cell.render('Cell')}
                    </StyledColumn>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default TableComponent
