import React from 'react'
import { useSelector } from 'react-redux'
import Button from 'components/Button/Button'
import getProperUrl from 'common/utils/getProperUrl'
import { useTranslation } from 'react-i18next'
import Link from 'components/Link/Link'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { selectCustomerCoins } from 'slices/coinsSlice'
import { StyledButtonContainer, StyledContainer } from '../ProductCheckout.styled'

export interface NoCoinsContainerProps {
  icon: string
  descriptionKey: string
  buttonLabel: string
  price: number
}

const NoCoinsContainer = (props: NoCoinsContainerProps) => {
  const { icon, descriptionKey, buttonLabel, price } = props
  const { t } = useTranslation()
  const customerConfig = useSelector(selectCustomerConfiguration)
  const customerCoins = useSelector(selectCustomerCoins)
  const coinsLink = getProperUrl(customerConfig, 'coins')
  const subscriptionCoinsLink = getProperUrl(customerConfig, 'coins-subscription')
  const subscriptionCoinsData = customerCoins.coins?.accounts?.find(
    (item) => item.type === 'subscription'
  )

  return (
    <StyledContainer>
      <img src={icon} alt="" className="app-icon" />
      <h1>{t('newAddressModal.finishTab.noCoinsTitle')}</h1>
      <p className="no-coins-info">
        {t(descriptionKey, {
          value: price,
        })}
      </p>
      <StyledButtonContainer>
        {coinsLink?.active && (
          <Link url={coinsLink?.url} newTab={coinsLink?.newTab} channelName={customerConfig?.name}>
            <Button kind="tertiary">{t('common.coinsButton.prePaid')}</Button>
          </Link>
        )}
        {subscriptionCoinsLink?.active &&
          subscriptionCoinsLink?.url &&
          (subscriptionCoinsData === undefined ? (
            <Link
              url={subscriptionCoinsLink?.url}
              newTab={subscriptionCoinsLink?.newTab}
              channelName={customerConfig?.name}
            >
              <Button kind="tertiary">{t('common.coinsButton.subscription')}</Button>
            </Link>
          ) : (
            <Button type="disable" className="disabled-subscription-coins-button">
              {t('common.coinsButton.subscription')}
            </Button>
          ))}
        <Button type="disable" className="no-coins-button">
          {buttonLabel}
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default NoCoinsContainer
