import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledMultiSelectionCol = styled.div<any>`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-direction: column;

  ${respondFrom.smallDesktop`
    padding: 5px;
    width: 33.33%;
  `}

  ${respondFrom.lessThanTablet`
    padding: 5px;
    width: 50%;
  `}
  ${respondFrom.mobile`
    width: 100%;
  `}
`
export const StyledMultiSelectionRow = styled.div<any>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;

  ${respondFrom.lessThanTablet`
    flex-wrap: wrap;
  `}

  .MuiInputBase-root {
    height: 30px;

    .MuiInputBase-input {
      padding-left: 10px;
    }
  }
  .makeStyles-formControl-19 {
    margin: 0 0 0 0;
  }
  .MuiInputBase-root {
    width: 230px;
    height: 40px;
    font-size: 14px;
    border: 1px solid #7ca5ca;
    border-radius: 4px;

    ${respondFrom.lessThanTablet`
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    `}

    &::before {
      border-bottom: 1px solid transparent;
    }

    &::after {
      border-bottom: 1px solid transparent;
    }
    &:hover {
      &::before {
        border-bottom: 1px solid transparent;
      }
    }
  }
  .MuiInputLabel-animated {
    color: #495057;
    padding: 3px 10px 5px;
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(4px, 10px) scale(1);
    background-color: #fff;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${respondFrom.mobile`
      max-width: 240px;
    `}
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(4px, -6px) scale(0.75);
    max-width: unset;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }
  .MuiFormControl-root {
    ${respondFrom.lessThanTablet`
      min-width: 100%;
      > div {
        width: 100%;
      }
    `}
  }
`
export const StyledMultiSelectionColHeating = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledMultiSelectionColWindows = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledMultiSelectionColStoreRoom = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledMultiSelectionColFloor = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledMultiSelectionRoofCovering = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
