import { CustomerConfigurationEntityType, ProductType } from 'types/ApiResponse'

export const findInArray = (array = [], itemProp: string, value: string) =>
  array.find((item) => item?.[itemProp]?.toLowerCase() === value)

export const isProductCoveredBySubscription = (
  productName: string,
  customerConfig: CustomerConfigurationEntityType
) => {
  return customerConfig?.subscriptions.find((item) =>
    item.products?.find((product) => product.toUpperCase() === productName.toUpperCase())
  )
}

export const isProductBilled = (
  product: ProductType,
  customerConfig: CustomerConfigurationEntityType
) => {
  if (!product) return false
  if (product.billing) {
    if (product.subscriptionBased && isProductCoveredBySubscription(product.name, customerConfig)) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
