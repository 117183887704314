import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  StyledCartIcon,
  StyledProductIcon,
  StyledShortButton,
  StyledFooter,
  StyledProductInfo,
  StyledProductDescription,
  StyledProductTitle,
  StyledProductSubtitle,
  StyledLeftFooter,
  StyledRightFooter,
  StyledCoinPrice,
  StyledHomeIcon,
  StyledCheckmarkIcon,
  StyledFavoriteIcon,
} from './ProductTile.style'
import Link from 'components/Link/Link'
import cartIcon from 'assets/images/icons/shopcart.svg'
import addressListIcon from 'assets/images/icons/addressList.svg'
import batchListIcon from 'assets/images/icons/batchList.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/radio-checked.svg'
import line from 'assets/images/icons/line.svg'
import coinIcon from 'assets/images/icons/coin.svg'
import { useTranslation } from 'react-i18next'
import starFilledIcon from 'assets/images/icons/starFilled.svg'
import starEmptyIcon from 'assets/images/icons/starEmpty.svg'
import Tooltip from '@material-ui/core/Tooltip'

const ProductTile = (props: any) => {
  const { t } = useTranslation()
  const { product, customerConfig, icon, favoritesEnabled, favoritesList, saveFavorites } = props
  const isHome = product.isAddressList || product.isBatchList
  const homeLink = product.isAddressList ? '/leads' : '/batches'
  const [starHover, setStarHover] = useState(false)

  const onFavoriteClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const newFavorites = favoritesList.includes(product.id)
      ? favoritesList.filter((id: number) => id !== product.id)
      : favoritesList.concat([product.id])
    saveFavorites(newFavorites)
  }

  const ProductInfo = () => {
    return (
      <>
        <StyledProductInfo>
          {favoritesEnabled && (
            <StyledFavoriteIcon>
              <Tooltip
                title={
                  favoritesList.includes(product.id)
                    ? t('common.remove-favorite')
                    : t('common.add-favorite')
                }
                arrow
                placement="top"
              >
                <img
                  src={
                    starHover
                      ? starFilledIcon
                      : favoritesList.includes(product.id)
                      ? starFilledIcon
                      : starEmptyIcon
                  }
                  onMouseOver={() => setStarHover(true)}
                  onMouseOut={() => setStarHover(false)}
                  alt=""
                  onClick={(event) => onFavoriteClick(event)}
                />
              </Tooltip>
            </StyledFavoriteIcon>
          )}

          <StyledProductIcon>
            <img src={product?.imageUrl || icon} alt={product.name} />
          </StyledProductIcon>
          <StyledProductDescription>
            <StyledProductTitle>
              {t(`products.${product.name.toLowerCase().replace(/\s+/g, '')}.title`)}
            </StyledProductTitle>
            <StyledProductSubtitle>
              {t(`products.${product.name.toLowerCase().replace(/\s+/g, '')}.description`)}
            </StyledProductSubtitle>
          </StyledProductDescription>
        </StyledProductInfo>
        <StyledFooter>
          <StyledLeftFooter>
            {!product.showCheckmarkIcon && product.coinBased && product.coinPrice > 0 && (
              <StyledCoinPrice>
                <img src={coinIcon} alt="coinIcon" />
                <span>{product.coinPrice}</span>
              </StyledCoinPrice>
            )}
            {product.showCheckmarkIcon && (
              <StyledCheckmarkIcon>
                <CheckmarkIcon />
              </StyledCheckmarkIcon>
            )}
            {product.showCartIcon && (
              <StyledCartIcon>
                <img src={cartIcon} alt="cart.svg" />
              </StyledCartIcon>
            )}
            {((product.coinPrice > 0 && product.coinBased) ||
              product.showCheckmarkIcon ||
              product.showCartIcon) &&
              isHome && <img src={line} className="separator" alt="" />}
            {isHome && (
              <StyledHomeIcon>
                <img src={product?.isAddressList ? addressListIcon : batchListIcon} alt="" />
              </StyledHomeIcon>
            )}
          </StyledLeftFooter>
          <StyledRightFooter>
            {(product?.urlMarketing?.length && (
              <Link
                url={product?.urlMarketing}
                newTab={product?.newTab}
                channelName={customerConfig?.name}
                className="more-info"
                stopPropagation={true}
              >
                {t(product.showCartIcon ? 'common.checkOffer' : 'common.moreInfo')}
              </Link>
            )) ||
              null}
          </StyledRightFooter>
        </StyledFooter>
      </>
    )
  }

  return (
    <StyledShortButton hoverColors={product.hoverColor} isDisabled={product.isDisabled}>
      {(!isHome || (isHome && product.showCartIcon)) && (
        <Link
          url={product.showCartIcon ? product?.urlMarketing : product?.url}
          newTab={product?.newTab}
          channelName={customerConfig?.name}
          isDisabled={product?.isDisabled}
        >
          <ProductInfo />
        </Link>
      )}
      {isHome && !product.showCartIcon && (
        <RouterLink to={homeLink}>
          <ProductInfo />
        </RouterLink>
      )}
    </StyledShortButton>
  )
}

export default ProductTile
