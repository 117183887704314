import React from 'react'
import { StyledBigImageContainer, StyledClose } from './BigImage.styled'
import close from 'assets/images/icons/close.svg'

const BigImage = ({ data, onClickClose }: any) => {
  return (
    <StyledBigImageContainer>
      <img src={`data:image/jpeg;base64,${data.image}`} alt="big" />
      <StyledClose onClick={onClickClose}>
        <img src={close} alt="close" />
      </StyledClose>
    </StyledBigImageContainer>
  )
}

export default BigImage
