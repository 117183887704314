import React from 'react'
import { default as MaterialButton } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledButton } from './Button.style'

function Button({
  children,
  style,
  kind,
  icon,
  disabled,
  loading,
  active,
  noBorder,
  ...props
}: any): any {
  return (
    <StyledButton kind={kind} disabled={disabled} active={active} noBorder={noBorder}>
      <MaterialButton
        disabled={disabled}
        variant="contained"
        color="primary"
        style={style}
        active={active}
        {...props}
      >
        {icon ? <img src={icon} alt="" style={{ marginRight: 10 }} /> : null}
        {children}
        {loading && <CircularProgress size={15} color="secondary" />}
      </MaterialButton>
    </StyledButton>
  )
}

export default Button
