import styled from 'styled-components'

export const getColors = (name) => {
  switch (name) {
    case 'ERROR':
    case 'OFFER':
    case 'SUBSCRIPTION_END':
      return `
        color: #EF111E;
        background: rgba(255, 214, 221, 0.94);
        `
    case 'MAINTENANCE':
    case 'INFO':
      return `
            color: #4174A3;
            background: #E9F2FB;
            `
    default:
      return ``
  }
}

export const Container = styled.div`
  position: relative;
  color: #495057;
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e7;
`
export const Information = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`
export const Date = styled.div``
export const TypeTag = styled.div`
  ${({ type }) => getColors(type)}
  padding: 2px 4px;
  text-transform: uppercase;
  margin-left: 8px;
  font-weight: 600;
`
export const Title = styled.h4`
  margin: 6px 0;
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
`
export const Description = styled.p`
  font-size: 12px;
  white-space: normal;
  margin: 0 0 10px;
`
export const LinkInner = styled.span`
  color: #044f98;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 12px !important;
  font-weight: 600;
  :hover {
    text-decoration: underline;
  }
`
export const Icon = styled.img`
  height: 18px;
  width: 18px;
`

export const UnreadBadge = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 25px;
  left: -15px;
`
