import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  .second-button {
    border: 1px solid #044f98;
  }
`
export const StyledBackdropButton = styled.div`
  margin-right: 10px;

  .MuiButton-containedPrimary {
    background-color: #ffffff;
    border: 1px solid #044f98;
    color: #044f98;
  }
`

export const StyledQuestionBeforeDelete = styled.div`
  width: 100%;
  font-size: 14px;
  color: #495057;
  margin-top: 15px;
`

export const StyledBatchContainer = styled.div`
  width: 100%;
  height: 67px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 15px;
`
export const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 10px;
  font-size: 16px;

  img {
    margin-right: 10px;
  }
`
export const StyledDate = styled.div`
  margin-left: 45px;
  font-size: 14px;
  color: #92969a;
  display: flex;
  flex-direction: row;
`
