import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledModalContainer = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 224, 224);
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #495057;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #92969a;
  }

  img {
    margin-left: 150px;
  }

  ${respondFrom.lessThanTablet`
    img {
      margin-left: 20px;
    }
  `}
`

export const StyledHeader = styled.div<any>`
  display: flex;
  align-items: center;

  > div {
    margin-left: 20px;

    button.MuiButton-containedPrimary {
      border: 0;
    }
  }
`
