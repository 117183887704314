import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg'
import { SessionEndContainer, StyledInfoIcon } from './SessionEnd.styles'

const SessionEnd = () => {
  const { t } = useTranslation()

  return (
    <SessionEndContainer>
      <StyledInfoIcon>
        <InfoIcon height={40} width={40} />
      </StyledInfoIcon>
      <h1>{t('common.error.session-ended.line1')}</h1>
      <h2>{t('common.error.session-ended.line2')}</h2>
    </SessionEndContainer>
  )
}

export default SessionEnd
