import { Menu } from '@material-ui/core'
import styled from 'styled-components'

export const StyledBuyCoinsMenu = styled(Menu)`
  & .MuiPaper-root {
    margin-top: 5px;

    & .MuiMenuItem-root {
      &:hover {
        background-color: #0000000a;
        color: #000000;
      }
    }
  }
`
