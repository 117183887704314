import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledHouseParametersRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const StyledHouseParametersRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const StyledHouseParametersCol = styled.div`
  width: 25%;
  min-height: 70px;
  max-height: 70px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  flex-direction: column;

  label {
    display: block;
  }

  ${respondFrom.smallDesktop`
    width: 33.33%;
  `}

  ${respondFrom.lessThanTablet`
    width: 50%;
    padding: 0 5px;
  `}

  ${respondFrom.mobile`
    width: 100%;
  `}

  .cViiLY .MuiFormControl-root {
    width: 65%;
    margin-top: 10px;
    color: #495057;
  }

  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #495057;
  }

  .MuiInputLabel-animated {
    padding: 3px 10px 5px;
  }

  .MuiFormLabel-root {
    padding-top: 3px;
    font-size: 14px;
    color: #495057;
  }

  .MuiFormHelperText-root {
    padding-left: 10px;
  }

  .makeStyles-root-9 > * {
    margin: 0;
  }

  .makeStyles-formControl-19 {
    margin: 0;
  }

  .MuiInputBase-root {
    width: 200px;
    height: 40px;
    font-size: 14px;
    border: 1px solid #7ca5ca;
    border-radius: 4px;
    padding-right: 10px;

    ${respondFrom.lessThanTablet`
      width: 100%;
    `}

    &::before {
      border-bottom: 1px solid transparent;
    }

    &::after {
      border-bottom: 1px solid transparent;
    }

    .MuiInputBase-input {
      padding: 10px 10px 6px;
    }
    &:hover {
      &::before {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(4px, 10px) scale(1);
    background-color: #fff;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    ${respondFrom.lessThanTablet`
      max-width: 160px;
    `}
    ${respondFrom.mobile`
      max-width: 240px;
    `}
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(4px, -8px) scale(0.75);
    max-width: unset;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }

  .MuiFormControl-root {
    margin: 0 0 0 0;

    ${respondFrom.lessThanTablet`
      min-width: 100%;
      > div {
        width: 100%;
      }
    `}
  }
`
export const StyledHouseParametersColConstruction = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledHouseParametersColPlotArea = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledHouseParametersColConstructionYear = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledHouseParametersColLivingArea = styled.div`
  .MuiInputBase-root {
    border: ${({ validationError }) => (validationError ? '1px solid red' : '1px solid #7ca5ca')};
  }
`
export const StyledHouseParametersColElevator = styled.div`
  .MuiFormLabel-root {
    color: ${({ validationError }) => (validationError ? 'red' : '#495057')};
  }
`
export const StyledHouseParametersColGarage = styled.div`
  .MuiFormLabel-root {
    color: ${({ validationError }) => (validationError ? 'red' : '#495057')};
  }
`
