import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query'
import { getToken } from 'common/services/AuthInterceptor'
import { isProductionApp } from 'common/utils/environmentCheck'

export const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: isProductionApp()
    ? process.env.REACT_APP_BACKEND_PROD
    : process.env.REACT_APP_BACKEND_DEV,
  prepareHeaders: (headers) => {
    const token = getToken()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithSessionEnd: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQueryWithAuth(args, api, extraOptions)
  if (result?.error?.status === 401) {
    window.location.href = '/session-ended'
  }
  return result
}
