import React from 'react'
import { useSelector } from 'react-redux'
import { StyledDisabledLink, StyledLink } from './Link.styled'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'

interface LinkProps {
  children: React.ReactNode
  newTab: boolean
  url: string
  channelName: string
  isDisabled?: boolean
  className?: string
  stopPropagation?: boolean
}

const DisableLink = (props: any) => {
  const { children, isDisabled, ...rest } = props

  if (!props?.href?.length || isDisabled) {
    return (
      <StyledDisabledLink isDisabled={isDisabled} {...rest}>
        {children}
      </StyledDisabledLink>
    )
  }
  return (
    <StyledLink isDisabled={isDisabled} {...rest}>
      {children}
    </StyledLink>
  )
}

const Link = (props: LinkProps) => {
  const { configuration } = useSelector(selectCustomerConfiguration)
  const { newTab, url = '', isDisabled, children, className, stopPropagation } = props
  const enablePostMessage = !newTab && configuration.isPostMessageActive === 'true'
  const postMessageOrigin = configuration.origin || ''
  const hasURL = url?.length
  const handleLinkClick = (e): void => {
    if (stopPropagation) {
      e.stopPropagation()
    }
    if (enablePostMessage) {
      e.preventDefault()
      window.parent.postMessage({ url }, postMessageOrigin)
    }
  }

  return (
    <DisableLink
      href={hasURL ? url : ''}
      target={hasURL ? (newTab ? '_blank' : '_top') : '_self'}
      rel="noreferrer"
      onClick={handleLinkClick}
      style={{ textDecoration: 'none', color: 'inherit' }}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </DisableLink>
  )
}

export default Link
