import styled from 'styled-components'

export const StyledDate = styled.div<any>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 23px;
  .year {
    color: #92969a;
    font-size: 0.875rem;
  }
`
