import React from 'react'
import { StyledTableError, StyledTryAgainButton, StyledWarningIcon } from './TableError.styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'

export interface TableErrorProps {
  tryAgainAction: () => void
  message: string
  isLoading: boolean
}

const TableError = (props: TableErrorProps) => {
  const { tryAgainAction, message, isLoading } = props
  const { t } = useTranslation()

  return (
    <StyledTableError>
      <StyledWarningIcon>
        <WarningIcon height={40} width={40} />
      </StyledWarningIcon>
      <h1>{message}</h1>
      <h2>{t('common.error.table.try-again.line2')}</h2>
      <StyledTryAgainButton
        kind="secondary"
        onClick={tryAgainAction}
        loading={isLoading}
        active={isLoading}
      >
        {isLoading ? '' : t('common.error.table.try-again.button')}
      </StyledTryAgainButton>
    </StyledTableError>
  )
}

export default TableError
