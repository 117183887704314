import Chip from '@material-ui/core/Chip/Chip'
import styled from 'styled-components'
import { BatchStatus } from 'types/BatchStatus'

const getChipColor = (status: BatchStatus) => {
  switch (status) {
    case BatchStatus.COMPLETED:
      return '#EFF8E7'
    case BatchStatus.DRAFT:
      return '#F6F6F6'
    case BatchStatus.ERROR:
      return '#FBF5F5'
    case BatchStatus.PROCESSING:
      return '#E9F2FB'
  }
}

export const StyledChip = styled(Chip)`
  border-radius: 4px;
  background-color: ${(props) => getChipColor(props.status)};
  width: fit-content;
`
