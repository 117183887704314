import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { selectAddressFormData } from 'slices/addressFormSlice'
import ReportCheckout from './ReportCheckout/ReportCheckout'
import MapCheckout from './MapCheckout/MapCheckout'
import IndexCheckout from './IndexCheckout/IndexCheckout'

export interface ProductCheckoutProps {
  isReportGenerated: boolean
  setReportGenerated: Dispatch<SetStateAction<boolean>>
  onBackdropClick: () => void
}

const ProductCheckout = (props: ProductCheckoutProps) => {
  const addressFormData = useSelector(selectAddressFormData)

  switch (addressFormData.productName) {
    case 'report':
      return <ReportCheckout {...props} />
    case 'map':
      return <MapCheckout {...props} />
    case 'index':
      return <IndexCheckout {...props} />
    default:
      return <></>
  }
}

export default ProductCheckout
