import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { CustomerCoinsEntityType } from 'types/ApiResponse'

const COINS = '/coins'

export const coinsApi = createApi({
  reducerPath: 'coinsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getProductCost: builder.query<Record<string, number>, string>({
      query: (product) => ({
        url: `${COINS}/${product}`,
        method: 'GET',
      }),
    }),
    getProductsCost: builder.query<Record<string, number>, string[]>({
      query: (products) => ({
        url: `${COINS}/products/${products.join()}`,
        method: 'GET',
      }),
    }),
    getCustomerCoins: builder.query<CustomerCoinsEntityType, void>({
      query: () => ({
        url: `/customer/V2/coins`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetProductCostQuery,
  useGetProductsCostQuery,
  useGetCustomerCoinsQuery,
  useLazyGetCustomerCoinsQuery,
} = coinsApi
