import Pagination from '@material-ui/lab/Pagination'
import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

const getColumnColor = (id) => {
  switch (id) {
    case 'lead':
      return '#f6f6f6'
    case 'report':
      return '#f6f6f6'
    case 'maps':
      return '#fef8ef'
    case 'indices':
      return '#eff7ff'
    default:
      return '#ffffff'
  }
}

const getColumnWidth = (columnsNo: number) => {
  switch (columnsNo) {
    case 7:
      return '30%'
    case 6:
      return '40%'
    case 5:
      return '50%'
    case 4:
      return '60%'
  }
}

export const TableStyles = styled.div<any>`
  width: 100%;
  height: 93%;
  ${respondFrom.lessThanTablet`
    overflow-x: scroll;
  `}
  table {
    width: 100%;
    border-spacing: 0;
    overflow-y: hidden;
    border-spacing: 2px;
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    thead {
      display: table-header-group;
    }
    tbody {
      display: block;
      height: ${(props) => (props.tableError ? '0px' : '360px')};
    }
    tr {
      display: table;
      table-layout: fixed;
      width: auto;
      height: 89px;
    }
    th,
    td {
      position: relative;
      width: 141px;
      min-width: 130px;
      height: 89px;
      margin: 0;
      padding: 26px 16px;
      text-align: center;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgb(224, 224, 224);
      border-radius: 4px 4px 0px 0px;
      border-right: 10px solid #fff;
    }
    th {
      height: 50px;
      padding: 0;
      vertical-align: bottom;
      div,
      span {
        color: #4a4a49;
        font-weight: 400;
      }
      img.app-icon {
        margin-bottom: 2px;
      }
    }
    td {
      div[kind='link'] {
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 100%;
        .MuiButtonBase-root {
          font-size: 12px;
          padding: 0;
        }
      }

      .plus-button {
        min-width: inherit;
        width: 36px;
        height: 36px;
        padding: 0;
      }

      .bin-icon {
        opacity: 0.5;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 70px;
      width: 100px;
      padding: 1rem 0px;
      text-align: left;
    }
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 350px;
      width: ${(props) => getColumnWidth(props.columnsNo)};
      padding: 1rem 0px;
      text-align: left;
      border-right: 0;
    }
    th:nth-last-child(2),
    td:nth-last-child(2) {
      border-right: 0;
    }
    th:last-child,
    td:last-child {
      width: 5%;
      padding: 26px 16px;
      min-width: 20px;
    }
  }
`

export const StyledFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const StyledTags = styled.div`
  display: flex;
`

export const StyledNoData = styled.div<any>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 400;
`
export const SpinnerStyled = styled.div<any>`
  .MuiCircularProgress-root {
    margin-right: 10px;
  }
`

export const StyledImageLinkContainer = styled.div<any>`
  position: relative;
  z-index: 2;
  display: block;
  text-decoration: none;
  color: inherit;
  padding-bottom: 10px;
  img {
    transition: opacity 0.2s;
  }
  &:after {
    content: '';
    position: absolute;
    height: 60px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 4px 4px 0px 0px;
    z-index: -1;
  }
  > div,
  > img {
    position: relative;
  }
`

export const StyledColumn = styled.td<any>`
  background-color: ${(props) =>
    props.leadColumnColor && props.productName === 'lead'
      ? props.leadColumnColor
      : getColumnColor(props.productName)};
`

export const LeadLogo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  & + & {
    margin-left: 5px;
  }
`

export const StyledPagination = styled(Pagination)<any>`
  margin-top: 10px;
`

export const StyledProductName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 5px;
  padding-right: 5px;

  img {
    height: 24px;
  }
`

export const StyledCoinPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 14px;
    color: #1f497d !important;
  }

  img {
    height: 20px;
    width: 20px;
  }
`

export const StyledCheckmarkIcon = styled.div`
  display: flex;
  align-items: center;

  circle {
    fill: #5cb814;
  }
`

export const StyledCartIcon = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 20px;
    width: 20px;
  }
`
