import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledObjectType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .cViiLY .MuiFormControl-root {
    width: 78%;
  }

  legend {
    font-size: 14px;
    color: #495057;
    margin-bottom: 3px;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 14px;

    p {
      width: 35px;
      height: 18px;
      margin: 0;
      font-size: 12px;
      margin-left: 14px;
      margin-right: 65px;
      background-color: #f6f6f6;
      border-radius: 2px;
      text-align: center;
      color: ${theme.colors.dark};
      font-weight: 600;
    }
  }
`
