import { useEffect } from 'react'
import { ToastStatusType, setToast } from 'slices/toastSlice'
import { useAppDispatch } from 'store/hooks'

export const useToastEffect = (
  message: string,
  type: keyof typeof ToastStatusType,
  dependencies: boolean[],
  cb?: () => void,
  timeout?: number
) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (dependencies.length && dependencies.every(Boolean)) {
      dispatch(
        setToast({
          message,
          type: ToastStatusType[type],
          timeout: timeout ?? 5000,
        })
      )
      cb && cb()
    }
  }, dependencies) // eslint-disable-line
}
