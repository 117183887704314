import React from 'react'
import {
  Container,
  Date,
  Description,
  Icon,
  Information,
  LinkInner,
  Title,
  TypeTag,
  UnreadBadge,
} from './NotificationItem.style'
import ExternalIcon from 'assets/images/icons/external.svg'
import Link from 'components/Link/Link'
import { useTranslation } from 'react-i18next'

export type NotificationType = 'image' | 'plain' | 'special offer' | 'system'

export interface INotificationItem {
  id: string
  name: string
  type: NotificationType
  draft: boolean
  startDate: string
  text: string
  image: string
  url: string
  newWindow: boolean
  popUp: boolean
  onClick: (id) => void
}

interface INotificationItemProps extends INotificationItem {
  channelName: string
  isUnread: boolean
}

const NotificationItem = (props: INotificationItemProps) => {
  const { type, startDate, name, text, newWindow, url, id, channelName, isUnread, onClick } = props
  const { t } = useTranslation()
  const showType =
    type !== ('maintenance' as NotificationType) && type !== ('plain' as NotificationType)

  return (
    <Container onClick={() => onClick(id)}>
      {isUnread && <UnreadBadge />}
      <Information>
        <Date>{startDate}</Date>
        {showType && <TypeTag type={type}>{type}</TypeTag>}
      </Information>
      <Title>{name}</Title>
      <Description>{text}</Description>
      {url && url.length && (
        <Link channelName={channelName} url={url} newTab={newWindow}>
          <LinkInner>
            {t('common.learnMore')}
            <Icon src={ExternalIcon} alt="external link" />
          </LinkInner>
        </Link>
      )}
    </Container>
  )
}

export default NotificationItem
