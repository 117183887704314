import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import Address from './AddressInfoModalComponents/Address/Address'
import HouseProperty from './AddressInfoModalComponents/HouseProperty/HouseProperty'
import { StyledButtonContainer } from './AddressInfoModal.styled'
import Button from 'components/Button/Button'
import BigImage from './AddressInfoModalComponents/BigImage/BigImage'
import { useTranslation } from 'react-i18next'
import { BasicAddressEntityType } from 'types/ApiResponse'
import { useGetAddressQuery } from 'services/address-api'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import LoadError from './AddressInfoModalComponents/LoadError/LoadError'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 820,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

export interface AddressInfoModalProps {
  isOpened: boolean
  address: BasicAddressEntityType | null
  onBackdropClick: () => void
}

const AddressInfoModal = (props: AddressInfoModalProps) => {
  const { isOpened, address, onBackdropClick } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)
  const [bigImage, setBigImage] = useState(false)
  const [showToastError, setShowToastError] = useState(false)

  const { data, isError, isFetching, isLoading, isSuccess, refetch } = useGetAddressQuery(
    { id: address?.id },
    {
      skip: isOpened === false,
    }
  )

  useEffect(() => {
    if (isSuccess && !isFetching && !isLoading) {
      setShowToastError(false)
    }
  }, [isSuccess, isFetching, isLoading])

  useToastEffect(t('addressModal.error.toast'), 'ERROR', [isError, showToastError])

  const onCloseModal = () => {
    onBackdropClick()
    setBigImage(false)
    setShowToastError(false)
  }

  if (address === null) {
    return <></>
  }

  const showBody = !(isError || showToastError) && isSuccess && !isLoading && !isFetching

  const body = (
    <>
      <div style={modalStyle} className={classes.paper}>
        <ModalTop label={t('addressModal.title')} onBackdropClick={onCloseModal} />
        {showBody && (
          <>
            {bigImage === false && (
              <Address
                data={data}
                onClickThumb={() => {
                  setBigImage(true)
                }}
              />
            )}
            {bigImage === false && <HouseProperty data={data} />}
            {bigImage && (
              <BigImage
                data={data}
                onClickClose={() => {
                  setBigImage(false)
                }}
              />
            )}
          </>
        )}
        {(isError || showToastError) && (
          <LoadError
            tryAgainAction={() => {
              setShowToastError(true)
              refetch()
            }}
            isLoading={isFetching}
          />
        )}
        <StyledButtonContainer>
          <Button onClick={onCloseModal}>{t('common.closeButton')}</Button>
        </StyledButtonContainer>
      </div>
    </>
  )

  return (
    <MaterialModal
      open={isOpened}
      disableBackdropClick={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      style={{ height: '750px' }}
    >
      {body}
    </MaterialModal>
  )
}

export default AddressInfoModal
