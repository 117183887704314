import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledShortButton = styled.div`
  border-radius: 18px;
  background-color: ${({ isDisabled }: any) => (isDisabled ? '#dfdfdf' : '#F9F9F9')};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  position: relative;
  width: calc(33.33% - 10px);
  gap: 8px;

  ${respondFrom.smallDesktop`
    width: calc(50% - 10px);
  `}

  ${respondFrom.lessThanTablet`
    width: calc(100% - 10px);
  `}

  > a, > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    border-radius: 18px;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  a:hover:not(:has(a:hover)):not(.more-info) {
    background-color: ${({ hoverColors, isDisabled }: any) =>
      isDisabled ? '#dfdfdf' : hoverColors || '#d6eaff'};
  }
`

export const StyledProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 10px 10px;
  gap: 16px;
`

export const StyledProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
  gap: 8px;
`

export const StyledProductTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #495057;
`

export const StyledProductSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7c7c7b;
`

export const StyledProductIcon = styled.div`
  position: relative;
  display: inline-block;
  height: 90px;
  width: 90px;
  > img {
    height: 90px;
    width: 90px;
  }
`

export const StyledFooter = styled.div`
  padding: 0px 8px 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`

export const StyledLeftFooter = styled.div`
  display: flex;
  padding: 0px 0px 0px 16px;

  > img {
    height: 28px;
  }
`

export const StyledRightFooter = styled.div`
  color: #7c7c7b;
  a {
    display: block;
    height: 37px;
    width: 148px;
    padding: 0px 8px 0px 8px;
    border-radius: 16px;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: inherit;
    align-content: center;

    :hover {
      background-color: #ededed;
    }
  }
`

export const StyledHomeIcon = styled.div`
  padding: 0px 12px 0px 12px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;

  img {
    height: 28px;
    width: 28px;
  }
`

export const StyledCoinPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 0px;
  gap: 8px;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #1f497d;
  }

  img {
    height: 20px;
    width: 20px;
  }
`

export const StyledCheckmarkIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 0px;

  circle {
    fill: #5cb814;
  }
`

export const StyledCartIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 0px;
  img {
    height: 20px;
    width: 20px;
  }
`

export const StyledFavoriteIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  height: 28px;
  width: 28px;

  img {
    height: 20px;
    width: 20px;
  }
`
