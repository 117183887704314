import Slider from '@material-ui/core/Slider'
import styled from 'styled-components'

export const StyledSlider = styled(Slider)`
  min-width: 560px;

  & .MuiSlider-markLabel[data-index='0'] {
    border-left: none;
  }

  & .MuiSlider-markLabel {
    color: #495057;
    font-size: 14px;
    font-weight: 400;
    top: -80px;
    width: 14.2%;
    min-width: 80px;
    height: 127px;
    border-left: 1px solid #d8d8d7;
  }

  & .MuiSlider-markLabelActive {
    color: #044f98;
    font-weight: 600;
  }

  & .MuiSlider-markLabel p {
    text-wrap: wrap;
    text-align: center;
    padding: 0px 10px 0px 10px;
    margin-top: 5px;
  }

  & .MuiSlider-rail {
    height: 4px;
    border-radius: 4px;
    color: #d4e5f7;
    opacity: 1;
  }

  & .MuiSlider-mark {
    display: none;
  }

  & .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -9px;
    color: #044f98;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 1);

    &:hover {
      color: #1f497d;
      box-shadow: 0px 0px 0px 6px #e6edf5;
    }
  }

  & .MuiSlider-thumb.MuiSlider-active {
    color: #1f497d;
    box-shadow: 0px 0px 0px 10px #e6edf5;
  }
`

export const StyledLevelDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #495057;

  b {
    font-weight: 600;
  }

  ::first-letter {
    text-transform: capitalize;
  }
`
