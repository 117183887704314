import React from 'react'
import { StyledPropertyCol, StyledPropertyRow, StyledPropertyValue } from './BatchProperty.styled'
import { useTranslation } from 'react-i18next'
import dayjs from 'common/utils/defaultDayjs'
import BatchStatusChip from 'components/BatchStatusChip/BatchStatusChip'

const BatchProperty = ({ batch }: any) => {
  const { t } = useTranslation()

  function formatTime(timestamp) {
    if (timestamp) {
      const time = dayjs.unix(timestamp)
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{time.format('HH:mm:ss')}</span>
          <span>{time.format('DD.MM.YYYY')}</span>
        </div>
      )
    } else return '—'
  }

  return (
    <>
      <StyledPropertyRow>
        <StyledPropertyCol style={{ width: '100%' }}>
          <label>{t('batchInfoModal.name')}</label>
          <StyledPropertyValue>{batch.name}</StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.status')}</label>
          <BatchStatusChip status={batch.status} />
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.batchSize')}</label>
          <StyledPropertyValue>{batch.batchSize}</StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.failedRowsSize')}</label>
          <StyledPropertyValue>
            {batch?.failedRowsSize !== undefined ? batch?.failedRowsSize : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.creationTime')}</label>
          <StyledPropertyValue>{formatTime(batch.creationTime)}</StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.processingStartTime')}</label>
          <StyledPropertyValue>{formatTime(batch.processingStartTime)}</StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('batchInfoModal.processingEndTime')}</label>
          <StyledPropertyValue>{formatTime(batch.processingEndTime)}</StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
    </>
  )
}

export default BatchProperty
