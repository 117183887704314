import React, { useState, ChangeEvent, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { StyledMultiSelect } from './RadioGroupButtons.styled'
import radioChecked from 'assets/images/icons/radio-checked.svg'
import radioUnchecked from 'assets/images/icons/radio-unchecked.svg'
import radioValidation from 'assets/images/icons/radio-validation.svg'
import { useTranslation } from 'react-i18next'

export default function RadioGroupButtons({
  options,
  label,
  onChangeProps,
  defaultValue,
  primary,
  validationErrorCheckbox,
  ...props
}: any) {
  const [value, setValue] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value.toString())
    onChangeProps((event.target as HTMLInputElement).value)
  }

  return (
    <StyledMultiSelect>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue={value || defaultValue}
          value={value}
          onChange={handleChange}
          {...props}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={option.key + index}
              value={option.key}
              control={
                <Radio
                  icon={
                    <img src={validationErrorCheckbox ? radioValidation : radioUnchecked} alt="" />
                  }
                  checkedIcon={<img src={radioChecked} alt="" />}
                  disabled={option.disabled}
                />
              }
              label={typeof option.value === 'string' ? t(option.value) : option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </StyledMultiSelect>
  )
}
