import styled from 'styled-components'

export const StyledMultiSelect = styled.div<any>`
  width: 100%;

  .MuiFormControl-root {
    width: 100%;

    .MuiFormGroup-root {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      span {
        &::before {
          background: none;
        }
      }
    }
  }
`
