import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { NotificationEntityType } from 'types/ApiResponse'
import { sortByDate } from 'common/utils/utils'

const NOTIFICATIONS = '/notifications'

const sortNotifications = (response: NotificationEntityType[]): any => {
  const formatedData = response.map((notification) => ({
    ...notification,
    startDate: new Date(notification.startDate * 1000).toLocaleDateString(),
  }))
  return sortByDate(formatedData)
}

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getAllNotifications: builder.query<NotificationEntityType[], void>({
      query: () => ({
        url: `${NOTIFICATIONS}/all`,
        method: 'GET',
      }),
      transformResponse: sortNotifications,
    }),
    getUnreadNotifications: builder.query<NotificationEntityType[], void>({
      query: () => ({
        url: `${NOTIFICATIONS}/new`,
        method: 'GET',
      }),
      transformResponse: sortNotifications,
    }),
    setNotificationAsDelivered: builder.mutation<void, { id: string }>({
      query: (args) => ({
        url: `${NOTIFICATIONS}/${args.id}/delivered`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useLazyGetAllNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
  useSetNotificationAsDeliveredMutation,
} = notificationsApi
