import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ToastState, ToastStatusType, clearToast } from 'slices/toastSlice'
import { useAppDispatch } from 'store/hooks'
import {
  StyledCloseButton,
  StyledErrorIcon,
  StyledToastBody,
  StyledToastContent,
} from './Toast.styles'
import { Close } from '@material-ui/icons'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'
import { useTranslation } from 'react-i18next'

export const Toast = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const toast = useSelector(({ toast }: { toast: ToastState }) => toast)
  const [message, setMessage] = useState<string | null>()
  const [type, setType] = useState<ToastStatusType | null>()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const timeoutRef = useRef<any>()

  useEffect(() => {
    if (toast.message && toast.type) {
      setMessage(toast.message)
      setType(toast.type)
      setIsVisible(true)

      if (toast.timeout) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false)
          setTimeout(() => {
            dispatch(clearToast())
            setMessage(null)
            setType(null)
          }, 100)
        }, toast.timeout - 100)
      }
    }
  }, [toast, dispatch])

  const handleMessageClose = () => {
    setIsVisible(false)
    setTimeout(() => {
      dispatch(clearToast())
      setMessage(null)
      setType(null)
    }, 100)
  }

  return (
    <StyledToastBody isVisible={isVisible}>
      <StyledToastContent type={type}>
        {type === ToastStatusType.ERROR && (
          <>
            <StyledErrorIcon>
              <WarningIcon height={24} width={24} />
            </StyledErrorIcon>
            <span style={{ fontWeight: 600, marginLeft: 12 }}>{t('common.error.toast')}</span>
          </>
        )}
        <span style={{ marginLeft: 4 }}>{message}</span>
        <StyledCloseButton onClick={handleMessageClose}>
          <Close fontSize="small" />
        </StyledCloseButton>
      </StyledToastContent>
    </StyledToastBody>
  )
}
