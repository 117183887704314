import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import mapIcon from 'assets/images/icons/SprengnetterAppsMaps1x.png'
import Button from 'components/Button/Button'
import { StyledContainer } from '../ProductCheckout.styled'
import { useTranslation } from 'react-i18next'
import { selectAddressFormData } from 'slices/addressFormSlice'
import { selectCustomerCoins, selectProductsCost } from 'slices/coinsSlice'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useLazyGetMapLinkQuery } from 'services/map-api'
import NoCoinsContainer from '../NoCoinsContainer/NoCoinsContainer'
import ProductGenerationError from 'components/ProductGenerationError/ProductGenerationError'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { useLazyGetCustomerCoinsQuery } from 'services/coins-api'
import ProductGenerationSuccess from 'components/ProductGenerationSuccess/ProductGenerationSuccess'
import { findInArray, isProductBilled } from 'common/utils/generic'

export interface MapCheckoutProps {
  isReportGenerated: boolean
  setReportGenerated: Dispatch<SetStateAction<boolean>>
}

const MapCheckout = (props: MapCheckoutProps) => {
  const { isReportGenerated, setReportGenerated } = props
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const servicePrices = useSelector(selectProductsCost)
  const customerConfig = useSelector(selectCustomerConfiguration)
  const customerCoins = useSelector(selectCustomerCoins)
  const premiumCoins =
    customerCoins.coins?.accounts?.find((item) => item.type === 'on-demand')?.amount || 0
  const subscriptionCoinsData = customerCoins.coins?.accounts?.find(
    (item) => item.type === 'subscription'
  )
  const subscriptionCoins = subscriptionCoinsData?.amount
  const mapsProduct = findInArray(customerConfig?.products, 'name', 'maps')
  const isMapBilled = isProductBilled(mapsProduct, customerConfig)
  const hasEnoughCoins =
    (premiumCoins || subscriptionCoins) &&
    (servicePrices?.maps <= subscriptionCoins || servicePrices?.maps <= premiumCoins)
  const [showToastError, setShowToastError] = useState(false)

  const [getCustomerCoins] = useLazyGetCustomerCoinsQuery()

  const [getMapLink, { data, isSuccess, isError, isFetching }] = useLazyGetMapLinkQuery()

  const handleGenerateMapButtonClick = () => {
    if (isFetching) return
    getMapLink({ id: addressFormData.addressData.id })
  }

  const handleOpenMapButtonClick = () => {
    if (isSuccess && data && !isFetching) {
      window.open(data, '_blank')
    }
  }

  useEffect(() => {
    if (isSuccess) {
      getCustomerCoins()
      setShowToastError(false)
    }
    if (isSuccess || isError) {
      setReportGenerated(true)
    }
  }, [isSuccess, isError, getCustomerCoins, setReportGenerated])

  useToastEffect(t('newAddressModal.finishTab.mapInfo.error.toast'), 'ERROR', [
    isError,
    showToastError,
  ])

  return (
    <>
      {!isReportGenerated &&
        (!isMapBilled || hasEnoughCoins ? (
          <StyledContainer>
            <img src={mapIcon} alt="" className="app-icon" />
            <h1>{t('common.generateMap')}</h1>
            {isMapBilled && (
              <p>
                {t('newAddressModal.finishTab.mapInfo.description', {
                  value: servicePrices?.maps,
                })}
              </p>
            )}
            <div onClick={() => handleGenerateMapButtonClick()}>
              <Button kind="primary" loading={isFetching} disabled={isFetching}>
                {isFetching ? t('common.loadingButton') : t('common.generateMap')}
              </Button>
            </div>
          </StyledContainer>
        ) : (
          <NoCoinsContainer
            icon={mapIcon}
            descriptionKey="newAddressModal.finishTab.mapInfo.noCoinsDescription"
            buttonLabel={t('common.generateMap')}
            price={servicePrices?.maps}
          />
        ))}
      {isSuccess && (
        <ProductGenerationSuccess
          icon={mapIcon}
          title={t('newAddressModal.finishTab.mapInfo.successTitle')}
          message1={t('newAddressModal.finishTab.mapInfo.successDescription')}
        >
          <Button kind="primary" onClick={handleOpenMapButtonClick}>
            {t('common.showMapButton')}
          </Button>
        </ProductGenerationSuccess>
      )}
      {(isError || showToastError) && (
        <ProductGenerationError
          icon={mapIcon}
          title={t('newAddressModal.finishTab.mapInfo.errorTitle')}
          message1={t('newAddressModal.finishTab.mapInfo.errorDescription.1')}
          message2={t('newAddressModal.finishTab.mapInfo.errorDescription.2')}
          isLoading={isFetching}
          tryAgainAction={() => {
            setShowToastError(true)
            handleGenerateMapButtonClick()
          }}
        />
      )}
    </>
  )
}

export default MapCheckout
