const TOKEN_KEY = 'jwt_token'

export const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let token

  if (urlParams.has(TOKEN_KEY)) {
    token = urlParams.get(TOKEN_KEY)
    window.localStorage.setItem(TOKEN_KEY, token)
  } else {
    token = window.localStorage.getItem(TOKEN_KEY)
  }
  return token
}

export const removeToken = () => {
  if (window.location.origin.indexOf('localhost') === -1) {
    window.localStorage.removeItem(TOKEN_KEY)
  }
}

export const AuthInterceptor = (request) => {
  const token = getToken()
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
}
