import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import { AddressGeocoded } from 'types/AddressGeocoded'
import { mapMapBoxPlaceResponse } from 'common/utils/utils'

const MAPBOX = '/mapbox'
const PLACES_API = '/geocoding/v5/mapbox.places'

export const mapBoxApi = createApi({
  reducerPath: 'mapBoxApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getAddressFromLatLng: builder.query<AddressGeocoded, { lat: number; lng: number }>({
      query: (args) => ({
        url: `${MAPBOX}${PLACES_API}/${args.lng},${args.lat}.json`,
        method: 'GET',
      }),
      transformResponse: mapMapBoxPlaceResponse,
    }),
  }),
})

export const { useLazyGetAddressFromLatLngQuery } = mapBoxApi
