const confirmText = 'common.yes'
const declineText = 'common.no'

const sharedOptions = [
  { key: 'true', value: confirmText, disabled: false },
  { key: 'false', value: declineText, disabled: false },
]

export const garageSelect = sharedOptions

export const parkingSpace = sharedOptions

export const elevatorSelect = sharedOptions

export const includeContactDetailsSelect = sharedOptions

export const reportTypeSelect = [
  {
    key: 'VALUATION',
    value: 'newAddressModal.objectDataTab.reportType.valuation',
    disabled: false,
  },
  { key: 'RENT', value: 'newAddressModal.objectDataTab.reportType.rent', disabled: false },
]

export const periodTypeSelect = [
  {
    key: 'YEAR_1',
    value: 'newAddressModal.objectDataTab.periodType.1_year',
    disabled: false,
  },
  {
    key: 'YEAR_2',
    value: 'newAddressModal.objectDataTab.periodType.2_year',
    disabled: false,
  },
  {
    key: 'YEAR_5',
    value: 'newAddressModal.objectDataTab.periodType.5_year',
    disabled: false,
  },
  {
    key: 'YEAR_10',
    value: 'newAddressModal.objectDataTab.periodType.10_year',
    disabled: false,
  },
]

export const hausTypeEFHandMFH = [
  {
    label: 'newAddressModal.objectDataTab.houseTypesEFHandMFH.semiDetachedHouse',
    value: 'DOPPELHAUS',
  },
  {
    label: 'newAddressModal.objectDataTab.houseTypesEFHandMFH.detached',
    value: 'FREISTEHEND',
  },
  {
    label: 'newAddressModal.objectDataTab.houseTypesEFHandMFH.terracedHouse',
    value: 'REIHEN_ENDHAUS',
  },
  {
    label: 'newAddressModal.objectDataTab.houseTypesEFHandMFH.midTerracedHouse',
    value: 'REIHEN_MITTELHAUS',
  },
]

export const hausTypeETW = [
  { label: 'newAddressModal.objectDataTab.houseTypesETW.attic', value: 'DACHGESCHOSS' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.groundFloor', value: 'ERDGESCHOSSWOHNUNG' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.apartment', value: 'ETAGENWOHNUNG' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.mezzanine', value: 'HOCHPARTERRE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.loft', value: 'LOFT' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.duplex', value: 'MAISONETTE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.penthouse', value: 'PENTHOUSE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.basement', value: 'SOUTERRAIN' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.terrace', value: 'TERRASSENWOHNUNG' },
]

export const hausTypeETW_AT = [
  { label: 'newAddressModal.objectDataTab.houseTypesETW.attic', value: 'DACHGESCHOSS' },
  { label: 'houseTypes.topFloor', value: 'DACHGESCHOSSPENTHOUSE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.groundFloor', value: 'ERDGESCHOSSWOHNUNG' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.apartment', value: 'ETAGENWOHNUNG' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.loft', value: 'LOFT' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.duplex', value: 'MAISONETTE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.garden', value: 'MITGARTENTEIL' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.penthouse', value: 'PENTHOUSE' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.basement', value: 'SOUTERRAIN' },
  { label: 'newAddressModal.objectDataTab.houseTypesETW.terrace', value: 'TERRASSENWOHNUNG' },
]
