import React from 'react'
import checkMarkYes from 'assets/images/icons/checkMarkYes.svg'
import checkMarkNo from 'assets/images/icons/checkMarkNo.svg'
import {
  StyledPropertyCol,
  StyledPropertyRow,
  StyledPropertyUnit,
  StyledPropertyValue,
} from './HouseProperty.styled'
import { useTranslation } from 'react-i18next'
import { EquipmentMethod } from 'types/EquipmentMethod'

const HouseProperty = ({ data }: any) => {
  const { t } = useTranslation()
  const confirmText = t('common.yes')
  const declineText = t('common.no')
  function changeConstruction(construction) {
    switch (construction) {
      case 'DOPPELHAUS':
        return t('newAddressModal.objectDataTab.houseTypesEFHandMFH.semiDetachedHouse')
      case 'FREISTEHEND':
        return t('newAddressModal.objectDataTab.houseTypesEFHandMFH.detached')
      case 'REIHEN_ENDHAUS':
        return t('newAddressModal.objectDataTab.houseTypesEFHandMFH.terracedHouse')
      case 'REIHEN_MITTELHAUS':
        return t('newAddressModal.objectDataTab.houseTypesEFHandMFH.midTerracedHouse')
      case 'LOFT':
        return t('newAddressModal.objectDataTab.houseTypesETW.loft')
      case 'PENTHOUSE':
        return t('newAddressModal.objectDataTab.houseTypesETW.penthouse')
      case 'SOUTERRAIN':
        return t('newAddressModal.objectDataTab.houseTypesETW.basement')
      case 'ERDGESCHOSSWOHNUNG':
        return t('newAddressModal.objectDataTab.houseTypesETW.groundFloor')
      case 'HOCHPARTERRE':
        return t('newAddressModal.objectDataTab.houseTypesETW.mezzanine')
      case 'MAISONETTE':
        return t('newAddressModal.objectDataTab.houseTypesETW.duplex')
      case 'TERRASSENWOHNUNG':
        return t('newAddressModal.objectDataTab.houseTypesETW.terrace')
      case 'DACHGESCHOSS':
        return t('newAddressModal.objectDataTab.houseTypesETW.attic')
      case 'ETAGENWOHNUNG':
        return t('newAddressModal.objectDataTab.houseTypesETW.apartment')
      case 'DACHGESCHOSSPENTHOUSE':
        return t('houseTypes.topFloor')
      case 'MITGARTENTEIL':
        return t('houseTypes.garden')
      default:
        return '—'
    }
  }

  const getEquipmentMethodName = (equipmentMethod) => {
    switch (equipmentMethod) {
      case EquipmentMethod.SIMPLE:
        return t('newAddressModal.furnishingTab.equipmentMethod.simple')
      case EquipmentMethod.DETAILED:
        return t('newAddressModal.furnishingTab.equipmentMethod.detailed')
      default:
        return '—'
    }
  }

  return (
    <>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.houseType')}</label>
          <StyledPropertyValue>
            <span>{changeConstruction(data?.construction)}</span>
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.buildYear')}</label>
          <StyledPropertyValue>
            {data?.constructionYear ? data?.constructionYear : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.livingArea')}</label>
          <StyledPropertyValue>
            {data?.livingArea ? data?.livingArea : '—'}
            <StyledPropertyUnit>m2</StyledPropertyUnit>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.plotArea')}</label>
          <StyledPropertyValue>
            {data?.plotArea ? data?.plotArea : '—'}
            <StyledPropertyUnit>m2</StyledPropertyUnit>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.rooms')}</label>
          <StyledPropertyValue>{data?.rooms ? data?.rooms : '—'}</StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('addressModal.floors')}</label>
          <StyledPropertyValue>
            {data?.floor || data?.numberOfFloors ? data?.floor || data?.numberOfFloors : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
      <StyledPropertyRow>
        <StyledPropertyCol>
          <label>{t('addressModal.equipmentLevel')}</label>
          <StyledPropertyValue>
            <span>
              {data?.equipment ? t(`equipmentLevel.${data?.equipment.toLowerCase()}`) : '—'}
            </span>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('addressModal.equipmentMethod')}</label>
          <StyledPropertyValue>
            <span>{getEquipmentMethodName(data?.equipmentMethod)}</span>
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.garage')}</label>
          <StyledPropertyValue>
            {data.garages && <img src={checkMarkYes} alt="checkMarkYes" />}
            {data.garages === false && <img src={checkMarkNo} alt="checkMarkNo" />}
            {data?.garages
              ? confirmText
              : data.garages === null
              ? '—'
              : data.garages === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.parkingPlace')}</label>
          <StyledPropertyValue>
            {data.outdoorParkingSpace ? (
              <img src={checkMarkYes} alt="checkMarkYes" />
            ) : data.outdoorParkingSpace === false ? (
              <img src={checkMarkNo} alt="checkMarkNo" />
            ) : null}
            {data?.outdoorParkingSpace
              ? confirmText
              : data.outdoorParkingSpace === null
              ? '—'
              : data.outdoorParkingSpace === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
        <StyledPropertyCol>
          <label>{t('newAddressModal.objectDataTab.elevator')}</label>
          <StyledPropertyValue>
            {data.elevator && <img src={checkMarkYes} alt="checkMarkYes" />}
            {data.elevator === false && <img src={checkMarkNo} alt="checkMarkNo" />}
            {data?.elevator
              ? confirmText
              : data.elevator === null
              ? '—'
              : data.elevator === false
              ? declineText
              : '—'}
          </StyledPropertyValue>
        </StyledPropertyCol>
      </StyledPropertyRow>
    </>
  )
}

export default HouseProperty
