const confirmText = 'common.yes'
const declineText = 'common.no'

export const insulatedWalls = [
  { key: 'AUSSENWAENDE_GEDAEMMT', value: confirmText },
  { key: 'AUSSENWAENDE_NICHT_GEDAEMMT', value: declineText },
]

export const guestToilet = [
  { key: 'GAESTE_WC', value: confirmText },
  { key: 'KEIN_GAESTE_WC', value: declineText },
]
export const surfaceMountedInstalationEFH = [
  { key: 'LEITUNGEN_AUF_PUTZ', value: confirmText },
  { key: 'LEITUNGEN_NICHT_AUF_PUTZ', value: declineText },
]
