import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

export const StyledFileUpload = styled.div`
  width: 100%;
  border: 2px dashed ${({ isError }) => (!isError ? '#f2f4f4' : 'red')};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin: 0 0 20px;
  background-color: ${({ transparent }) => (transparent ? '#f8fbfe' : 'transparent')};
  position: relative;

  .file-drop {
    width: 100%;
  }

  &:hover {
    border: 2px dashed #044f98;

    SVG {
      circle,
      path {
        stroke: #044f98;
      }
    }
  }
  ${respondFrom.lessThanTablet`
    width: auto;
  `}
`

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  margin-left: 10px;
  justify-content: left;
  align-items: center;
  opacity: ${({ isDrag }) => (isDrag ? 0.1 : 1)};

  p {
    margin: 0;
    size: 14px;
    color: #495057;

    span {
      color: #044f98;
      margin-left: 10px;

      :hover {
        cursor: pointer;
      }
    }

    &:last-child {
      size: 12px;
      color: #92969a;
    }
  }

  img {
    margin-right: 20px;
    height: 40px;
  }

  .trash-icon {
    :hover {
      cursor: pointer;
    }
    margin-left: auto;
  }
`

export const StyledDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  width: 100%;
`

export const StyledOnDragDescription = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  padding: 15px;
  width: 100%;
  color: #495057;

  img {
    margin-right: 20px;
  }
`

export const StyledDescriptionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledSpinner = styled(CircularProgress)`
  margin-right: 20px;
  width: 40px !important;
  height: auto !important;
`
