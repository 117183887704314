import styled from 'styled-components'
import searchIcon from 'assets/images/icons/search-blue.svg'
import searchIconBold from 'assets/images/icons/search-blue-bold.svg'
import closeIcon from 'assets/images/icons/closeInput.svg'
import { respondFrom, respondTo } from 'common/styles/Responsive.styles'

export const StyledAddressPanel = styled.div`
  width: 100%;

  ${respondTo.smallDesktop`
    height: 400px;
  `}

  form {
    .MuiTextField-root {
      margin-left: 0;
      margin-bottom: 13px;
    }
  }

  .MuiFilledInput-root {
    width: 300px;
    height: 36px;
    border: 1px solid #7ca5ca;
    border-radius: 4px;
    background-color: #f8fbfe;
    padding: 7px 34px;
    position: relative;
    &::before {
      content: '';
      height: 24px;
      width: 24px;
      background-image: url(${searchIcon});
      background-size: cover;
      position: absolute;
      border: 0;
      top: 5px;
      left: 5px;
    }

    &::after {
      // display: none;
    }
  }
  .mapboxgl-map {
    overflow: visible !important;
  }
  .mapboxgl-ctrl-top-right {
    position: relative;
    width: 100px;
  }
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    position: absolute;
    z-index: 1000;
    top: -60px;
  }
  .mapboxgl-ctrl-geocoder--input {
    width: 100px;
    height: 10px;
    border: 1px solid #7ca5ca;
    border-radius: 4px;
    color: #044f98 !important;
    background-color: #f8fbfe;
    padding: 7px 34px;
    position: absolute;
    ::placeholder {
      color: #044f98;
    }
  }
  .mapboxgl-ctrl-geocoder--icon-search {
    stroke-width: -10px;
    fill: #044f98;
    position: absolute;
    z-index: 1;
    transform: rotate(90deg);
    ${respondFrom.lessThanTablet`
      top: 6px;
    `}
  }
  ${respondTo.lessThanTablet`
    .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
      right: unset;
    }
  `}
  .mapboxgl-ctrl-geocoder--button {
    position: absolute;
    z-index: 1;
    left: 275px;
    background: #f8fbfe !important;
    fill: #044f98 !important;
    .mapboxgl-ctrl-geocoder--icon {
      box-shadow: none !important;
      background-color: transparent !important;
      fill: #044f98 !important;
      &:hover {
        box-shadow: none !important;
        background-color: transparent !important;
      }
    }
    ${respondFrom.lessThanTablet`
      left: auto;
      right: 2px;
      top: 1px;
    `}
  }
  .mapbox-improve-map {
    display: none;
  }
  .suggestions-wrapper {
    ul {
      margin-top: 50px;
      margin-left: 10px;
    }
  }
`
export const SyledAddresInfoContainer = styled.div<any>`
  width: 100%;
  min-height: 44px;
  display: flex;
  margin-top: 20px;
`

export const StyledAddressInfo = styled.div<any>`
  color: #495057;
  font-size: 14px;
  line-height: 24px;
`

export const StyledCoordinates = styled.div<any>`
  font-size: 12px;
  line-height: 20px;
  color: #92969a;
`
export const StyledCoordinateContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`
export const StyledLabel = styled.div<any>`
  width: 100%;
  height: 100px;
  padding-top: 25px;
  color: #495057;
`

export const StyledInput = styled.div`
  position: relative;
  z-index: 2000;
  display: inline-block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &::before {
    content: '';
    height: 18px;
    width: 18px;
    background-image: url(${searchIconBold});
    background-size: cover;
    position: absolute;
    border: 0;
    top: 8px;
    left: 8px;
    transform: rotate(90deg);
  }
  input {
    font-size: 15px;
    width: 300px;
    height: 36px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#7ca5ca')};
    border-radius: 4px;
    color: #044f98;
    background-color: #f8fbfe;
    padding: 7px 34px;
    ::placeholder {
      color: #044f98;
    }
    outline: none;
    ${respondFrom.lessThanTablet`
      width: 100%;
    `}
  }
`

export const MapClearIcon = styled.div`
  content: '';
  height: 20px;
  width: 20px;
  background-image: url(${closeIcon});
  background-size: cover;
  position: absolute;
  border: 0;
  top: 8px;
  right: 7px;
  cursor: pointer;
`

export const SearchContainer = styled.div`
  margin: 30px 0 20px 0;
  background-color: #fff;
  position: relative;
  z-index: 100;
`
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`
