import React from 'react'
import {
  StyledProductGenerationError,
  StyledTittle,
  StyledTryAgainButton,
  StyledWarningIcon,
} from './ProductGenerationError.styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'

export interface ProductGenerationErrorProps {
  tryAgainAction: () => void
  title: string
  message1: string
  message2: string
  isLoading: boolean
  icon: any
}

const ProductGenerationError = (props: ProductGenerationErrorProps) => {
  const { tryAgainAction, title, message1, message2, icon, isLoading } = props
  const { t } = useTranslation()

  return (
    <StyledProductGenerationError>
      <img src={icon} alt="" className="app-icon" />
      <StyledTittle>
        <StyledWarningIcon>
          <WarningIcon height={36} width={36} />
        </StyledWarningIcon>
        <span>{title}</span>
      </StyledTittle>
      <h1>{message1}</h1>
      <h1>{message2}</h1>
      <StyledTryAgainButton
        kind="secondary"
        onClick={tryAgainAction}
        loading={isLoading}
        active={isLoading}
      >
        {isLoading ? '' : t('common.error.table.try-again.button')}
      </StyledTryAgainButton>
    </StyledProductGenerationError>
  )
}

export default ProductGenerationError
