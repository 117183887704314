import styled from 'styled-components'

export const StyledPropertyRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`
export const StyledPropertyCol = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 14px;
    color: #92969a;
  }
`

export const StyledPropertyValue = styled.div`
  font-size: 16px;
  color: #495057;
  display: flex;
  word-break: break-all;
  overflow-wrap: break-word;

  img {
    margin-right: 5px;
  }
`
