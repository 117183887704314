import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Dropdown,
  Button,
  Inner,
  ShowAllButton,
  Badge,
  Icon,
  NoNotifications,
} from './NotificationsManager.style'
import { ReactComponent as NotificationsIcon } from 'assets/images/icons/notifications.svg'
import NotificationItem from './NotificationItem/NotificationItem'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useOutsideClickHandler from 'hooks/useOutsideClickHandler'
import { selectAllNotifications, selectUnreadNotifications } from 'slices/notificationsSlice'
import {
  useLazyGetAllNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
  useSetNotificationAsDeliveredMutation,
} from 'services/notifications-api'
import NotificationError from './NotificationError/NotificationError'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'

interface NotificationsManagerProps {
  channelName: string
}

const NotificationsManager = (props: NotificationsManagerProps) => {
  const { channelName } = props
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const [isShowAllDisabled, setIsShowAllDisabled] = useState<boolean>(false)
  const [showToastError, setShowToastError] = useState(false)
  const unreadNotifications = useSelector(selectUnreadNotifications)
  const allNotifications = useSelector(selectAllNotifications)
  const { t } = useTranslation()
  const dropdownRef = useRef()
  useOutsideClickHandler(dropdownRef, () => setShowNotifications(false))
  const notifications =
    (!isShowAllDisabled && unreadNotifications?.length && unreadNotifications) || allNotifications

  const [
    getUnreadNotifications,
    {
      isError: isUnreadNotificiationsFetchError,
      isSuccess: isUnreadNotificationSuccess,
      isLoading: isUnreadNotificationLoading,
      isFetching: isUnreadNotificationFetching,
    },
  ] = useLazyGetUnreadNotificationsQuery()

  const [
    getAllNotifications,
    {
      isError: isAllNotificiationsFetchError,
      isSuccess: isAllNotificationSuccess,
      isLoading: isAllNotificationLoading,
      isFetching: isAllNotificationFetching,
    },
  ] = useLazyGetAllNotificationsQuery()

  const isNotificationsError =
    isAllNotificiationsFetchError ||
    unreadNotifications === null ||
    isUnreadNotificiationsFetchError

  const [setNotificationAsDelivered, { isSuccess: isSetNotificationAsDeliveredSuccess }] =
    useSetNotificationAsDeliveredMutation()

  useEffect(() => {
    if (isSetNotificationAsDeliveredSuccess) {
      getUnreadNotifications()
      getAllNotifications()
    }
  }, [isSetNotificationAsDeliveredSuccess, getUnreadNotifications, getAllNotifications])

  useEffect(() => {
    if (
      isUnreadNotificationSuccess &&
      isAllNotificationSuccess &&
      !isUnreadNotificationFetching &&
      !isAllNotificationFetching
    ) {
      setShowToastError(false)
    }
  }, [
    isUnreadNotificationSuccess,
    isAllNotificationSuccess,
    isUnreadNotificationFetching,
    isAllNotificationFetching,
  ])

  useToastEffect(t('notification.error.toast'), 'ERROR', [
    isUnreadNotificiationsFetchError || isAllNotificiationsFetchError,
    showToastError,
  ])

  const handleClick = (id) => {
    setNotificationAsDelivered({ id })
  }

  const handleShowAll = () => {
    if (isShowAllDisabled) {
      return
    }
    getAllNotifications()
    setIsShowAllDisabled(true)
  }

  return (
    <Container>
      <Button onClick={() => setShowNotifications(!showNotifications)}>
        <Icon>
          {unreadNotifications !== null && unreadNotifications?.length ? (
            <Badge>{unreadNotifications?.length}</Badge>
          ) : null}
          <NotificationsIcon />
        </Icon>
        {t('common.notifications')}
      </Button>
      {showNotifications && (
        <Dropdown ref={dropdownRef}>
          <Inner>
            {!notifications?.length && !(isNotificationsError || showToastError) && (
              <NoNotifications>{t('common.noNotifications')}</NoNotifications>
            )}
            {(isNotificationsError || showToastError) && (
              <NotificationError
                tryAgainAction={() => {
                  getUnreadNotifications()
                  getAllNotifications()
                  setShowToastError(true)
                }}
                isLoading={
                  isUnreadNotificationLoading ||
                  isUnreadNotificationFetching ||
                  isAllNotificationLoading ||
                  isAllNotificationFetching
                }
              />
            )}
            {!(isNotificationsError || showToastError) &&
              notifications?.map((item) => (
                <NotificationItem
                  {...item}
                  key={item.id}
                  channelName={channelName}
                  isUnread={
                    !!unreadNotifications?.find((notification) => notification.id === item.id)
                  }
                  onClick={handleClick}
                />
              ))}
          </Inner>
          {!(isNotificationsError || showToastError) && (
            <ShowAllButton disabled={isShowAllDisabled} onClick={handleShowAll}>
              {t('common.showAll')}
            </ShowAllButton>
          )}
        </Dropdown>
      )}
    </Container>
  )
}

export default NotificationsManager
