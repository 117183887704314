import Button from 'components/Button/Button'
import styled from 'styled-components'

export const StyledProductGenerationError = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 68px;
    height: 68px;
  }

  h1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 480px;
  }
`

export const StyledTittle = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 16px;
  span {
    color: #495057;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`

export const StyledWarningIcon = styled.div`
  path {
    stroke: #f18500;
  }
  circle {
    stroke: #f18500;
  }
  line {
    stroke: #f18500;
  }
`

export const StyledTryAgainButton = styled(Button)<any>`
  margin-top: 10px;
  min-width: 100px;
  height: 26px;
`
