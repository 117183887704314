import styled from 'styled-components'

export const StyledTabsWrapper = styled.div`
  position: relative;
  .MuiBox-root {
    padding: 0;
  }
  .MuiAppBar-colorPrimary {
    background-color: transparent;
    color: #000;
    box-shadow: none;
  }

  .MuiTabs-flexContainer {
    min-height: 72px;
  }

  .MuiTab-root {
    padding: 0px;
  }

  .MuiTab-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    text-transform: capitalize;
    svg {
      margin-left: 5px;
    }
  }

  .PrivateTabIndicator-colorSecondary-7 {
    background-color: #044f98;
    height: 4px;
  }

  .MuiTab-fullWidth::before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 99%;
    height: 4px;
    background: #d4e5f7;
  }

  .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin-bottom: 0;
  }

  .MuiChip-root {
    background: #f6f6f6;
    border-radius: 2px;
  }

  .MuiChip-label {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    background: #f6f6f6;
    border-radius: 2px;
  }
`
