import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FileDrop } from 'react-file-drop'
import SVG from 'react-inlinesvg'
import addImage from 'assets/images/icons/addImage.svg'
import {
  StyledImageUpload,
  StyledDescription,
  StyledOptionalInfo,
  StyledOnDragInfo,
  StyledThumbContainer,
  StyledDescriptionContent,
  StyledError,
} from './AddImageUpload.styled'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import { selectAddressFormData, setFormField } from 'slices/addressFormSlice'

const MAX_UPLOAD_SIZE = 1000 // In kB

const AddImageUpload = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const fileInputRef = useRef(null)
  const [onDragState, setOnDragState] = useState(false)
  const [imageName, setImageName] = useState(addressFormData.addressData?.imageName ?? undefined)
  const [image, setImage] = useState<any>(addressFormData.addressData?.image ?? undefined)
  const [errorItem, setErrorItem] = useState<any>({ isActive: false, key: '' })

  useEffect(() => {
    dispatch(setFormField({ key: 'imageName', value: imageName }))
  }, [imageName, dispatch])

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(
          (fileReader.result as string).substring((fileReader.result as string).indexOf(',') + 1)
        )
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleDrop = async (files: any) => {
    const file = files[0]
    const isWrongImageType = !(file?.type === 'image/jpeg' || file?.type === 'image/png')

    if (file?.size * 0.001 > MAX_UPLOAD_SIZE || isWrongImageType) {
      setErrorItem({ isActive: true, key: isWrongImageType ? 'wrongImageType' : 'imageToBig' })
      return
    }
    setErrorItem({ isActive: false, key: '' })
    setImage(URL.createObjectURL(file))
    setImageName(file.name)
    dispatch(setFormField({ key: 'image', value: await convertBase64(file) }))
  }

  const onImageChange = () => {
    setOnDragState(false)
    setImageName('')
    setImage(undefined)
  }

  const onRemoveImage = () => {
    setOnDragState(false)
    setImageName('')
    setImage(undefined)
    dispatch(setFormField({ key: 'image', value: '' }))
  }

  const bodyBeforImage = (
    <>
      <StyledDescription opacity={onDragState}>
        {errorItem.isActive && (
          <StyledError>
            {t(`newAddressModal.objectDataTab.imageUpload.error.${errorItem.key}`)}
          </StyledError>
        )}
        <img src={addImage} alt="addImage.png" />
        <StyledDescriptionContent>
          <p>{t('newAddressModal.objectDataTab.imageUpload.row1')}</p>
          <p onClick={() => fileInputRef.current.click()}>
            {t('newAddressModal.objectDataTab.imageUpload.row2')}
          </p>
          {t('newAddressModal.objectDataTab.imageUpload.row3')}
        </StyledDescriptionContent>
      </StyledDescription>
      {onDragState && (
        <StyledOnDragInfo>
          <SVG src={addImage} />
          {t('newAddressModal.objectDataTab.imageUpload.dragDrop')}
        </StyledOnDragInfo>
      )}
    </>
  )

  const imageBody = (
    <StyledThumbContainer>
      <img src={image?.includes('blob') ? image : `data:image/png;base64, ${image}`} alt="thumb" />
      <span>{imageName}</span>
      <span className="clickable" onClick={onImageChange}>
        {t('newAddressModal.objectDataTab.imageUpload.editImageButton')}
      </span>
      <span className="clickable deleteImage" onClick={onRemoveImage}>
        {t('newAddressModal.objectDataTab.imageUpload.removeImageButton')}
      </span>
    </StyledThumbContainer>
  )

  return (
    <>
      <StyledImageUpload transparent={onDragState} isError={errorItem.isActive}>
        <FileDrop
          onFrameDragEnter={(event) => setOnDragState(true)}
          onFrameDragLeave={(event) => setOnDragState(false)}
          onFrameDrop={(event) => setOnDragState(false)}
          onDragOver={(event) => setOnDragState(true)}
          // onDragLeave={(event) => setOnDragState(false)}
          onDrop={(files, event) => handleDrop(files)}
        >
          {image ? imageBody : bodyBeforImage}
          <StyledOptionalInfo transparent={onDragState}>optional</StyledOptionalInfo>
        </FileDrop>
      </StyledImageUpload>
      <input
        onChange={(event) => handleDrop(event.target.files)}
        ref={fileInputRef}
        type="file"
        style={{ visibility: 'hidden' }}
      />
    </>
  )
}

export default AddImageUpload
