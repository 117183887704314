import React from 'react'
import {
  StyledAddressContainer,
  StyledThumb,
  StyledAddress,
  StyledAddressRow,
} from './Address.styled'
import { useTranslation } from 'react-i18next'

const Address = ({ data, onClickThumb }: any) => {
  const { t } = useTranslation()

  const getCategoryName = (category) => {
    switch (category) {
      case 'EFH':
        return t('newAddressModal.objectDataTab.objectTypes.detachedHouse')
      case 'ETW':
        return t('newAddressModal.objectDataTab.objectTypes.condominium')
      case 'MFH':
        return t('newAddressModal.objectDataTab.objectTypes.apartment')
      default:
        return ''
    }
  }

  return (
    <div>
      <StyledAddressContainer>
        {data?.image && (
          <StyledThumb onClick={onClickThumb}>
            <img src={`data:image/jpeg;base64,${data.image}`} alt="thumb" />
          </StyledThumb>
        )}
        <StyledAddress>
          <StyledAddressRow>
            {data.street} {data.houseNumber}
          </StyledAddressRow>
          <StyledAddressRow>
            {data.zip} {data.town}, {data.nation} | {getCategoryName(data.category)}{' '}
            <p>{data.category}</p>
          </StyledAddressRow>
          <StyledAddressRow>
            {data.lat?.toFixed(6)}, {data.lng?.toFixed(6)}
          </StyledAddressRow>
        </StyledAddress>
      </StyledAddressContainer>
    </div>
  )
}

export default Address
