import { respondTo } from 'common/styles/Responsive.styles'
import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledObjectData = styled.div`
  width: 100%;
  ${respondTo.smallDesktop`
    height: 430px;
  `}
`

export const StyledLine = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.darkMedium};
  opacity: 0.2;
  margin: 10px 0 26px 0;
`
export const StyledBottomLine = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.darkMedium};
  opacity: 0.2;
  margin: 0 0 20px;
`
