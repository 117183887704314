import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledLink = styled.div<any>`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: #044f98;
  }
  white-space: nowrap !important;
`

export const StyledTag = styled.div<any>`
  display: flex;
  align-items: center;
  color: #92969a;

  .MuiChip-root {
    margin-left: 10px;
    margin-right: 10px;
    color: ${theme.colors.dark};
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    white-space: nowrap;
    border-radius: 2px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #f6f6f6;
    max-width: 55px;
    max-height: 25px;
    min-width: 35px;
    min-height: 18px;
  }
`
