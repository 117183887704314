import React from 'react'
import { StyledLoadError, StyledTryAgainButton, StyledWarningIcon } from './LoadError.styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'

export interface LoadErrorProps {
  tryAgainAction: () => void
  isLoading: boolean
}

const LoadError = (props: LoadErrorProps) => {
  const { tryAgainAction, isLoading } = props
  const { t } = useTranslation()

  return (
    <StyledLoadError>
      <StyledWarningIcon>
        <WarningIcon height={40} width={40} />
      </StyledWarningIcon>
      <h1>{t('addressModal.error.title')}</h1>
      <h2>{t('addressModal.error.text')}</h2>
      <StyledTryAgainButton
        kind="secondary"
        onClick={tryAgainAction}
        loading={isLoading}
        active={isLoading}
      >
        {isLoading ? '' : t('common.error.table.try-again.button')}
      </StyledTryAgainButton>
    </StyledLoadError>
  )
}

export default LoadError
