import React from 'react'
import { StyledLine, StyledObjectData, StyledBottomLine } from './AddObjectData.styled'
import AddHouseParameters from './AddObjectDataComponents/AddHouseParameters/AddHouseParameters'
import AddImageUpload from './AddObjectDataComponents/AddImageUpload/AddImageUpload'
import AddObjectType from './AddObjectDataComponents/AddObjectType/AddObjectType'

export interface AddObjectDataProps {
  nextButton: React.MutableRefObject<undefined>
  productName: string
}

const AddObjectData = (props: AddObjectDataProps) => {
  const { nextButton, productName } = props
  const isIndex = productName === 'index'

  return (
    <StyledObjectData>
      <AddObjectType />
      {!isIndex && (
        <>
          <StyledLine />
          <AddHouseParameters nextButton={nextButton} onlyMandatoryFields={isIndex} />
          <StyledBottomLine />
          {productName === 'report' && <AddImageUpload />}
        </>
      )}
    </StyledObjectData>
  )
}

export default AddObjectData
