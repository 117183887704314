import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ProductGenerationError from 'components/ProductGenerationError/ProductGenerationError'
import { useToastEffect } from 'components/Toast/hooks/useToastEffect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLazyGetCustomerCoinsQuery } from 'services/coins-api'
import { selectAddressFormData } from 'slices/addressFormSlice'
import { selectCustomerCoins, selectProductsCost } from 'slices/coinsSlice'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import indexIcon from 'assets/images/icons/SprengnetterAppsIndex.png'
import { StyledContainer } from '../ProductCheckout.styled'
import Button from 'components/Button/Button'
import NoCoinsContainer from '../NoCoinsContainer/NoCoinsContainer'
import { useLazyGetIndexDocumentQuery } from 'services/indices-api'
import ProductGenerationSuccess from 'components/ProductGenerationSuccess/ProductGenerationSuccess'
import { findInArray, isProductBilled } from 'common/utils/generic'

export interface IndexCheckoutProps {
  isReportGenerated: boolean
  setReportGenerated: Dispatch<SetStateAction<boolean>>
  onBackdropClick: () => void
}

const IndexCheckout = (props: IndexCheckoutProps) => {
  const { isReportGenerated, setReportGenerated, onBackdropClick } = props
  const { t } = useTranslation()
  const addressFormData = useSelector(selectAddressFormData)
  const servicePrices = useSelector(selectProductsCost)
  const customerConfig = useSelector(selectCustomerConfiguration)
  const customerCoins = useSelector(selectCustomerCoins)
  const premiumCoins =
    customerCoins.coins?.accounts?.find((item) => item.type === 'on-demand')?.amount || 0
  const subscriptionCoinsData = customerCoins.coins?.accounts?.find(
    (item) => item.type === 'subscription'
  )
  const subscriptionCoins = subscriptionCoinsData?.amount
  const indicesProduct = findInArray(customerConfig?.products, 'name', 'indices')
  const isIndexBilled = isProductBilled(indicesProduct, customerConfig)
  const hasEnoughCoins =
    (premiumCoins || subscriptionCoins) &&
    (servicePrices?.indices <= subscriptionCoins || servicePrices?.indices <= premiumCoins)
  const [showToastError, setShowToastError] = useState(false)

  const [getCustomerCoins] = useLazyGetCustomerCoinsQuery()

  const [getIndexDocument, { data, isFetching, isSuccess, isError }] =
    useLazyGetIndexDocumentQuery()

  const handleDownloadButtonClick = () => {
    if (isFetching) return
    getIndexDocument({
      id: addressFormData.addressData.id,
      type: addressFormData.addressData.indexReportType,
      isContactDetailActive: addressFormData.addressData.isIndexContactDetailActive,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      const anchor = document.createElement('a')
      const fileName = `indices_${addressFormData.addressData.zip}-${addressFormData.addressData.town}`
      anchor.setAttribute('download', fileName)
      document.body.appendChild(anchor)
      const objectUrl = window.URL.createObjectURL(data)
      anchor.href = objectUrl
      anchor.click()

      window.URL.revokeObjectURL(objectUrl)
      document.body.removeChild(anchor)
    }
  }, [isSuccess, data, addressFormData])

  useEffect(() => {
    if (isSuccess) {
      getCustomerCoins()
      setShowToastError(false)
    }
    if (isSuccess || isError) {
      setReportGenerated(true)
    }
  }, [isSuccess, isError, getCustomerCoins, setReportGenerated])

  useToastEffect(t('newAddressModal.finishTab.indexInfo.error.toast'), 'ERROR', [
    isError,
    showToastError,
  ])

  return (
    <>
      {!isReportGenerated &&
        (!isIndexBilled || hasEnoughCoins ? (
          <StyledContainer>
            <img src={indexIcon} alt="" className="app-icon" />
            <h1>{t('common.generateIndex')}</h1>
            {isIndexBilled && (
              <p>
                {t('newAddressModal.finishTab.indexInfo.description', {
                  value: servicePrices?.indices,
                })}
              </p>
            )}
            <div onClick={() => handleDownloadButtonClick()}>
              <Button kind="primary" loading={isFetching} disabled={isFetching}>
                {isFetching ? t('common.loadingButton') : t('common.generateIndex')}
              </Button>
            </div>
          </StyledContainer>
        ) : (
          <NoCoinsContainer
            icon={indexIcon}
            descriptionKey="newAddressModal.finishTab.indexInfo.noCoinsDescription"
            buttonLabel={t('common.generateIndex')}
            price={servicePrices?.indices}
          />
        ))}
      {isSuccess && (
        <ProductGenerationSuccess
          icon={indexIcon}
          title={t('newAddressModal.finishTab.indexInfo.downloadTitle')}
          message1={t('newAddressModal.finishTab.indexInfo.downloadDescription')}
          message2={t('newAddressModal.finishTab.indexInfo.successDescription')}
        >
          <Button kind="secondary" onClick={onBackdropClick}>
            {t('common.closeButton')}
          </Button>
        </ProductGenerationSuccess>
      )}
      {(isError || showToastError) && (
        <ProductGenerationError
          icon={indexIcon}
          title={t('newAddressModal.finishTab.indexInfo.errorTitle')}
          message1={t('newAddressModal.finishTab.indexInfo.errorDescription.1')}
          message2={t('newAddressModal.finishTab.indexInfo.errorDescription.2')}
          isLoading={isFetching}
          tryAgainAction={() => {
            setShowToastError(true)
            handleDownloadButtonClick()
          }}
        />
      )}
    </>
  )
}

export default IndexCheckout
