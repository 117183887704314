import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledImageUpload = styled.div`
  width: 500px;
  border: 2px dashed ${({ isError }) => (!isError ? '#f2f4f4' : 'red')};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin: 0 0 20px;
  background-color: ${({ transparent }) => (transparent ? '#f8fbfe' : 'transparent')};
  position: relative;

  .file-drop {
    width: 100%;
  }

  &:hover {
    border: 2px dashed #044f98;

    SVG {
      circle,
      path {
        stroke: #044f98;
      }
    }
  }
  ${respondFrom.lessThanTablet`
    width: auto;
  `}
`

export const StyledOptionalInfo = styled.div`
  width: 80px;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : '#ffffff')};
  text-align: center;
  color: #92969a;
  position: absolute;
  right: 25px;
  bottom: -11px;
  font-size: 14px;
`

export const StyledThumbContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }

  span {
    margin: 0;
    font-size: 14px;
    color: #495057;
    margin-left: 20px;
    &.clickable {
      color: #044f98;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &.deleteImage {
      color: red;
    }
  }
`

export const StyledThumb = styled.div`
  width: 40px;
  height: 40px;
  background-color: white;
`

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  opacity: ${({ opacity }) => (opacity ? 0.1 : 1)};

  p {
    margin: 0;
    size: 14px;
    color: #495057;

    &:last-child {
      color: #044f98;

      :hover {
        cursor: pointer;
      }
    }
  }

  img {
    margin-right: 20px;
  }
`
export const StyledDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`

export const StyledOnDragInfo = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  svg {
    margin-top: -6px;
  }
`

export const StyledError = styled.div`
  position: absolute;
  font-size: 14px;
  top: -16px;
  left: 25px;
  color: red;
  background-color: #fff;
  padding: 5px;
`
