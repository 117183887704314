import styled, { createGlobalStyle } from 'styled-components'
import searchIcon from 'assets/images/icons/search.svg'
import Inter from 'assets/fonts/Inter.ttf'

export const GlobalStyles = createGlobalStyle`

@font-face { 
  font-family: 'Inter'; 
  src: url(${Inter}) format('truetype');
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #ededed;
}

.PrivateTabIndicator-colorSecondary-5, .MuiTabs-indicator {
  background-color: #044F98;
}

.Mui-selected {
  cursor: inherit;
  pointer-events: none;
}

.Mui-selected {
  .MuiTab-wrapper {
    color: #044f98;
    font-weight: 600;
  }
}

.MuiTab-textColorInherit.Mui-disabled {
  opacity: 1;
}

.MuiSelect-select:focus {
  background-color: inherit;
}

.MuiFormControlLabel-label {
  font-size: 14px;
  color: #495057;
}

.MuiFormLabel-root.Mui-focused {
  color: #495057;
}

.MuiInputBase-input {
  padding: 7px;
}

.MuiPaper-root {
   li.MuiButtonBase-root {
    &:hover {
      background-color: #e9f2fb;
      color: #044f98;
    }
  }
}

.MuiIconButton-colorSecondary:hover, .MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba(11, 79, 152, 0.04);
}

.MuiTouchRipple-root {
  display: none;
}

.MuiTooltip-tooltip {
  font-size: 12px;
}
`

export const StyledMainLayoutWrapper = styled.div`
  position: relative;
  z-index: 10;
  border-radius: 2px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
`

export const StyledMainLayout = styled.div`
  max-width: 1200px;
  height: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #c8cbcd;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const StyledAppWrapper = styled.div`
  background-color: #ffffff;
  height: 750px;
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7%;

  div:nth-of-type(2) {
    button {
      width: 170px;
    }
  }
  button {
    text-transform: none !important;
    img {
      margin-right: 10px;
    }
  }
`

export const StyledLabel = styled.label`
  max-width: 260px;
  width: 100%;
  position: relative;
  margin-right: 24px;

  input {
    width: 100%;
    height: 36px;
    border: 1px solid #d8d8d7;
    border-radius: 4px;
    padding-left: 35px;
    font-size: 0.875rem;

    &::placeholder {
      color: #92969a;
    }
  }

  &::before {
    content: '';
    height: 24px;
    width: 24px;
    background-image: url(${searchIcon});
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    margin: auto;
  }
`

export const StyledMoreButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  justify-content: center;
  ul {
    display: ${(props) => (props.isDropdownVisible ? 'block' : 'none')};
    list-style: none;
    margin: 0;
    padding: 4px 16px;
    position: absolute;
    top: 100%;
    right: -1rem;
    background: #fff;
    z-index: 1;
    text-transform: capitalize;
    border: 1px solid #eee;
    border-radius: 3px;
  }
  li {
    padding: 4px 0;
    &:hover {
      color: rgba(4, 79, 152);
    }
  }
`

export const StyledCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 690px;
  color: #495057;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

export const StyledWarningIcon = styled.div`
  path {
    stroke: #f18500;
  }
  circle {
    stroke: #f18500;
  }
  line {
    stroke: #f18500;
  }
`
