import styled from 'styled-components'

export const StyledProductGenerationSuccess = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 28px;
    color: #324245;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    display: flex;
  }
  p {
    margin-top: 15px;
    font-size: 14px;
    color: #495057;
    width: 450px;
  }

  img {
    width: 68px;
    height: 68px;
  }

  svg#check-mark {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .MuiButton-containedPrimary {
    margin-top: 20px;
  }
`
