import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledPersonalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledPersonalRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  ${respondFrom.lessThanTablet`
    flex-direction: column;
  `}
`
export const StyledPersonalShortCol = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${respondFrom.lessThanTablet`
    height: 40px;
    & + & {
      margin-top: 10px;
    }
  `}
`
export const StyledPersonalLongCol = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${respondFrom.lessThanTablet`
    height: 40px;
    margin-top: 10px;
  `}
`
export const StyledLabel = styled.div`
  font-size: 12px;
  color: #92969a;
`
export const StyledValue = styled.div`
  font-size: 16px;
  color: #495057;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }

  .MuiButtonBase-root {
    height: 30px;
    background-color: #ffffff;
    color: #044f98;
    border: 1px solid #044f98;
  }

  div + div {
    margin-left: 10px;
  }
  ${respondFrom.lessThanTablet`
    flex-direction: ${(props) => (!props.withButtons ? 'unset' : 'column')};
    align-items: ${(props) => (!props.withButtons ? 'center' : 'flex-start')};
  `}
`

export const StyledPersonalButtonsLabel = styled.div`
  display: flex;
  ${respondFrom.lessThanTablet`
    & {
      margin-left: 0 !important;
      align-items: center;
    }
  `}
`

export const StyledPersonalButtonsContainer = styled.div`
  display: flex;
  & div {
    margin-left: 0;
  }
  ${respondFrom.lessThanTablet`
    margin: 10px 0 0 0 !important;
  `}
`
