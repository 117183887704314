import { theme } from 'common/styles/theme'
import styled from 'styled-components'

export const StyledLeadAddresContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${(props) => (props.isModernizerLead ? 'rgba(185, 209, 203, 0.2)' : '#fff5f5')};
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledIcon = styled.div`
  width: 42px;
  height: 42px;

  img {
    width: 100%;
  }
`
export const StyledAddress = styled.div`
  max-width: 570px;
  width: 100%;
  height: 67px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledAddressRow = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  color: #92969a;

  :first-child {
    font-size: 16px;
    color: #495057;
  }

  :last-child {
    font-size: 12px;
  }

  p {
    min-width: 35px;
    min-height: 18px;
    margin: 0 0 0 5px;
    padding: 1px 14px 0 14px;
    background-color: #ffffff;
    color: ${theme.colors.dark};
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
`
